import React, { useRef, useMemo } from 'react'

import useInputMask from 'use-input-mask'

import { INPUT_PHONE_MASK, maskPhone } from '@sponte/lib-utils/dist/helpers/masks'

import { SptField } from '../Field'

export const SptFieldPhone = ({ value, onChange, ...props }) => {
  const inputRef = useRef(null)

  const handleChange = useInputMask({
    input: inputRef,
    onChange,
    mask: INPUT_PHONE_MASK,
    guide: false,
    initialValue: maskPhone(value)
  })

  const fieldProps = useMemo(
    () => ({
      ...props,
      type: 'text',
      maxLength: 19,
      value: maskPhone(value),
      onChange: handleChange
    }),
    [props, handleChange]
  )

  return <SptField inputRef={inputRef} {...fieldProps} />
}

SptFieldPhone.propTypes = {}

SptFieldPhone.defaultProps = {}
