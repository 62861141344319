import React from 'react'

import { StdDay } from './styles'

export const SptBigCalendarDayButton = React.memo(({ children, ...props }) => {
  return (
    <StdDay {...props}>
      {children}
    </StdDay>
  )
})
