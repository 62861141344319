import React, { useRef, useMemo } from 'react'

import moment from 'moment'

import useInputMask from 'use-input-mask'

import { INPUT_TIME_MASK } from '@sponte/lib-utils/dist/helpers/masks'

import { SptIcon } from '../../atoms/Icon'

import { SptField } from '../Field'

export const SptFieldTimeMask = ({ value, onChange, ...props }) => {
  const inputRef = useRef(null)

  const icon = useMemo(() => {
    return <SptIcon>spt-time</SptIcon>
  }, [])

  const internalValue = useMemo(() => {
    const time = moment(
      value,
      ['YYYY-MM-DDTHH:mm:ss.SSSS', 'YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:ss', 'HH:mm:ss', 'HH:mm'],
      true
    )

    if (time.isValid()) {
      return time.format('HH:mm')
    }

    return value
  }, [value])

  const handleOnChange = useInputMask({
    input: inputRef,
    onChange: (e) => {
      if (!onChange || typeof onChange !== 'function') {
        return
      }

      if (moment(e.target.value, 'HH:mm', true).isValid()) {
        onChange({
          ...e,
          target: {
            ...e.target,
            id: props.id,
            type: 'text',
            name: props.name,
            value: e.target.value
          }
        })
      } else {
        onChange(e)
      }
    },
    mask: INPUT_TIME_MASK,
    guide: false,
    initialValue: internalValue
  })

  return <SptField inputRef={inputRef} prefix={icon} type="text" onChange={handleOnChange} {...props} />
}

SptFieldTimeMask.displayName = 'SptFieldTimeMask'

SptFieldTimeMask.propTypes = {}

SptFieldTimeMask.defaultProps = {}
