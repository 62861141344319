import React, { useRef, useMemo } from 'react'

import useInputMask from 'use-input-mask'

import { INPUT_CEP_MASK, maskCep } from '@sponte/lib-utils/dist/helpers/masks'

import { SptField } from '../Field'

export const SptFieldCep = ({ value, onChange, ...props }) => {
  const inputRef = useRef(null)

  const handleChange = useInputMask({
    input: inputRef,
    onChange,
    mask: INPUT_CEP_MASK,
    guide: false,
    initialValue: maskCep(value)
  })

  const fieldProps = useMemo(
    () => ({
      ...props,
      type: 'text',
      value: maskCep(value),
      onChange: handleChange
    }),

    // eslint-disable-next-line
    [props, handleChange]
  )

  return <SptField inputRef={inputRef} {...fieldProps} />
}

SptFieldCep.propTypes = {}

SptFieldCep.defaultProps = {}
