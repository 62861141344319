import React, { Suspense } from 'react'

import { ReactQueryProvider } from 'providers/reactQuery'

import { SptLoading } from '@sponte/lib-components/dist/atoms/Loading'

import UnknownUser from './pages/unknownuser'

const App = () => {
  return (
    <Suspense fallback={<SptLoading full size="xl" position="absolute" />}>
      <ReactQueryProvider>
        <UnknownUser />
      </ReactQueryProvider>
    </Suspense>
  )
}

export default App
