import React, { memo, useMemo, useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { RemoveScroll } from 'react-remove-scroll'

import { useCombobox } from 'downshift'

import { stopPropagationAndPreventDefault } from '@sponte/lib-utils/dist/helpers/events'
import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { useMedia } from '@sponte/lib-utils/dist/hooks/useMedia'
import { usePopper } from '@sponte/lib-utils/dist/hooks/usePopper'
import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'
import { useThrottle } from '@sponte/lib-utils/dist/hooks/useThrottle'

import { SptBox } from '../../elements/Box'
import { SptText } from '../../elements/Text'

import { SptButton } from '../../atoms/Button'
import { SptIcon } from '../../atoms/Icon'
import { SptMenuItem } from '../../atoms/MenuItem'
import { SptMenuList } from '../../atoms/MenuList'
import { SptScrollbar } from '../../atoms/Scrollbar'

import { SptField } from '../Field'

const defaultItemToString = (item) => (typeof item === 'string' ? item : getSafe(item, 'nome', '')) || ''

export function stateReducerFieldSelect(state, actionAndChanges) {
  const { type, changes, props } = actionAndChanges
  const { onCreate, items, itemToString } = props

  switch (type) {
    case useCombobox.stateChangeTypes.ItemClick:
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
      if (onCreate && state.highlightedIndex === items.length - 1) {
        onCreate((state.inputValue || '').trim())

        return {
          ...changes,
          inputValue: state.inputValue,
          selectedItem: state.selectedItem
        }
      }

      return changes

    case useCombobox.stateChangeTypes.FunctionSelectItem:
      return {
        ...changes,
        inputValue: itemToString(changes.selectedItem)
      }

    case useCombobox.stateChangeTypes.InputBlur:
      return {
        ...changes,
        inputValue: itemToString(getSafe(state, 'selectedItem', '')),
        selectedItem: state.selectedItem
      }

    default:
      return changes
  }
}

export const SptFieldSelect = memo(
  ({
    items: defaultItems = [],
    itemIsSelectedKey = 'id',
    itemToString = defaultItemToString,
    groupToString = defaultItemToString,
    getGroupItems = null,
    onChange = () => {},
    onCreate = null,
    onInputChange = null,
    inputChangeTimeout = 300,
    value = null,
    searchIcon = false,
    displayTextHelp = true,
    dropDownIcon = true,
    clearable = true,
    disabled = false,
    inputRef,
    textHelperEmptyItems,
    ...props
  }) => {
    const theme = useTheme()
    const isSmall = useMedia(theme.mediaQueries.down.tablet, true, false)

    const { referrence, popper } = usePopper({
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: ({ placement }) => {
              if (placement === 'bottom-start' && !(props.size === 'sm' || !displayTextHelp)) {
                return [0, -16]
              }
              return []
            }
          }
        }
      ]
    })

    const { t } = useTranslation()

    const itemIsSelected = useCallback(
      (item, selectedItem) =>
        getSafe(item, itemIsSelectedKey, item) === getSafe(selectedItem, itemIsSelectedKey, selectedItem),
      [itemIsSelectedKey]
    )

    const id = useMemo(() => {
      return props.id || props.name
    }, [props.id, props.name])

    const items = useMemo(() => {
      if (getGroupItems) {
        return defaultItems.reduce((acc, group) => {
          return [...acc, ...getGroupItems(group)]
        }, [])
      }

      return defaultItems
    }, [defaultItems, getGroupItems])

    const handleInputValueChange = useThrottle(onInputChange, inputChangeTimeout)

    const {
      isOpen,
      inputValue,
      selectedItem,
      highlightedIndex,
      reset,
      getComboboxProps,
      getInputProps,
      getMenuProps,
      getItemProps,
      getToggleButtonProps,
      selectItem
    } = useCombobox({
      onCreate,
      id,
      items: onCreate ? [...items, null] : items,
      itemToString,
      stateReducer: stateReducerFieldSelect,
      initialSelectedItem: value,
      defaultHighlightedIndex: -1,
      onInputValueChange: ({ inputValue: newInputValue }) => {
        handleInputValueChange((newInputValue || '').trim())
      },
      onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
        onChange({
          target: {
            id,
            type: 'text',
            name: props.name,
            value: newSelectedItem
          }
        })
      }
    })

    useEffect(() => {
      if (!value && selectedItem) {
        reset()
      } else {
        selectItem(value)
      }
    }, [value])

    const iconClear = useMemo(() => {
      if (clearable && selectedItem) {
        return (
          <SptButton
            tabIndex={-1}
            icon="spt-close"
            size="sm"
            variant="text"
            palette="neutral"
            disabled={disabled}
            onClick={reset}
            data-testid="button-unselect-item"
          />
        )
      }

      return null
    }, [selectedItem, clearable, disabled, reset])

    const icon = useMemo(() => {
      if (dropDownIcon) {
        return (
          <SptButton
            icon="spt-chevron-down"
            size="sm"
            mr={5}
            variant="text"
            palette="neutral"
            data-testid={`${props.name}-dropDownIcon`}
            {...getToggleButtonProps({ disabled })}
          />
        )
      }

      return null
    }, [dropDownIcon, disabled, getToggleButtonProps])

    const iconLeft = useMemo(() => {
      if (searchIcon || onInputChange) {
        return <SptIcon data-testid="searchIcon">spt-search</SptIcon>
      }

      return null
    }, [searchIcon])

    const handleKeyDown = useCallback(
      (e) => {
        if (e.key === 'Escape' && (isOpen || value)) {
          stopPropagationAndPreventDefault(e)
        }
      },
      [value, isOpen]
    )

    const itemsToRender = useMemo(() => {
      let list = []

      if (getGroupItems) {
        list = defaultItems.reduce(
          (result, group, index) => {
            const groupItems = getGroupItems(group)

            if (groupItems.length === 0) {
              return result
            }

            let newIndex = result.index

            const newItems = [
              ...result.items,
              <SptMenuItem key={index} variant="header">
                {groupToString(group)}
              </SptMenuItem>,
              ...groupItems.map((item, itemIdx) => {
                newIndex += 1

                return (
                  <SptMenuItem
                    key={`${index}-${itemIdx}`}
                    icon={item.icon}
                    iconColor={item.iconColor}
                    active={newIndex === highlightedIndex}
                    selected={itemIsSelected(item, selectedItem)}
                    {...getItemProps({
                      item,
                      index: newIndex
                    })}
                  >
                    {itemToString(item)}
                  </SptMenuItem>
                )
              })
            ]

            return {
              items: newItems,
              index: newIndex
            }
          },
          { items: [], index: -1 }
        ).items
      } else {
        list = items.map((item, index) => {
          return (
            <SptMenuItem
              key={index}
              icon={item.icon}
              iconColor={item.iconColor}
              active={index === highlightedIndex}
              selected={itemIsSelected(item, selectedItem)}
              {...getItemProps({
                item,
                index
              })}
            >
              {itemToString(item)}
            </SptMenuItem>
          )
        })
      }

      if (onCreate) {
        list.push(
          <SptMenuItem
            icon="spt-plus"
            {...getItemProps({
              key: `adicionar-${props.name}`,
              index: items.length,
              active: items.length === highlightedIndex
            })}
            data-testid={`adicionar-${props.name}`}
          >
            <SptText>Adicionar </SptText>
            {(inputValue || '').trim() !== '' && (
              <SptText fontWeight="bold" truncate>
                <>&quot;</>
                {inputValue.trim()}
                <>&quot;</>
              </SptText>
            )}
          </SptMenuItem>
        )
      }

      if (list.length > 0) {
        return list
      }

      return (
        <SptMenuItem variant="header" truncate>
          <SptText>{textHelperEmptyItems || t('geral:feedbacks.nenhumRegistro')}</SptText>
        </SptMenuItem>
      )
    }, [getItemProps, itemToString, items, highlightedIndex, selectedItem, itemIsSelected, inputValue, onCreate])

    return (
      <SptBox width={1} position="relative" {...getComboboxProps()}>
        <SptBox width={1} ref={referrence}>
          <SptField
            {...getInputProps({
              ...props,
              readOnly: !onInputChange || props.readOnly,
              ref: inputRef,
              refKey: 'inputRef',
              type: 'text',
              disabled,
              suffix: (
                <>
                  {iconClear}
                  {icon || props.suffix}
                </>
              ),
              prefix: iconLeft || props.prefix,
              onKeyDown: handleKeyDown,
              displayTextHelp
            })}
          />
        </SptBox>

        {isOpen && (
          <RemoveScroll enabled={!isSmall}>
            <SptMenuList
              zIndex={10}
              {...getMenuProps({
                ref: popper
              })}
            >
              {isSmall ? (
                <SptBox maxHeight={200}>{itemsToRender}</SptBox>
              ) : (
                <SptScrollbar maxHeight={200}>{itemsToRender}</SptScrollbar>
              )}
            </SptMenuList>
          </RemoveScroll>
        )}
      </SptBox>
    )
  }
)

SptFieldSelect.stateChangeTypes = useCombobox.stateChangeTypes

SptFieldSelect.displayName = 'SptFieldSelect'

SptFieldSelect.propTypes = {
  items: PropTypes.array.isRequired,
  itemToString: PropTypes.func,
  groupToString: PropTypes.func,
  getGroupItems: PropTypes.func,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onInputChange: PropTypes.func,
  value: PropTypes.any,
  searchIcon: PropTypes.bool,
  displayTextHelp: PropTypes.bool,
  dropDownIcon: PropTypes.bool,
  clearable: PropTypes.bool
}

SptFieldSelect.defaultProps = {}
