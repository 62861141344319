function compareByQuantity(a, b) {
  if(a.quantidade > b.quantidade) {
    return 1;
  }

  if(a.quantidade < b.quantidade) {
    return -1;
  }

  return 0;
}

export function sortEvents(eventsArray) {
  // Ordena o array de forma crescente, porém os eventos precisam
  // estar em order DECRESCENTE, por isso o .reverse()
  if(!eventsArray) {
    return null
  }

  const sortedArray = eventsArray.sort( compareByQuantity )

  return sortedArray.reverse();
}
