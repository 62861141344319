import React, { memo } from 'react'

import PropTypes from 'prop-types'

import { getIn } from 'formik'

import { SptField } from '../Field'
import { SptFieldAutocomplete } from '../FieldAutocomplete'
import { SptFieldBoxLetter } from '../FieldBoxLetter/FieldBoxLetter'
import { SptFieldButtonMultiselect } from '../FieldButtonMultiselect'
import { SptFieldButtonSelect } from '../FieldButtonSelect'
import { SptFieldCEE } from '../FieldCEE'
import { SptFieldCep } from '../FieldCep'
import { SptFieldCnpj } from '../FieldCnpj'
import { SptFieldColor } from '../FieldColor'
import { SptFieldCpf } from '../FieldCpf'
import { SptFieldCurrency } from '../FieldCurrency'
import { SptFieldDate } from '../FieldDate'
import { SptFieldDateCreditCard } from '../FieldDateCreditCard'
import { SptFieldDateMaskText } from '../FieldDateMaskText'
import { SptFieldDatePicker } from '../FieldDatePicker'
import { SptFieldDateRangePicker } from '../FieldDateRangePicker'
import { SptFieldLDBEN } from '../FieldLDBEN'
import { SptFieldLine } from '../FieldLine/FieldLine'
import { SptFieldNumber } from '../FieldNumber'
import { SptFieldNumberFloat } from '../FieldNumberFloat'
import { SptFieldPassword } from '../FieldPassword'
import { SptFieldPasswordStrength } from '../FieldPasswordStrength'
import { SptFieldPercentage } from '../FieldPercentage'
import { SptFieldPhone } from '../FieldPhone'
import { SptFieldRadioButtonGroup } from '../FieldRadioButtonGroup'
import { SptFieldRadioGroup } from '../FieldRadioGroup'
import { SptFieldSelect } from '../FieldSelect'
import { SptFieldSelectHorizontal } from '../FieldSelectHorizontal'
import { SptFieldSimple, SptFieldSimpleDate, SptFieldSimpleTime } from '../FieldSimple'
import { SptFieldTableSelect } from '../FieldTableSelect'
import { SptFieldTableSimple } from '../FieldTableSimple'
import { SptFieldTimeMask } from '../FieldTimeMask'
// eslint-disable-next-line import/no-cycle
import { SptRichTextArea } from '../RichTextArea'

const fields = {
  simple: SptFieldSimple,
  simpleDate: SptFieldSimpleDate,
  simpleTime: SptFieldSimpleTime,
  date: SptFieldDate,
  dateRangePicker: SptFieldDateRangePicker,
  datePicker: SptFieldDatePicker,
  dateCreditCard: SptFieldDateCreditCard,
  senha: SptFieldPassword,
  passwordStrength: SptFieldPasswordStrength,
  select: SptFieldSelect,
  selectHorizontal: SptFieldSelectHorizontal,
  number: SptFieldNumber,
  numberFloat: SptFieldNumberFloat,
  cpf: SptFieldCpf,
  cnpj: SptFieldCnpj,
  phone: SptFieldPhone,
  currency: SptFieldCurrency,
  percentage: SptFieldPercentage,
  radioGroup: SptFieldRadioGroup,
  radioButtonGroup: SptFieldRadioButtonGroup,
  dateMaskText: SptFieldDateMaskText,
  timeMask: SptFieldTimeMask,
  buttonSelect: SptFieldButtonSelect,
  buttonMultiselect: SptFieldButtonMultiselect,
  color: SptFieldColor,
  autocomplete: SptFieldAutocomplete,
  richTextArea: SptRichTextArea,
  cep: SptFieldCep,
  cee: SptFieldCEE,
  ldben: SptFieldLDBEN,
  boxLetter: SptFieldBoxLetter,
  line: SptFieldLine,
  tableLine: SptFieldTableSimple,
  tableSelect: SptFieldTableSelect
}

export const SptFieldFormik = memo((props) => {
  const { field, form, showLoadingWhenIsSubmitting, type, ...rest } = props
  const { name } = field
  const { touched, errors, isSubmitting, submitCount, status } = form

  const error = getIn(errors, name)
  const showError = !!error && (getIn(touched, name) || submitCount > 0)
  const variant = showError ? 'error' : props.variant
  const textHelp = showError ? error : props.textHelp
  const disabled = isSubmitting || props.disabled
  const loading = showLoadingWhenIsSubmitting && isSubmitting ? true : props.loading

  if (!field.value) {
    field.value = !Number.isNaN(parseInt(field.value, 10)) ? field.value : ''
  }

  const Comp = fields[type] || SptField
  const defaultFieldProps = fields[type] || {}

  const fieldProps = {
    ...rest,
    type,
    loading,
    variant,
    textHelp,
    disabled: disabled || status === 'DISABLE_ALL_FORM',
    error,
    ...defaultFieldProps,
    ...field
  }

  return <Comp {...fieldProps} />
})

SptFieldFormik.propTypes = {
  showLoadingWhenIsSubmitting: PropTypes.bool
}

SptFieldFormik.defaultProps = {
  showLoadingWhenIsSubmitting: false
}
