export function callAll(...fns) {
  return (...args) => {
    fns.forEach((fn) => {
      if (fn) {
        fn(...args)
      }
    })
  }
}

export function callAllEventHandlers(...fns) {
  return (event, ...args) =>
    fns.forEach((fn) => {
      if (fn) {
        fn(event, ...args)
      }
    })
}
