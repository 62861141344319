import React, { memo, useEffect, useRef, useState } from 'react'

import { SptFlex } from '@sponte/lib-components/dist/elements/Flex/Flex'

import { SptAnimationSlide } from '@sponte/lib-components/dist/atoms/AnimationSlide'
import { SptCard } from '@sponte/lib-components/dist/atoms/Card'

import { BoxWebWithBackGround } from 'components/BoxWebWithBackGround'

export const CadastroTesteGratisWrapperWeb = memo(({ children }) => {
  const ref = useRef(null)
  const [minHeightScreen, setMinHeightScreen] = useState()

  useEffect(() => {
    if (ref) {
      setMinHeightScreen(ref.current.clientHeight + 150)
    }
  }, [])

  return (
    <BoxWebWithBackGround minHeight={minHeightScreen}>
      <SptAnimationSlide direction="leftToRight" timeout={2500} appear in>
        <SptFlex flex={1} justifyContent="center" alignItems="center">
          <SptCard width={500} ref={ref}>
            {children}
          </SptCard>
        </SptFlex>
      </SptAnimationSlide>
    </BoxWebWithBackGround>
  )
})
