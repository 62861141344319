import React from 'react'

import Editor from '@draft-js-plugins/editor'

import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { getBlockAlignment, TEXT_COLORS } from './decorators'
import { useRichTextContext } from './RichTextAreaContext'
import { getPlugins } from './RichTextAreaPlugins'

export const SptRichTextAreaContentEditor = () => {
  const theme = useTheme()

  const { readOnly, handleEditorState, setReadOnly, editorState, ...restPropsContext } = useRichTextContext()

  return (
    <Editor
      {...restPropsContext}
      readOnly={readOnly}
      plugins={getPlugins(setReadOnly)}
      editorState={editorState}
      onChange={handleEditorState}
      customStyleMap={TEXT_COLORS.reduce(
        (acc, textColor) => ({
          ...acc,
          [textColor]: {
            color: theme.colors[textColor]
          }
        }),
        {}
      )}
      blockStyleFn={(block) => {
        let alignment = getBlockAlignment(block)

        if (!block.getText()) {
          const previousBlock = editorState.getCurrentContent().getBlockBefore(block.getKey())

          if (previousBlock) {
            alignment = getBlockAlignment(previousBlock)
          }
        }

        return `alignment--${alignment}`
      }}
    />
  )
}
