import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { EditorState, Modifier } from 'draft-js'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { useDisclosure } from '@sponte/lib-utils/dist/hooks/useDisclosure'

import { ButtonWithToolTip } from './ComponentsStyleds/ButtonWithTooltip'
// import { DrawerTagsDocumentoSelect } from 'components/DrawerTagsDocumento'

export const RichTextAreaContentControlsBlockInsertTags = memo(({ value, onChange, focusEditor }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()

  const handleAddTag = (subTag) => {
    const chave = getSafe(subTag, 'patternSubstituicao', '')
    const contentValue = value.getCurrentContent()
    const selection = value.getSelection()

    const nextContentValue = Modifier.insertText(contentValue, selection, chave)
    const nextEditorValue = EditorState.push(value, nextContentValue, 'insert-tag')

    onChange(nextEditorValue)

    setTimeout(() => focusEditor(), 300)
  }

  const handleToggle = () => {
    const selection = value.getSelection()

    if (selection.isCollapsed()) {
      onOpen()
    }
  }

  return (
    <>
      <ButtonWithToolTip
        tipText="Adicionar tag"
        size="sm"
        iconLeft="spt-add-tag"
        variant="text"
        palette="neutral"
        onClick={() => handleToggle()}
      >
        {t('cadastro:preferencias.documentos.adicionarCampo')}
      </ButtonWithToolTip>
      {/* {isOpen && (
        <DrawerTagsDocumentoSelect
          title={t('cadastro:preferencias.documentos.modelosDeDocumentos.adicionarCampo')}
          isOpen={isOpen}
          onClose={onClose}
          onSelect={handleAddTag}
        />
      )} */}
    </>
  )
})
