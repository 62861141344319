import React, { memo } from 'react'

import { RichUtils } from 'draft-js'

import { ButtonWithToolTip } from './ComponentsStyleds'
import { useRichTextContext } from './RichTextAreaContext'

const BLOCK_LIST_TYPES = [
  {
    icon: 'spt-list-numbered',
    style: 'ordered-list-item',
    tipText: 'Lista ordenada'
  },
  {
    icon: 'spt-list-bulleted',
    style: 'unordered-list-item',
    tipText: 'Lista simples'
  }
]

export const SptRichTextAreaContentControlsBlockListType = memo(() => {
  const { editorState, handleEditorState } = useRichTextContext()

  const selection = editorState.getSelection()
  const currentBlockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()

  const handleToggleBlockType = (blockType) => (e) => {
    e.preventDefault()

    handleEditorState(RichUtils.toggleBlockType(editorState, blockType))
  }

  return BLOCK_LIST_TYPES.map((blockListType) => (
    <ButtonWithToolTip
      tipText={blockListType.tipText}
      key={blockListType.style}
      size="sm"
      icon={blockListType.icon}
      variant="text"
      palette={currentBlockType === blockListType.style ? 'primary' : 'neutral'}
      onMouseDown={handleToggleBlockType(blockListType.style)}
    />
  ))
})

SptRichTextAreaContentControlsBlockListType.displayName = 'SptRichTextAreaContentControlsBlockListType'

SptRichTextAreaContentControlsBlockListType.propTypes = {}

SptRichTextAreaContentControlsBlockListType.defaultProps = {}
