import React from 'react'

import { SptFlex } from '../../elements/Flex'

import { SptCardHeader } from '../../atoms/Card'

import { SptRichTextAreaContentControlsAlignments } from './RichTextAreaContentControlsAlignments'
// import { RichTextAreaContentControlsBlockInsertAnexos } from './RichTextAreaContentControlsBlockInsertAnexos'
import { RichTextAreaContentControlsBlockInsertTags } from './RichTextAreaContentControlsBlockInsertTags'
import { SptRichTextAreaContentControlsBlockListType } from './RichTextAreaContentControlsBlockListType'
import { SptRichTextAreaContentControlsBlockType } from './RichTextAreaContentControlsBlockType'
import { SptRichTextAreaContentControlsImages } from './RichTextAreaContentControlsImages'
import { SptRichTextAreaContentControlsInlineStyle } from './RichTextAreaContentControlsInlineStyle'
// eslint-disable-next-line import/no-cycle
import { SptRichTextAreaContentControlsMargins } from './RichTextAreaContentControlsMargins'
// eslint-disable-next-line import/no-cycle
import { SptRichTextAreaContentControlsTable } from './RichTextAreaContentControlsTable'
import { SptRichTextAreaContentControlsTextColors } from './RichTextAreaContentControlsTextColors'
// eslint-disable-next-line import/no-cycle
import { SptRichTextAreaContentControlsVideo } from './RichTextAreaContentControlsVideo'

export const SptRichTextAreaContentControls = () => {
  return (
    <SptCardHeader py={5} px={10} justifyContent="flex-start" style={{ userSelect: 'none' }}>
      <SptFlex flexWrap="wrap">
        <SptFlex pr={5} paddingLeft={5}>
          <SptRichTextAreaContentControlsBlockType />
        </SptFlex>

        <SptFlex alignItems="center" px={5}>
          <SptRichTextAreaContentControlsMargins />
        </SptFlex>

        <SptFlex alignItems="center" px={5}>
          <SptRichTextAreaContentControlsInlineStyle />
        </SptFlex>

        <SptFlex alignItems="center" px={5}>
          <SptRichTextAreaContentControlsVideo />
        </SptFlex>

        <SptFlex alignItems="center" px={5}>
          <SptRichTextAreaContentControlsTextColors />
        </SptFlex>

        <SptFlex alignItems="center" px={5}>
          <SptRichTextAreaContentControlsAlignments />
        </SptFlex>

        <SptFlex alignItems="center" pl={5}>
          <SptRichTextAreaContentControlsBlockListType />
        </SptFlex>

        <SptFlex alignItems="center" pl={5}>
          <SptRichTextAreaContentControlsImages />
        </SptFlex>

        {/* <SptFlex alignItems="center" pl={5}>
          <SptRichTextAreaContentInsertAnexos  />
        </SptFlex> */}

        <SptFlex alignItems="center" pl={5}>
          <SptRichTextAreaContentControlsTable />
        </SptFlex>

        <SptFlex alignItems="center" pl={5}>
          <RichTextAreaContentControlsBlockInsertTags />
        </SptFlex>
      </SptFlex>
    </SptCardHeader>
  )
}

SptRichTextAreaContentControls.displayName = 'SptRichTextAreaContentControls'

SptRichTextAreaContentControls.propTypes = {}

SptRichTextAreaContentControls.defaultProps = {}
