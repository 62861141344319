import React, { memo } from 'react'

import PropTypes from 'prop-types'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptAvatar } from '../../atoms/Avatar'
import { SptBoxLoader } from '../../atoms/BoxLoader'
import { SptCloseButton } from '../../atoms/Button'
import { SptCardHeader } from '../../atoms/Card'

const Title = memo(({ title }) => {
  return (
    <SptFlex maxWidth="100%" overflow="hidden">
      <SptText fontSize="large" fontWeight="bold" truncate>
        {title}
      </SptText>
    </SptFlex>
  )
})

export const SptDrawerFormHeaderSimpleLoader = memo(({ avatar }) => {
  return (
    <SptCardHeader px={15} py={8} alignItems="center">
      <SptFlex flex={1} alignItems="center">
        {avatar && <SptBoxLoader mr={{ _: 5, tablet: 15 }} width={40} height={40} borderRadius="full" />}

        <SptBoxLoader width={{ _: 1 / 2, web: 1 / 3 }} height={20} borderRadius="sm" />
      </SptFlex>

      <SptFlex alignItems="center">
        <SptCloseButton data-testid="closeButton" ml={10} disabled />
      </SptFlex>
    </SptCardHeader>
  )
})

export const SptDrawerFormHeaderSimple = memo(({ avatar, icon, title, onClose, action }) => {
  return (
    <SptCardHeader px={15} py={8} alignItems="center">
      <SptFlex alignItems="center" overflow="hidden" width={1}>
        {icon || avatar ? (
          <SptAvatar mr={{ _: 5, tablet: 15 }} size="xs" name={title} icon={icon} image={avatar} />
        ) : null}

        <Title title={title} />
      </SptFlex>

      <SptFlex alignItems="center">
        {action}

        {onClose && <SptCloseButton data-testid="closeButton" onClick={() => onClose()} ml={10} />}
      </SptFlex>
    </SptCardHeader>
  )
})

SptDrawerFormHeaderSimple.displayName = 'SptDrawerFormHeaderSimple'

SptDrawerFormHeaderSimple.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  action: PropTypes.node
}

SptDrawerFormHeaderSimple.defaultProps = {}
