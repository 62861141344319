import React, { memo, forwardRef } from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'
import { fontFamily } from 'styled-system'

import { theme, fontSize } from '@sponte/lib-utils/dist/theme/tools'

const SptInputStyled = styled.input`
  border: 0;
  padding: 0;
  color: ${theme('colors.almostBlack')};
  background-color: transparent;
  font-weight: 500;
  ${fontSize('default')};
  ${fontFamily};
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
  -webkit-text-security: ${(props) =>
    (props.type === 'senha' || props.type === 'passwordStrength') && !props.showPassword ? 'disc' : 'none'};

  ::placeholder {
    color: ${theme('colors.mediumGrey')};
  }

  ::autofill {
    background-color: transparent;
  }
`

SptInputStyled.displayName = 'SptInputStyled'

export const SptInput = memo(
  forwardRef((props, ref) => {
    return <SptInputStyled ref={ref} {...props} />
  })
)

SptInput.displayName = 'SptInput'

SptInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

SptInput.defaultProps = {
  autoComplete: 'off'
}
