import styled from 'styled-components'

import { theme as getTheme, fontSize } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../../elements'

export const RichTextAreaStyled = styled(SptBox)`
  .alignment--left {
    .public-DraftStyleDefault-block {
      text-align: left;
    }

    img {
      &:hover {
        border: 2px solid ${getTheme('colors.blue')};
      }
    }
  }

  .alignment--center {
    .public-DraftStyleDefault-block {
      text-align: center;
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      &:hover {
        border: 2px solid ${getTheme('colors.blue')};
      }
    }
  }

  .alignment--right {
    .public-DraftStyleDefault-block {
      text-align: right;
    }

    img {
      position: relative;
      float: right;

      &:hover {
        border: 2px solid ${getTheme('colors.blue')};
      }
    }
  }

  .alignment--justify {
    .public-DraftStyleDefault-block {
      text-align: justify;
    }

    img {
      &:hover {
        border: 2px solid ${getTheme('colors.blue')};
      }
    }
  }

  .i1hzzm9j {
    // TODO: make PR on draft-js-plugins fixing this
    // prevent video from overflowing
    max-width: 100%;
  }

  .f1vn2c6d {
    // add border to selected image
    border: 2px solid ${getTheme('colors.blue')};
  }

  .public-DraftEditor-content {
    ${fontSize('default')};

    h1 {
      ${fontSize('xlarge')};
      margin: ${getTheme('space.5')}px 0;
      font-weight: inherit;
    }

    h2 {
      ${fontSize('large')};
      margin: ${getTheme('space.2')}px 0;
      font-weight: inherit;
    }

    blockquote {
      border-left: ${getTheme('space.1')}px solid ${getTheme('colors.mediumGrey')};
      padding-left: ${getTheme('space.10')}px;
      color: ${getTheme('colors.mediumGrey')};
    }

    ol {
      list-style: decimal;
      margin-left: ${getTheme('space.8')}px;

      .alignment--center {
        max-width: max-content;
        margin: auto;
        text-align: center;
      }

      .alignment--right {
        max-width: max-content;
        margin-left: auto;
      }
    }

    ul {
      list-style: disc;
      margin-left: ${getTheme('space.8')}px;

      .alignment--center {
        max-width: max-content;
        margin: auto;
        text-align: center;
      }

      .alignment--right {
        max-width: max-content;
        margin-left: auto;
      }
    }
  }
`
