import React, { memo, useMemo, useCallback, useRef, useEffect } from 'react'

import PropTypes from 'prop-types'

import { SptFlex } from '../../elements/Flex'

import { SptFieldBoxLetterInputStyled } from './FieldBoxLetterInputStyled'
import { keysNotAllow, numberOnly } from './utils'

export const SptFieldBoxLetterSimple = memo(
  ({ id, name, value, disabled = false, onChange, maxLength, setValue, onlyNumber, ...props }) => {
    const inputsRefs = useRef(null)

    useEffect(() => {
      inputsRefs.current = document.getElementsByName(`${name}-input`)
    }, [])

    const internalValue = useMemo(() => {
      const arrayValue = value ? String(value).split('') : []

      return Array.from({ length: maxLength }).map((_, i) => (i > arrayValue.length - 1 ? '' : arrayValue[i]))
    }, [value, maxLength])

    const setInputFocus = useCallback(() => {
      if (!value && !disabled) {
        document.getElementById(`${name}-0`).focus()
      }
    }, [disabled, value, name])

    const handleKeyDown = useCallback(
      (e, i) => {
        const prevCharacter = inputsRefs.current[i - 1]
        const nextCharacter = inputsRefs.current[i + 1]

        if (keysNotAllow(e.key) || (onlyNumber && numberOnly(e.key))) {
          return
        }

        if (e.key === 'ArrowRight') {
          if (nextCharacter) {
            nextCharacter.focus()
          }
          return
        }

        if (e.key === 'ArrowLeft') {
          if (prevCharacter) {
            prevCharacter.focus()
          }
          return
        }

        const valueCurrent =
          e.key.length === 1
            ? internalValue.map((ar, index) => (index === i ? e.key : ar))
            : internalValue.map((ar, index) => (index === i ? '' : ar))

        if (typeof onChange === 'function') {
          onChange({
            ...e,
            target: {
              ...e.target,
              id: props.id,
              type: 'text',
              name,
              value: valueCurrent.join('')
            }
          })
        }

        if (typeof setValue === 'function') {
          setValue(valueCurrent.join(''))
        }

        if ((e.key === 'Backspace' || e.key === 'Delete') && prevCharacter) {
          prevCharacter.focus()
          return
        }

        if (e.key.length === 1 && nextCharacter) {
          nextCharacter.focus()
        }
      },
      [name, internalValue]
    )

    return (
      <SptFlex
        width={{
          web: `${10 * maxLength}px`,
          hd: `${12 * maxLength}px`,
          fullhd: `${18 * maxLength}px`
        }}
        onClick={setInputFocus}
      >
        {internalValue.map((caracter, i) => (
          <SptFieldBoxLetterInputStyled
            key={i}
            id={`${name}-${i}`}
            name={`${name}-input`}
            {...props}
            maxLength={1}
            hasBorderRight={i === maxLength - 1}
            onKeyDown={(e) => handleKeyDown(e, i)}
            value={caracter}
            disabled={disabled}
          />
        ))}
      </SptFlex>
    )
  }
)

SptFieldBoxLetterSimple.displayName = 'SptFieldBoxLetterSimple'

SptFieldBoxLetterSimple.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  hasValue: PropTypes.bool
}
