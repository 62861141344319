import React from 'react'

import { SptBox } from '../../elements/Box'

export const SptMenuItemDivider = (props) => {
  return <SptBox my={4} bg="mediumGrey" minHeight="1px" width={1} {...props} />
}

SptMenuItemDivider.displayName = 'SptMenuItemDivider'

SptMenuItemDivider.propTypes = {}

SptMenuItemDivider.defaultProps = {}
