import React, { Suspense } from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'

import { SptLoading } from '@sponte/lib-components/dist/atoms/Loading'

import { CadastroTesteGratis } from './CadastroTesteGratis'

const UnknownUser = () => {
  return (
    <Suspense fallback={<SptLoading full size="lg" p={18} width={200} />}>
      <Switch>
        <Route
          path="/teste-gratis"
          render={() => (
            <Suspense fallback={<SptLoading full size="lg" p={18} width={200} />}>
              <Switch>
                <Route path="/teste-gratis" component={CadastroTesteGratis} />
                <Redirect to="/teste-gratis" />
              </Switch>
            </Suspense>
          )}
        />

        <Redirect to="/teste-gratis" />
      </Switch>
    </Suspense>
  )
}

export default UnknownUser
