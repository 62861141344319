import React, { useRef, useCallback } from 'react'

import createImagePlugin from '@draft-js-plugins/image'

import { SptInput } from '../../atoms/Input'

import { ButtonWithToolTip } from './ComponentsStyleds'
import { useRichTextContext } from './RichTextAreaContext'

export const SptRichTextAreaContentControlsImages = () => {
  const inputFileRef = useRef(null)
  const { editorState, handleEditorState } = useRichTextContext()

  const imagePlugin = createImagePlugin()

  const handleOpenUploadFile = useCallback(() => {
    inputFileRef.current.click()
  }, [inputFileRef.current])

  const insertImage = useCallback(
    (event) => {
      event.preventDefault()

      const urlValue = URL.createObjectURL(event.target.files[0])

      handleEditorState(imagePlugin.addImage(editorState, urlValue, { file: event.target.files[0] }))

      // TODO: por algum motivo quando o arquivo é selecionado duas vezes, ele cacheia a info. Verificar!
      // eslint-disable-next-line no-param-reassign
      event.target.value = null
    },
    [imagePlugin, handleEditorState, editorState]
  )

  return (
    <>
      <ButtonWithToolTip
        size="sm"
        palette="neutral"
        variant="text"
        tipText="Inserir imagem"
        icon="spt-photo"
        data-testid="button-upload-image"
        onClick={handleOpenUploadFile}
      />

      <SptInput type="file" onChange={insertImage} ref={inputFileRef} accept="image/*" hidden />
    </>
  )
}
