import React, { memo, useMemo, useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { COMMON_PALETTES, CALENDAR_EVENTS } from '@sponte/lib-utils/dist/theme/constants'

import { SptIcon } from '../../atoms/Icon'

import { SptFieldSelect } from '../FieldSelect'

export const SptFieldColor = memo(({ value, onChange, calendar = false, calendarUsedColors = [], ...props }) => {
  const { t } = useTranslation()

  const items = useMemo(() => {
    if (calendar) {
      return CALENDAR_EVENTS.filter(colors => !calendarUsedColors.includes(colors)).map((palette) => ({
        id: palette,
        nome: t(`geral:cores.${palette}`),
        icon: 'spt-fiber-manual-record',
        iconColor: palette
      }))
    }

    return COMMON_PALETTES.map((palette) => ({
      id: palette,
      nome: t(`geral:cores.${palette}`),
      icon: 'spt-fiber-manual-record',
      iconColor: palette
    }))
  }, [t])

  const icon = useMemo(() => {
    return <SptIcon color={value}>spt-fiber-manual-record</SptIcon>
  }, [value])

  const handleChange = useCallback(
    (e) => {
      if (!onChange) {
        return
      }

      onChange({
        target: {
          ...e.target,
          value: getSafe(e, 'target.value.id', null)
        }
      })
    },
    [onChange]
  )

  return (
    <SptFieldSelect
      {...props}
      items={items}
      prefix={icon}
      value={items.find((item) => item.id === value)}
      onChange={handleChange}
      readOnly
    />
  )
})

SptFieldColor.displayName = 'SptFieldColor'

SptFieldColor.propTypes = {}

SptFieldColor.defaultProps = {}
