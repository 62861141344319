import React from 'react'

import { convertToHTML } from 'draft-convert'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { PALETTES } from '@sponte/lib-utils/dist/theme/constants'

export const convertHTML = ({ theme, value }) => {
  return convertToHTML({
    // eslint-disable-next-line consistent-return
    styleToHTML: (style) => {
      const color = PALETTES.find((paletteColor) => paletteColor === style)

      if (color) {
        return <span style={{ color: theme.colors[color] }} />
      }
    },
    // eslint-disable-next-line consistent-return
    blockToHTML: (block) => {
      const style = block.inlineStyleRanges.find((item) => {
        return item.style === 'center' || item.style === 'right' || item.style === 'left'
      })

      if (block.type === 'unstyled') {
        return <p style={{ textAlign: getSafe(style, 'style', 'left') }} />
      }

      if (block.type === 'atomic') {
        return (
          <div
            style={{
              textAlign: getSafe(style, 'style', 'left'),
              float: getSafe(style, 'style') === 'right' ? 'right' : 'none'
            }}
          />
        )
      }
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'LINK') {
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={entity.data.url} target="_blank">
            {originalText}
          </a>
        )
      }

      if (entity.type === 'IMAGE') {
        return <img alt="" src={entity.data.src} width={entity.data.width ? `${entity.data.width}%` : 'auto'} />
      }

      if (entity.type === 'TABLE') {
        return (
          <table style={{ border: '1px solid', 'border-collapse': 'collapse' }}>
            <thead>
              {getSafe(entity, 'data.columns', []).map((column) => (
                <th style={{ border: '1px solid', height: '20px' }} key={column.key}>
                  {column.value}
                </th>
              ))}
            </thead>

            <tbody>
              {getSafe(entity, 'data.rows', []).map((row) => (
                <tr key={row.key}>
                  {getSafe(row, 'value', []).map((col) => (
                    <td
                      style={{ border: '1px solid', height: '20px', 'min-width': '60px', 'max-width': '120px' }}
                      key={col.key}
                    >
                      {col.value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )
      }

      return originalText
    }
  })(value.getCurrentContent())
}
