import React, { useEffect, useMemo } from 'react'

import Row from './Row'
import Th from './Th'

export default ({ block, blockProps, onToggleReadOnly }) => {
  const onChange = (column) => (value) => {
    const { columns, editColumn, getEditorState, setEditorState } = blockProps

    setEditorState(
      editColumn({
        editorState: getEditorState(),
        columns,
        column: { ...column, value },
        block
      })
    )
  }

  const addColumn = ({ index }) => () => {
    const { addColumn: addCol, columns, rows, getEditorState, setEditorState } = blockProps

    setEditorState(
      addCol({
        columns,
        rows,
        index,
        editorState: getEditorState(),
        block
      })
    )
  }

  const onRemoveColumn = ({ index }) => () => {
    const { removeColumn, columns, rows, getEditorState, setEditorState } = blockProps
    setEditorState(
      removeColumn({
        columns,
        rows,
        index,
        editorState: getEditorState(),
        block
      })
    )
  }

  const tableId = useMemo(() => {
    return `table-${blockProps.getEditorState().getSelection().getAnchorKey()}`

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleClick = (event) => {
      const ignoreClickOnMeElement = document.getElementById(tableId)

      const isClickInsideElement = ignoreClickOnMeElement.contains(event.target)
      if (!isClickInsideElement) {
        onToggleReadOnly(false)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <table id={tableId} style={{ border: '1px solid' }}>
      <thead>
        <tr>
          {blockProps.columns.map((col, i) => (
            <Th
              key={col.key}
              value={col.value}
              onAddColumnLeft={addColumn({
                index: i
              })}
              onAddColumnRight={addColumn({ index: i + 1 })}
              onRemoveColumn={blockProps.columns.length > 1 ? onRemoveColumn({ index: i }) : null}
              onToggleReadOnly={onToggleReadOnly}
              onChange={onChange(col)}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {blockProps.rows.map((row, rowIndex) => (
          <Row
            key={row.key}
            row={row}
            isOnlyRow={blockProps.rows.length === 1}
            block={block}
            blockProps={blockProps}
            rowIndex={rowIndex}
            columns={blockProps.columns}
            onToggleReadOnly={onToggleReadOnly}
          />
        ))}
      </tbody>
    </table>
  )
}
