export function getNameInitials(string) {
  const names = string.split(' ')

  let initials = names[0].charAt(0)

  if (names.length > 1) {
    initials += names[names.length - 1].charAt(0)
  }

  return initials.toUpperCase()
}

export function isolateExtensionType(string) {
  return string.split(/\.(?=[^.]+$)/)
}

export function removeAccents(string) {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
