import React, { memo, useContext, useState } from 'react'

import { useMedia } from '@sponte/lib-utils/dist/hooks/useMedia'
import { useStepper } from '@sponte/lib-utils/dist/hooks/useStepper'
import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { CadastroTesteGratisContext } from './CadastroTesteGratisContext'
import { CadastroTesteGratisFinish } from './CadastroTesteGratisFinish'
import { CadastroTesteGratisFormik } from './CadastroTesteGratisFormik'
import { CadastroTesteGratisWrapperMobile } from './CadastroTesteGratisWrapperMobile'
import { CadastroTesteGratisWrapperWeb } from './CadastroTesteGratisWrapperWeb'

export const CadastroTesteGratis = memo(() => {
  const [step, , setStep] = useStepper()
  const [newUser, setNewUser] = useState()

  return (
    <CadastroTesteGratisContext.Provider value={{ step, setStep, newUser, setNewUser }}>
      <CadastroTesteGratisComp />
    </CadastroTesteGratisContext.Provider>
  )
})

const CadastroTesteGratisComp = memo(() => {
  const { step } = useContext(CadastroTesteGratisContext)

  const theme = useTheme()
  const isSmall = useMedia(theme.mediaQueries.down.tablet, true, false)

  if (step === 2) {
    return <CadastroTesteGratisFinish />
  }

  if (isSmall) {
    return (
      <CadastroTesteGratisWrapperMobile>
        <CadastroTesteGratisFormik />
      </CadastroTesteGratisWrapperMobile>
    )
  }

  return (
    <CadastroTesteGratisWrapperWeb>
      <CadastroTesteGratisFormik />
    </CadastroTesteGratisWrapperWeb>
  )
})
