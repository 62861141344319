import React from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { ifProp, theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'
import { SptFlex } from '../../elements/Flex'

import { SptBadge } from '../../atoms/Badge'

export const SptBadgeStyled = styled(SptBadge)`
  border-radius: ${theme('radii.lg')}px;
  z-index: 10;
  height: 24px;
  width: 24px;
  margin-top: 24px;

  ${ifProp(
    'active',
    css`
      background-color: ${theme('colors.primary')};
    `,
    css`
      background-color: ${theme('colors.white')};
      border: 1px solid ${theme('colors.primary')};
    `
  )};

  ${ifProp(
    'onClick',
    css`
      cursor: pointer;
    `
  )};
`

SptBadgeStyled.displayName = 'SptBadgeStyled'

export const SptStepperLineStyled = styled(SptBox)`
  ${ifProp(
    'completedStep',
    css`
      background-color: ${theme('colors.primary')};
    `,
    css`
      background-color: ${theme('colors.primaryLight')};
    `
  )};
  height: 5px;
  margin-left: 10px;
  position: absolute;
  top: 34px;
  transform: rotate(180deg);
  width: 80px;
  ${theme('mediaQueries.down.web')} {
    width: 40px;
  }
  z-index: -1;
`

SptStepperLineStyled.displayName = 'SptStepperLineStyled'

export const SptStepperItem = ({ children, onClick, active, completedStep, isSmall, ...props }) => {
  return (
    <SptFlex position="relative" justifyContent="center" px={isSmall ? 7 : 15}>
      <SptFlex alignItems="center" flexDirection="column" {...props}>
        <SptBadgeStyled
          active={active}
          completedStep={completedStep}
          onClick={onClick}
          color={active ? 'white' : 'primary'}
        >
          {children}
        </SptBadgeStyled>

        <SptStepperLineStyled completedStep={completedStep} />
      </SptFlex>
    </SptFlex>
  )
}

SptStepperItem.displayName = 'SptStepperItem'

SptStepperItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  text: PropTypes.string
}

SptStepperItem.defaultProps = {}
