import React, { useRef, useMemo, useState, useCallback } from 'react'

import styled from 'styled-components'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { callAllEventHandlers } from '@sponte/lib-utils/dist/helpers/callAll'
import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { theme, fontSize } from '@sponte/lib-utils/dist/theme/tools'
import { usePopper } from '@sponte/lib-utils/src/hooks/usePopper'

import { SptBox } from '../../elements/Box'

import { SptButton } from '../../atoms/Button'
import { SptPortal } from '../../atoms/Portal'

import { SptFieldDate } from '../FieldDate'

const Calendar = styled(SptBox)`
  .DateInput {
    display: none;
  }

  .SingleDatePicker {
    display: block !important;
  }

  .SingleDatePicker_picker {
    z-index: 99999;
    position: fixed;
    top: ${(props) => props.y || 0}px !important;
    left: ${(props) => props.x || 0}px !important;
  }

  .CalendarMonth_caption {
    font-weight: ${theme('fontWeights.medium')};
    ${fontSize('default')}
    width: 124%;
    margin-left: -23px;
    padding-bottom: ${theme('space.11')}px;
    margin-bottom: ${theme('space.15')}px;
    background: ${theme('colors.lightGrey')};
  }

  .CalendarMonthGrid {
    background: transparent;
  }

  .CalendarDay {
    vertical-align: middle !important;
    border-radius: ${theme('radii.md')}px;
    width: ${theme('space.14')}px !important;
    height: ${theme('space.14')}px !important;
  }

  .CalendarDay:hover {
    border: none;
  }

  .CalendarDay__today {
    font-weight: ${theme('fontWeights.bold')} !important;
  }

  .CalendarDay__hovered_span {
    background: ${theme('colors.primary')};
    color: ${theme('colors.white')};
  }

  .CalendarDay__hovered_span:hover {
    background: ${theme('colors.primary')};
    color: ${theme('colors.white')};
  }

  .CalendarDay__selected {
    background: ${theme('colors.primary')};
    font-weight: ${theme('fontWeights.bold')};
  }

  .CalendarDay__selected:hover {
    background: ${theme('colors.primaryDark')};
    font-weight: ${theme('fontWeights.bold')};
  }

  .CalendarDay__selected_span {
    background: ${theme('colors.primary')};
    font-weight: ${theme('fontWeights.bold')};
  }

  .CalendarDay__selected_span:hover {
    background: ${theme('colors.primaryDark')};
    font-weight: ${theme('fontWeights.bold')};
  }

  .CalendarDay__default {
    font-family: ${theme('fonts.default')};
    font-weight: ${theme('fontWeights.medium')};
    ${fontSize('medium')}
    border: none;
    line-height: 0 !important;
  }

  .CalendarDay__blocked_calendar {
    border: none;
    background: ${theme('colors.lightGrey')};
    color: ${theme('colors.mediumGrey')};
    border-radius: 0;
  }

  .CalendarDay__blocked_calendar:hover {
    border: none;
    background: ${theme('colors.lightGrey')};
    color: ${theme('colors.mediumGrey')};
  }
  .DayPicker {
    overflow: hidden;
    width: auto !important;
    border-radius: ${theme('radii.sm')}px;
  }

  .DayPicker_weekHeader {
    background: ${theme('colors.white')};
    font-weight: ${theme('fontWeights.medium')};
    color: ${theme('colors.almostBlack')};
    margin-top: ${theme('space.5')}px;
  }

  .DayPicker_weekHeader_li {
    width: ${theme('space.14')}px !important;
  }

  .DayPickerNavigation {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .DayPickerNavigation_button button {
    margin-top: ${theme('space.6')}px;
    margin-left: ${theme('space.4')}px;
    margin-right: ${theme('space.4')}px;
  }

  .DayPicker_transitionContainer {
    background: transparent;
  }
`

Calendar.displayname = 'Calendar'

export const SptFieldDatePicker = ({
  calendarPositon: calendarPositonProp,
  blockedWeekDays = [],
  focused: focusedProp = false,
  numberOfMonths = 1,
  onFocus,
  onChange,
  onClick,
  hidden = false,
  pickerOnly,
  readOnly,
  ...props
}) => {
  const { t } = useTranslation()

  const calcComponentHeight = () =>
    document.getElementsByClassName('DayPicker_focusRegion')[0] &&
    document.getElementsByClassName('DayPicker_focusRegion')[0].clientHeight > 251
      ? document.getElementsByClassName('DayPicker_focusRegion')[0].clientHeight
      : 251

  const dateMask = useMemo(() => {
    return t('geral:date_mask')
  }, [t])

  const [focused, setFocused] = useState(focusedProp)

  const [height, setHeight] = useState()
  const handleHeight = useCallback(() => {
    setHeight(calcComponentHeight)
  }, [setHeight])

  const boxRef = useRef()

  const calendarPositon = calendarPositonProp || (boxRef.current && boxRef.current.getBoundingClientRect())

  const { id, name, value: valueProp } = props

  const handleChange = useCallback(
    (value) => {
      if (onChange && moment(value, dateMask, true).isValid()) {
        onChange({
          target: {
            id,
            name,
            type: 'text',
            value: value.format()
          }
        })
      } else if (onChange) {
        onChange(value)

        if (getSafe(value, 'target.value.length', false) > 9) {
          setFocused(false)
        }
      }
    },
    [id, name, onChange]
  )

  const isDayBlocked = useCallback(
    (value) => {
      return blockedWeekDays.includes(value.day())
    },
    [blockedWeekDays]
  )

  const { referrence, popper, styles } = usePopper({
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: ({ placement }) => {
            if (placement === 'top') {
              return [0, -16]
            }
            return []
          }
        }
      }
    ]
  })

  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 9) setFocused(false)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <SptFieldDate
        ref={referrence}
        boxRef={hidden ? boxRef : referrence}
        onChange={handleChange}
        onFocus={callAllEventHandlers(() => setFocused(true), onFocus)}
        onClick={callAllEventHandlers(() => setFocused(true), onClick)}
        onKeyDown={handleKeyDown}
        style={{ cursor: 'pointer', visibility: hidden && 'hidden' }}
        readOnly={readOnly || pickerOnly}
        {...props}
      />

      {!readOnly && focused ? (
        <SptPortal>
          <Calendar
            zIndex={10000}
            tabIndex="-1"
            role="menu"
            ref={popper}
            style={{ ...styles, height }}
            data-testid="input-date-picker"
            x={calendarPositon && calendarPositon.x}
            y={calendarPositon && calendarPositon.bottom}
            verticalHeight={260}
          >
            <SingleDatePicker
              renderCalendarInfo={handleHeight}
              isDayBlocked={isDayBlocked}
              isOutsideRange={() => false}
              numberOfMonths={numberOfMonths}
              onDateChange={handleChange}
              focused={focused}
              onFocusChange={(e) => setFocused(e.focused)}
              date={valueProp && moment(valueProp).isValid() ? moment(valueProp) : null}
              displayFormat={() => dateMask}
              noBorder
              daySize={28}
              hideKeyboardShortcutsPanel
              navPrev={<SptButton icon="spt-caret-left" palette="neutral" variant="text" />}
              navNext={<SptButton icon="spt-caret-right" palette="neutral" variant="text" />}
              readOnly={readOnly}
              {...props}
            />
          </Calendar>
        </SptPortal>
      ) : null}
    </>
  )
}

SptFieldDatePicker.propTypes = {}

SptFieldDatePicker.defaultProps = {}
