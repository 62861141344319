import React, { memo, useCallback, useContext, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { SptBox } from '@sponte/lib-components/dist/elements/Box/Box'
import { SptFlex } from '@sponte/lib-components/dist/elements/Flex/Flex'

import { SptButton } from '@sponte/lib-components/dist/atoms/Button/Button'
import { SptScrollbar } from '@sponte/lib-components/dist/atoms/Scrollbar/Scrollbar'

import { useMutationTenantsContaGratisControllerCadastrar } from 'api/retaguarda'

import { CadastroTesteGratisContext } from './CadastroTesteGratisContext'
import { CadastroTesteGratisFormikForm } from './CadastroTesteGratisFormikForm'
import { CadastroTesteGratisStepper } from './CadastroTesteGratisStepper'

const validationStepOne = ['nome', 'email', 'senha']
const validationStepTwo = ['telefone', 'numeroDocumento', 'subdominio', 'concordoComTermos']

export const CadastroTesteGratisFormik = memo(() => {
  const { t } = useTranslation()
  const { step, setStep, setNewUser } = useContext(CadastroTesteGratisContext)

  const params = new URLSearchParams(window.location.search)

  const initialValues = useMemo(() => {
    const rawData = params.get('data')
    let data = null

    if (rawData) {
      const decodedData = atob(rawData)

      try {
        data = JSON.parse(decodedData)
      } catch {
        data = JSON.parse(decodedData.replace(/,\s([^,]+)$/, '}'))
      }
    }

    return {
      cpfCnpj: {
        chave: 'CPF'
      },
      concordoComTermos: false,
      nome: getSafe(data, 'nome', null),
      email: getSafe(data, 'email', null),
      telefone: getSafe(data, 'phone', null)
    }
  }, [params])

  const [cadastrar] = useMutationTenantsContaGratisControllerCadastrar()

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string().email(t('geral:validacoes.email')).nullable().required(t('geral:validacoes.obrigatorio')),
        numeroDocumento: Yup.string().when('cpfCnpj.chave', {
          is: (chave) => chave === 'CNPJ',
          then: Yup.string().cnpj(t('geral:validacoes.cnpj')).required(t('geral:validacoes.obrigatorio')),
          otherwise: Yup.string().cpf(t('geral:validacoes.cpf')).required(t('geral:validacoes.obrigatorio'))
        }),
        nome: Yup.string().required(t('geral:validacoes.obrigatorio')).nullable(),
        telefone: Yup.string().required(t('geral:validacoes.obrigatorio')).nullable(),
        senha: Yup.string().required(t('geral:validacoes.obrigatorio')).passwordStrength(),
        subdominio: Yup.string().required(t('geral:validacoes.obrigatorio')),
        concordoComTermos: Yup.boolean().oneOf([true], 'Precisa aceitar os Termos de Uso e a Política de Privacidade.')
      }),
    [t]
  )

  const handleAvancar = useCallback(
    (submitForm, errosForm, setTouched) => {
      if (step === 0 && Object.keys(errosForm).some((key) => validationStepOne.includes(key))) {
        setTouched({ nome: true, email: true, senha: true }, true)
        return
      }

      if (step === 1 && Object.keys(errosForm).some((key) => validationStepTwo.includes(key))) {
        setTouched({ telefone: true, subdominio: true, numeroDocumento: true, concordoComTermos: true }, true)
        return
      }

      if (step === 1 && Object.keys(errosForm).length === 0) {
        submitForm()
        return
      }
      setStep()
    },
    [step, setStep]
  )

  const onSubmit = useCallback(
    (values, formik) => {
      cadastrar(
        {
          nomeCompleto: values.nome,
          nomeFantasia: values.nome,
          cnpj: values.cpfCnpj.chave === 'CNPJ' ? values.numeroDocumento : null,
          cpf: values.cpfCnpj.chave === 'CPF' ? values.numeroDocumento : null,
          subdominio: values.subdominio,
          isTesteGratis: true,
          contatoTelefoneCelular: values.telefone,
          contatoEmail: values.email,
          usuarioEmail: values.email,
          usuarioNomeCompleto: values.nome,
          usuarioSenha: values.senha,
          verticalSistema: 0
        },
        {
          onSuccess: (data) => {
            formik.resetForm({ values })
            setStep()
            setNewUser(data.nomeCompleto)
          },
          onSettled: () => {
            formik.setSubmitting(false)
          }
        }
      )
    },
    [cadastrar, setStep, setNewUser]
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnMount>
      {({ submitForm, errors, isSubmitting, setTouched }) => (
        <SptScrollbar>
          <SptBox px={15} py={step === 0 ? 15 : 10} maxWidth="100%">
            <CadastroTesteGratisFormikForm step={step} />

            <SptFlex flex={1} mt={10} ml={-10} justifyContent="space-between" alignItems="center">
              <CadastroTesteGratisStepper step={step} />
              <SptButton
                onClick={() => handleAvancar(submitForm, errors, setTouched)}
                iconLeft="spt-caret-right"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('geral:avancar')}
              </SptButton>
            </SptFlex>
          </SptBox>
        </SptScrollbar>
      )}
    </Formik>
  )
})
