import { useState, useMemo } from 'react'

export const useFocus = () => {
  const [isFocused, setIsFocused] = useState(false)

  const binds = useMemo(
    () => ({
      onFocus: () => setIsFocused(true),
      onBlur: () => setIsFocused(false)
    }),
    [setIsFocused]
  )

  return [isFocused, binds]
}
