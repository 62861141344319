export const move = (from, to, list) => {
  const { length } = list
  const result = list.slice()
  const positiveFrom = from < 0 ? length + from : from
  const positiveTo = to < 0 ? length + to : to
  const item = result.splice(positiveFrom, 1)

  return positiveFrom < 0 || positiveFrom >= list.length || positiveTo < 0 || positiveTo >= list.length
    ? list
    : [].concat(result.slice(0, positiveTo)).concat(item).concat(result.slice(positiveTo, list.length))
}

export const sortByLastUpdate = (a, b) => {
  const dateA = new Date(a.atualizadoEm).getTime()
  const dateB = new Date(b.atualizadoEm).getTime()

  return dateA < dateB ? 1 : -1
}
