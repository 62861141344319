import styled from 'styled-components'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

export const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  margin-right: 5px;
`

export const EventColor = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 4px;

  background: ${(props) => props.color};
`
export const EventName = styled.span`
  color: #000;
  text-align: center;
  font-family: ${theme('fonts.default')};
  font-weight: 500;
  font-size: 12px;
`
