import React, { memo } from 'react'

import { SptFlex } from '@sponte/lib-components/dist/elements/Flex/Flex'

export const BoxWebWithBackGround = memo(({ children, minHeight }) => {
  return (
    <SptFlex
      width="100%"
      height="100%"
      backgroundColor="white"
      position="absolute"
      minHeight={minHeight}
      backgroundImage="url('/assets/backgroundFreeTest.svg')"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      justifyContent="center"
      alignItems="centers"
    >
      {children}
    </SptFlex>
  )
})
