import styled, { keyframes } from 'styled-components'

import { SptBox } from '../../elements/Box'

const blink = keyframes`
  0% {
    opacity: .1;
  }
  
  50% {
    opacity: .2;
  }
  
  100% {
    opacity: .1;
  }
`

export const SptBoxLoader = styled(SptBox).attrs({ bg: 'almostBlack' })`
  opacity: 0.1;
  animation: ${blink} 1.5s infinite;
`

SptBoxLoader.displayName = 'SptBoxLoader'

SptBoxLoader.propTypes = {}

SptBoxLoader.defaultProps = {}
