import React, { memo } from 'react'

import { EditorState, Modifier } from 'draft-js'

import { ButtonWithToolTip } from './ComponentsStyleds/ButtonWithTooltip'
import { getBlockAlignment } from './decorators'
import { useRichTextContext } from './RichTextAreaContext'

const styleWholeSelectedBlocksModifier = (editorState, style, removeStyles = []) => {
  const currentContent = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const focusBlock = currentContent.getBlockForKey(selection.getFocusKey())
  const anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey())
  const selectionIsBackward = selection.getIsBackward()

  let changes = {
    anchorOffset: 0,
    focusOffset: focusBlock.getLength()
  }

  if (selectionIsBackward) {
    changes = {
      focusOffset: 0,
      anchorOffset: anchorBlock.getLength()
    }
  }

  const selectWholeBlocks = selection.merge(changes)
  const modifiedContent = Modifier.applyInlineStyle(currentContent, selectWholeBlocks, style)
  const finalContent = removeStyles.reduce((content, currentStyle) => {
    return Modifier.removeInlineStyle(content, selectWholeBlocks, currentStyle)
  }, modifiedContent)

  return EditorState.push(editorState, finalContent, 'change-inline-style')
}

const ALIGNMENTS = [
  {
    icon: 'spt-text-left',
    style: 'left',
    tipText: 'Alinhar à esquerda'
  },
  {
    icon: 'spt-text-center',
    style: 'center',
    tipText: 'Alinhar no centro'
  },
  {
    icon: 'spt-text-right',
    style: 'right',
    tipText: 'Alinhar à direita'
  },
  {
    icon: 'spt-text-justify',
    style: 'justify',
    tipText: 'Justificar'
  }
]

export const SptRichTextAreaContentControlsAlignments = memo(() => {
  const { editorState, handleEditorState } = useRichTextContext()

  const selection = editorState.getSelection()
  const currentBlock = editorState.getCurrentContent().getBlockForKey(selection.getStartKey())

  const currentBlockAlignment = getBlockAlignment(currentBlock)

  const handleToggleAlignment = (alignment) => (e) => {
    e.preventDefault()

    handleEditorState(
      styleWholeSelectedBlocksModifier(
        editorState,
        alignment,
        ALIGNMENTS.map((align) => align.style).filter((align) => align !== alignment)
      )
    )
  }

  return ALIGNMENTS.map((alignment) => (
    <ButtonWithToolTip
      key={alignment.style}
      size="sm"
      icon={alignment.icon}
      variant="text"
      tipText={alignment.tipText}
      palette={currentBlockAlignment === alignment.style ? 'primary' : 'neutral'}
      onMouseDown={handleToggleAlignment(alignment.style)}
    />
  ))
})

SptRichTextAreaContentControlsAlignments.displayName = 'SptRichTextAreaContentControlsAlignments'

SptRichTextAreaContentControlsAlignments.propTypes = {}

SptRichTextAreaContentControlsAlignments.defaultProps = {}
