import styled from 'styled-components'

import { SptBox } from '../Box'

export const SptHidden = styled(SptBox)`
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 0;
  width: 0;
  margin: 0;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  visibility: hidden;
`

SptHidden.displayName = 'SptHidden'

SptHidden.propTypes = {}

SptHidden.defaultProps = {}
