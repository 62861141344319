import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import { SptFlex } from '../../elements/Flex'

import { SptIcon } from '../../atoms/Icon'

import { SptSlider } from '../Slider'

import { SptStepperLineStyled } from './StepperItem'

export const SptStepperStyled = styled(SptFlex)`
  width: max-content;
  max-width: 100%;

  .slick-list {
    padding-bottom: 25px;

    .slick-slide {
      &:last-child {
        ${SptStepperLineStyled} {
          display: none;
        }
      }
    }
  }
`

SptStepperStyled.displayName = 'SptStepperStyled'

export const SptStepper = ({ children, arrows = true }) => {
  const iconNext = useMemo(
    () => (
      <SptIcon fontSize="xlarge" color="darkGrey">
        spt-caret-right
      </SptIcon>
    ),
    []
  )

  const iconPrev = useMemo(
    () => (
      <SptIcon fontSize="xlarge" color="darkGrey">
        spt-caret-left
      </SptIcon>
    ),
    []
  )

  return (
    <SptStepperStyled>
      <SptSlider swipeToSlide infinite={false} arrows={arrows} nextArrow={iconNext} prevArrow={iconPrev} flex={1}>
        {children}
      </SptSlider>
    </SptStepperStyled>
  )
}

SptStepper.displayName = 'SptStepper'

SptStepper.propTypes = {
  children: PropTypes.node.isRequired
}

SptStepper.defaultProps = {}
