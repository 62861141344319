import React, { memo } from 'react'

import { EditorState, RichUtils, Modifier } from 'draft-js'

import { useDisclosure } from '@sponte/lib-utils/dist/hooks/useDisclosure'
import { useOnClickOutside } from '@sponte/lib-utils/dist/hooks/useOnClickOutside'
import { usePopper } from '@sponte/lib-utils/dist/hooks/usePopper'

import { SptButton } from '../../atoms/Button'
import { SptCard } from '../../atoms/Card'
import { SptPortal } from '../../atoms/Portal'

import { ButtonWithToolTip } from './ComponentsStyleds'
import { TEXT_COLORS } from './decorators'
import { useRichTextContext } from './RichTextAreaContext'

export const SptRichTextAreaContentControlsTextColors = memo(() => {
  const { handleEditorState, editorState, focusEditor } = useRichTextContext()
  const { styles, popper, referrence, popperNode, referrenceNode } = usePopper()
  const { isOpen, onToggle, onClose } = useDisclosure({
    reducer: (_, { type }, changes) => {
      if (type === useDisclosure.types.close) {
        setTimeout(() => focusEditor(), 300)
      }

      return changes
    }
  })

  useOnClickOutside(
    [referrenceNode, popperNode],
    () => {
      if (isOpen) {
        onClose()
      }
    },
    [referrenceNode, popperNode]
  )

  const handleToggleTextColor = (toggledColor) => (e) => {
    e.preventDefault()

    const selection = editorState.getSelection()

    const nextContentState = TEXT_COLORS.reduce((contentState, color) => {
      return Modifier.removeInlineStyle(contentState, selection, color)
    }, editorState.getCurrentContent())

    let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style')

    const currentStyle = editorState.getCurrentInlineStyle()

    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color)
      }, nextEditorState)
    }

    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor)
    }

    handleEditorState(nextEditorState)

    onClose()
  }

  const currentInlineStyle = editorState.getCurrentInlineStyle()

  return (
    <>
      <ButtonWithToolTip
        tipText="Adicionar cores"
        icon="spt-text-format"
        variant="text"
        palette={TEXT_COLORS.find((textColor) => currentInlineStyle.has(textColor)) || 'neutral'}
        ref={referrence}
        onClick={() => onToggle()}
      />

      {isOpen && (
        <SptPortal>
          <SptCard ref={popper} style={styles} width={240} zIndex={100} p={8}>
            {TEXT_COLORS.map((textColor) => (
              <SptButton
                key={textColor}
                icon="spt-fiber-manual-record"
                palette={textColor}
                variant={currentInlineStyle.has(textColor) ? 'outlined' : 'text'}
                onMouseDown={handleToggleTextColor(textColor)}
              />
            ))}
          </SptCard>
        </SptPortal>
      )}
    </>
  )
})

SptRichTextAreaContentControlsTextColors.displayName = 'SptRichTextAreaContentControlsTextColors'

SptRichTextAreaContentControlsTextColors.propTypes = {}

SptRichTextAreaContentControlsTextColors.defaultProps = {}
