import React, { memo, useMemo, useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'

import { RemoveScroll } from 'react-remove-scroll'

import { useCombobox } from 'downshift'

import { stopPropagationAndPreventDefault } from '@sponte/lib-utils/dist/helpers/events'
import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { usePopper } from '@sponte/lib-utils/dist/hooks/usePopper'
import { useThrottle } from '@sponte/lib-utils/dist/hooks/useThrottle'

import { SptBox } from '../../elements/Box'

import { SptIcon } from '../../atoms/Icon'
import { SptMenuItem } from '../../atoms/MenuItem'
import { SptMenuList } from '../../atoms/MenuList'
import { SptScrollbar } from '../../atoms/Scrollbar'

import { SptField } from '../Field'
import { stateReducerFieldSelect } from '../FieldSelect'

const defaultItemToString = (item) => (typeof item === 'string' ? item : getSafe(item, 'nome', '')) || ''

export const SptFieldAutocomplete = memo(
  ({
    items: defaultItems = [],
    itemIsSelectedKey = 'id',
    itemToString = defaultItemToString,
    groupToString = defaultItemToString,
    getGroupItems = null,
    onChange = () => {},
    onInputChange = null,
    inputChangeTimeout = 300,
    value = null,
    searchIcon = false,
    displayTextHelp = true,
    disabled = false,
    inputRef,
    ...props
  }) => {
    const { referrence, popper, styles } = usePopper({
      placement: 'bottom-start'
    })

    const itemIsSelected = useCallback(
      (item, selectedItem) =>
        getSafe(item, itemIsSelectedKey, item) === getSafe(selectedItem, itemIsSelectedKey, selectedItem),
      [itemIsSelectedKey]
    )

    const id = useMemo(() => {
      return props.id || props.name
    }, [props.id, props.name])

    const items = useMemo(() => {
      if (getGroupItems) {
        return defaultItems.reduce((acc, group) => {
          return [...acc, ...getGroupItems(group)]
        }, [])
      }

      return defaultItems
    }, [defaultItems, getGroupItems])

    const handleInputValueChange = useThrottle((newInputValue) => {
      if (onInputChange) {
        onInputChange(newInputValue)
      }

      onChange({
        target: {
          id,
          type: 'text',
          name: props.name,
          value: newInputValue
        }
      })
    }, inputChangeTimeout)

    const {
      isOpen,
      inputValue,
      selectedItem,
      highlightedIndex,
      getComboboxProps,
      getInputProps,
      getMenuProps,
      getItemProps,
      selectItem
    } = useCombobox({
      id,
      items,
      itemToString,
      stateReducer: stateReducerFieldSelect,
      initialSelectedItem: value,
      defaultHighlightedIndex: -1,
      onInputValueChange: ({ inputValue: newInputValue }) => handleInputValueChange((newInputValue || '').trim()),
      onSelectedItemChange: ({ selectedItem: newSelectedItem }) =>
        onChange({
          target: {
            id,
            type: 'text',
            name: props.name,
            value: newSelectedItem
          }
        })
    })

    useEffect(() => {
      selectItem(value)
    }, [value])

    const iconLeft = useMemo(() => {
      if (searchIcon) {
        return <SptIcon data-testid="searchIcon">spt-search</SptIcon>
      }

      return null
    }, [searchIcon])

    const handleKeyDown = useCallback(
      (e) => {
        if (e.key === 'Escape' && (isOpen || value)) {
          stopPropagationAndPreventDefault(e)
        }
      },
      [value, isOpen]
    )

    const itemsToRender = useMemo(() => {
      let list = []

      if (getGroupItems) {
        list = defaultItems.reduce(
          (result, group, index) => {
            const groupItems = getGroupItems(group)

            if (groupItems.length === 0) {
              return result
            }

            let newIndex = result.index

            const newItems = [
              ...result.items,
              <SptMenuItem key={index} variant="header">
                {groupToString(group)}
              </SptMenuItem>,
              ...groupItems.map((item, itemIdx) => {
                newIndex += 1

                return (
                  <SptMenuItem
                    key={`${index}-${itemIdx}`}
                    icon={item.icon}
                    iconColor={item.iconColor}
                    active={newIndex === highlightedIndex}
                    selected={itemIsSelected(item, selectedItem)}
                    {...getItemProps({
                      item,
                      index: newIndex
                    })}
                  >
                    {itemToString(item)}
                  </SptMenuItem>
                )
              })
            ]

            return {
              items: newItems,
              index: newIndex
            }
          },
          { items: [], index: -1 }
        ).items
      } else {
        list = items.map((item, index) => {
          return (
            <SptMenuItem
              key={index}
              icon={item.icon}
              iconColor={item.iconColor}
              active={index === highlightedIndex}
              selected={itemIsSelected(item, selectedItem)}
              {...getItemProps({
                item,
                index
              })}
            >
              {itemToString(item)}
            </SptMenuItem>
          )
        })
      }

      if (list.length > 0) {
        return list
      }

      return null
    }, [getItemProps, itemToString, items, highlightedIndex, selectedItem, itemIsSelected, inputValue])

    return (
      <SptBox width={1} position="relative" {...getComboboxProps()}>
        <SptBox width={1} ref={referrence}>
          <SptField
            {...getInputProps({
              ...props,
              readOnly: props.readOnly,
              ref: inputRef,
              refKey: 'inputRef',
              type: 'text',
              disabled,
              suffix: <>{props.suffix}</>,
              prefix: iconLeft || props.prefix,
              onKeyDown: handleKeyDown,
              displayTextHelp
            })}
          />
        </SptBox>

        {isOpen && items.length ? (
          <RemoveScroll>
            <SptMenuList
              zIndex={10}
              {...getMenuProps({
                ref: popper,
                style: {
                  ...styles,
                  top: styles.top - (props.size === 'sm' || !displayTextHelp ? 0 : 18)
                }
              })}
            >
              <SptScrollbar maxHeight={200}>{itemsToRender}</SptScrollbar>
            </SptMenuList>
          </RemoveScroll>
        ) : null}
      </SptBox>
    )
  }
)

SptFieldAutocomplete.stateChangeTypes = useCombobox.stateChangeTypes

SptFieldAutocomplete.displayName = 'SptFieldAutocomplete'

SptFieldAutocomplete.propTypes = {
  items: PropTypes.array.isRequired,
  itemToString: PropTypes.func,
  groupToString: PropTypes.func,
  getGroupItems: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  value: PropTypes.any,
  searchIcon: PropTypes.bool,
  displayTextHelp: PropTypes.bool,
  dropDownIcon: PropTypes.bool
}

SptFieldAutocomplete.defaultProps = {}
