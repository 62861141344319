import { useRef, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

export const usePrompt = (when, message) => {
  const history = useHistory()

  const ref = useRef(null)

  useEffect(() => {
    if (when) {
      ref.current = history.block(message)
    } else {
      ref.current = null
    }

    const onWindowOrTabClose = (event) => {
      if (!when) {
        return false
      }

      if (typeof event === 'undefined') {
        // eslint-disable-next-line no-param-reassign
        event = window.event
      }

      if (event) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = message
      }

      return message
    }

    window.addEventListener('beforeunload', onWindowOrTabClose)

    return () => {
      if (ref.current) {
        ref.current()
        ref.current = null
      }

      window.removeEventListener('beforeunload', onWindowOrTabClose)
    }
  }, [when, message])
}
