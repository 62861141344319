import React, { memo, useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { switchProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'
import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptIcon } from '../Icon'

export const SptRadioSizes = {
  sm: css`
    height: 24px;
    width: 24px;
  `,

  md: css`
    height: 48px;
    width: 48px;
  `,

  lg: css`
    height: 96px;
    width: 96px;
  `
}

const SptRadioContainer = styled(SptFlex)`
  cursor: pointer;

  svg {
    cursor: pointer;
  }
`

SptRadioContainer.displayName = 'SptRadioContainer'

const SptRadioStyled = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

SptRadioStyled.displayName = 'SptRadioStyled'

const SptRadioIconStyled = styled(SptIcon)`
  ${switchProp('size', SptRadioSizes)};
`

SptRadioIconStyled.displayName = 'SptRadioIconStyled'

export const SptRadio = memo(
  ({
    colored,
    checked,
    radioActive,
    radioInactive,
    label,
    onClick,
    icon,
    iconChecked,
    disabled,
    name,
    value,
    textHelp,
    ...props
  }) => {
    const handleClick = useCallback(() => {
      if (!disabled && onClick) {
        onClick(value)
      }
    }, [value, disabled, onClick])

    const textColor = useMemo(() => {
      if (disabled) {
        return 'mediumGrey'
      }

      if (!colored) {
        return 'almostBlack'
      }

      return checked ? 'primary' : 'mediumGrey'
    }, [disabled, colored, checked])

    const iconColor = useMemo(() => {
      if (disabled) {
        return 'mediumGrey'
      }

      return checked ? 'primary' : 'darkGrey'
    }, [disabled, checked])

    const radioIcon = checked ? radioActive || iconChecked : radioInactive || icon

    return (
      <SptRadioContainer alignItems="center" onClick={handleClick} disabled={disabled} {...props}>
        <SptRadioStyled
          data-testid="radio"
          type="radio"
          disabled={disabled}
          name={name}
          value={value}
          defaultChecked={checked}
          onClick={handleClick}
        />

        <SptRadioIconStyled color={iconColor} {...props}>
          {radioIcon}
        </SptRadioIconStyled>

        {label && (
          <SptBox>
            <SptBox width={1}>
              <SptText ml={6} color={textColor}>
                {label}
              </SptText>
            </SptBox>

            {textHelp && (
              <SptBox width={1}>
                <SptText ml={6} color={textColor} fontSize="small">
                  {textHelp}
                </SptText>
              </SptBox>
            )}
          </SptBox>
        )}
      </SptRadioContainer>
    )
  }
)

SptRadio.displayName = 'SptRadio'

SptRadio.propTypes = {
  colored: PropTypes.bool,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  radioActive: PropTypes.string,
  radioInactive: PropTypes.string,
  textHelp: PropTypes.string
}

SptRadio.defaultProps = {
  icon: 'spt-radio-button-off',
  iconChecked: 'spt-radio-button-on'
}
