import React, { memo } from 'react'

import { SptText } from '../../../elements'

export const SptFieldTextHelpStyled = memo(({ id, children, variant }) => (
  <SptText color={variant} px={10} id={id} data-testid={id}>
    {children || <>&nbsp;</>}
  </SptText>
))

SptFieldTextHelpStyled.displayName = 'SptFieldTextHelpStyled'
