import React, { useRef, useState } from 'react'

import styled from 'styled-components'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

// import Tags from './Tags'
import TextareaAutosize from './TextArea'

const Textarea = styled(TextareaAutosize)`
  font-family: ${theme('fonts.default')} !important;
  resize: none;
  width: 100%;
`

export default ({
  drawerTagsProps = {},
  render = () => {},
  onShowEditOptions = () => {},
  onChange: onChangeProp,
  onToggleReadOnly,
  value: valueProp
}) => {
  const timeout = useRef()

  const [showEditOptions, setShowEditOptions] = useState(false)
  const [value, setValue] = useState(valueProp)

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onFocus = () => {
    onToggleReadOnly(true)
    onShowEditOptions(true)

    clearTimeout(timeout.current)

    setShowEditOptions(true)
  }

  const onBlur = () => {
    onShowEditOptions(false)

    timeout.current = setTimeout(() => {
      setShowEditOptions(false)
    }, 200)

    onChangeProp(value)
  }

  return (
    <>
      <span>
        <Textarea
          useCacheForDOMMeasurements
          type="text"
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
        />
        {render({
          showEditOptions,
          value
        })}
      </span>

      {/* {drawerTagsProps.isOpen && (
        <Tags size="sm" icon="spt-add-tag" {...drawerTagsProps} value={value} onChange={onChange} />
      )} */}
    </>
  )
}
