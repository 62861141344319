import styled from 'styled-components'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

export const StdDay = styled.button`
  width: 2.75rem;
  height: 2.313rem;
  background: white;
  border-radius: 50px;
  border: none;
  outline: none;

  font-size: 14px;
  font-family: ${theme('fonts.default')};
  font-weight: 400;
`
