import React, { memo, useRef, useMemo, useState, useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { callAllEventHandlers } from '@sponte/lib-utils/dist/helpers/callAll'
import { generateId } from '@sponte/lib-utils/dist/helpers/generateId'

import { useForkRef } from '@sponte/lib-utils/dist/hooks/useForkRef'

import { theme, palette, ifProp, switchProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'
import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptInput } from '../../atoms/Input'
import { SptTextarea } from '../../atoms/Textarea'

export const SptFieldSizes = {
  sm: css`
    height: 32px;
  `,

  md: css`
    height: 40px;
  `,

  lg: css`
    height: 48px;
  `
}

export const SptFieldVariants = {
  info: css`
    border-color: ${palette('info')};

    + span {
      color: ${palette('info')};
    }
  `,

  success: css`
    border-color: ${palette('success')};

    + span {
      color: ${palette('success')};
    }
  `,

  warning: css`
    border-color: ${palette('warning')};

    + span {
      color: ${palette('warning')};
    }
  `,

  error: css`
    border-color: ${palette('error')};

    + span {
      color: ${palette('error')};
    }
  `
}

export const SptBoxBorder = styled(SptBox)`
  border: 1px solid ${palette('mediumGrey')};
  border-radius: 3px;
  padding: ${theme('space.1')}px ${theme('space.3')}px;
  margin: ${theme('space.2')}px;
`

const HeaderStyled = styled(SptFlex)`
  background-color: #f2f4f8;
  margin: ${theme('space.2')}px;
`

export const SptHeaderSection = memo(({ title, height = '25px', alignItems = 'center', flex = 1, ...props }) => {
  return (
    <HeaderStyled height={height} alignItems={alignItems} flex={flex} {...props}>
      <SptText ml={5} fontSize={10} fontWeight="bold" color="almostBlack">
        {title}
      </SptText>
    </HeaderStyled>
  )
})

SptHeaderSection.displayName = 'HeaderSection'

SptHeaderSection.propType = {
  title: PropTypes.string.isRequired
}

SptHeaderSection.defaultProps = {
  flex: 1,
  height: '25px',
  alignItems: 'center'
}

const SptFieldStyled = styled(SptFlex)`
  height: 18px !important;
  background-image: url('${theme('assets.borda')}');
  background-size: 18px;
  background-color: transparent;
  background-repeat: repeat-x;
  font-size: 14px;
  line-height: 15px;
  padding-left: 6px;
  padding-bottom: 2px;
  svg {
    z-index: 1;
  };

  ${ifProp(
    'disabled',
    css`
      background-color: ${theme('colors.lightGrey')};
      border-color: ${theme('colors.darkGrey')};
    `
  )};

  ${switchProp('size', SptFieldSizes)};

  ${switchProp('variant', SptFieldVariants)};
`

const SptInputStyled = styled(SptInput)`
  height: 18px !important;
  background-color: transparent;
  ${ifProp(
    'border',
    css`
      border-bottom: 1px solid #c6ccd7;
    `
  )};
`

SptFieldStyled.displayName = 'SptFieldStyled'

const SptFieldPrefixStyled = memo(({ children }) => {
  return (
    <SptFlex mr={3} alignItems="center" justifyContent="flex-end">
      <SptText fontWeight="bold" fontSize="small">
        {children}
      </SptText>
    </SptFlex>
  )
})

SptFieldPrefixStyled.displayName = 'SptFieldPrefixStyled'

export const SptLabelFieldLine = memo(({ id, htmlFor, required, children }) => (
  <SptText as="label" variant="label" id={id} htmlFor={htmlFor}>
    {children}
    {required && (
      <SptText fontSize="small" color="error" pl={1}>
        *
      </SptText>
    )}
  </SptText>
))

SptLabelFieldLine.displayName = 'SptLabelFieldLine'

export const SptFieldLine = memo(
  ({
    id,
    name,
    suffix,
    prefix,
    label,
    textHelp,
    required,
    size,
    variant,
    palette: colorPalette,
    disabled,
    loading,
    value,
    onClick,
    displayTextHelp,
    onBlur,
    onFocus,
    textarea,
    children,
    inputRef: inputRefProp,
    boxRef,
    maxLength,
    hasValue: hasValueProp,
    ...props
  }) => {
    const inputRef = useRef()

    const handleInputRef = useForkRef(inputRef, inputRefProp)

    const [focused, setFocus] = useState(false)

    const setInputFocus = useCallback(() => {
      if (inputRef.current && !disabled) {
        inputRef.current.focus()
      }
    }, [disabled])

    const [hasValue, setHasValue] = useState(hasValueProp || (inputRef.current && !!inputRef.current.value))

    useEffect(() => {
      setHasValue(hasValueProp || !!hasValue || (inputRef.current && !!inputRef.current.value))
    }, [value, hasValueProp, focused, inputRef.current])

    const { inputId, labelId, describeId } = useMemo(() => {
      const baseInputId = id || name || generateId()

      return {
        inputId: baseInputId,
        labelId: `${baseInputId}-label`,
        describeId: `${baseInputId}-describe`
      }
    }, [id, name])

    const inputProps = useMemo(
      () => ({
        'data-testid': inputId,
        name,
        value,
        disabled,
        ref: handleInputRef,
        onBlur: callAllEventHandlers(onBlur, () => setFocus(false)),
        onFocus: callAllEventHandlers(onFocus, () => setFocus(true)),
        'aria-labelledby': labelId,
        'aria-describedby': describeId,
        ...props
      }),
      [inputId, value, disabled, handleInputRef, onBlur, onFocus, labelId, describeId, props]
    )

    const prefixComponent = useMemo(() => {
      if (prefix) {
        return <SptFieldPrefixStyled>{prefix}</SptFieldPrefixStyled>
      }

      return prefix
    }, [prefix])

    const labelComponent = useMemo(() => {
      if (label) {
        return (
          <SptFlex flex={1} mb={2}>
            <SptLabelFieldLine id={labelId} htmlFor={inputId} required={required}>
              {label}
            </SptLabelFieldLine>
          </SptFlex>
        )
      }

      return label
    }, [inputId, labelId, label, required])

    const inputComponent = !textarea ? <SptInputStyled {...inputProps} /> : <SptTextarea {...inputProps} />

    return (
      <SptFlex flex={1} p={2} onClick={setInputFocus} flexDirection="column">
        {labelComponent}
        <SptFlex flex={1} alignItems="flex-end">
          {prefixComponent}
          {inputComponent}
        </SptFlex>
      </SptFlex>
    )
  }
)

SptFieldLine.displayName = 'SptFieldLine'

SptFieldLine.propTypes = {
  size: PropTypes.oneOf(Object.keys(SptFieldSizes)),
  variant: PropTypes.oneOf(['', ...Object.keys(SptFieldVariants)]),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  textHelp: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  palette: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'neutral']).isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  displayTextHelp: PropTypes.bool,
  maxLength: PropTypes.number,
  hasValue: PropTypes.bool
}

SptFieldLine.defaultProps = {
  size: 'lg',
  palette: 'primary',
  loading: false,
  disabled: false,
  required: false,
  displayTextHelp: true,
  hasValue: false
}
