import React from 'react'

import PropTypes from 'prop-types'

import { SptCheckbox } from '../Checkbox'

export const SptCheckboxSelectAll = (props) => {
  const { checked } = props

  // TODO: adiciona internacionalização
  const label = checked ? 'Selecionar Todos' : 'Desfazer Seleção'

  return (
    <SptCheckbox colored checkboxActive="spt-select-all" checkboxInactive="spt-select-all" label={label} {...props} />
  )
}

SptCheckboxSelectAll.displayName = 'SptCheckboxSelectAll'

SptCheckboxSelectAll.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool
}

SptCheckboxSelectAll.defaultProps = {}
