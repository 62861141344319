import React, { memo, useContext } from 'react'

import { useMedia } from '@sponte/lib-utils/dist/hooks/useMedia'
import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { SptBox } from '@sponte/lib-components/dist/elements/Box/Box'

import { SptStepper } from '@sponte/lib-components/dist/molecules/Stepper/Stepper'
import { SptStepperItem } from '@sponte/lib-components/dist/molecules/Stepper/StepperItem'

import { CadastroTesteGratisContext } from './CadastroTesteGratisContext'

export const CadastroTesteGratisStepper = memo(({ finishedStep }) => {
  const theme = useTheme()
  const isSmall = useMedia(theme.mediaQueries.down.tablet, true, false)

  const { step } = useContext(CadastroTesteGratisContext)

  return (
    <SptBox maxWidth={isSmall && !finishedStep ? '50%' : '100%'}>
      <SptStepper arrows={false}>
        <SptStepperItem active={step >= 0} completedStep={step > 0} isSmall={isSmall}>
          1
        </SptStepperItem>
        <SptStepperItem active={step >= 1} completedStep={step > 1} isSmall={isSmall}>
          2
        </SptStepperItem>
        <SptStepperItem active={step >= 2} completedStep={step > 2} isSmall={isSmall}>
          3
        </SptStepperItem>
      </SptStepper>
    </SptBox>
  )
})
