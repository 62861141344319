import decorateComponentWithProps from 'decorate-component-with-props'

import * as types from './table/constants'

import DefaultTableComponent from './table/components/Table'

import { editColumn, editCell, removeRow, addRow, addColumn, removeColumn } from './table/modifiers/editTable'

export default ({ tableComponent, onToggleReadOnly, decorator, render } = {}) => {
  let Table = tableComponent || DefaultTableComponent

  if (decorator) {
    Table = decorator(Table)
  }

  let ThemedTable = decorateComponentWithProps(Table, {
    onToggleReadOnly
  })

  if (render) {
    ThemedTable = () => render
  }

  return {
    blockRendererFn: (block, { getEditorState, setEditorState }) => {
      if (block.getType() === types.ATOMIC) {
        try {
          // TODO subject to change for draft-js next release
          const contentState = getEditorState().getCurrentContent()
          const entity = contentState.getEntity(block.getEntityAt(0))
          if (!entity) {
            return null
          }
          const type = entity.getType()
          const { columns, rows } = entity.getData()
          if (type === types.TABLETYPE) {
            return {
              component: ThemedTable,
              editable: true,
              props: {
                columns,
                rows,
                getEditorState,
                setEditorState,
                editColumn,
                editCell,
                addColumn,
                removeColumn,
                removeRow,
                addRow
              }
            }
          }
        } catch (e) {
          return null
        }
      }

      return null
    },
    types
  }
}
