import { useRef, useState, useLayoutEffect, useImperativeHandle, forwardRef } from 'react'

import { findDOMNode, createPortal } from 'react-dom'

function getContainer(container) {
  return findDOMNode(typeof container === 'function' ? container() : container)
}

export const SptPortal = forwardRef(({ children, container }, ref) => {
  const [mountNode, setMountNode] = useState(null)
  const childRef = useRef(null)

  useLayoutEffect(() => {
    setMountNode(getContainer(container) || document.body)
  }, [container])

  useImperativeHandle(ref, () => mountNode || childRef.current, [mountNode])

  return mountNode ? createPortal(children, mountNode) : mountNode
})

SptPortal.displayName = 'SptPortal'

SptPortal.propTypes = {}

SptPortal.defaultProps = {}
