export function stopPropagationAndPreventDefault(e) {
  if (e) {
    e.preventDefault()
    e.stopPropagation()
  }
}

export const eventPersist = (event) => event.persist()

export const eventPreventDefault = (event) => event.preventDefault()

export const eventStopPropagation = (event) => event.stopPropagation()
