import React from 'react'

export default () => (
  <svg
    style={{
      width: 20,
      fill: 'gray',
      enableBackground: 'new 0 0 191.146 191.146'
    }}
    version="1.1"
    id="Capa_1"
    x={0}
    y={0}
    viewBox="0 0 191.146 191.146"
  >
    <g>
      <g>
        <path
          d="M187.733,0h-61.44c-1.884,0-3.413,1.527-3.413,3.413v92.16c0,1.886,1.529,3.413,3.413,3.413h61.44
			c1.884,0,3.413-1.527,3.413-3.413V3.413C191.147,1.527,189.618,0,187.733,0z M184.32,92.16h-54.613V6.827h54.613V92.16z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M187.733,92.16h-61.44c-1.884,0-3.413,1.527-3.413,3.413v92.16c0,1.886,1.529,3.413,3.413,3.413h61.44
			c1.884,0,3.413-1.528,3.413-3.413v-92.16C191.147,93.687,189.618,92.16,187.733,92.16z M184.32,184.32h-54.613V98.987h54.613
			V184.32z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M126.293,0h-61.44c-1.884,0-3.413,1.527-3.413,3.413v92.16c0,1.886,1.529,3.413,3.413,3.413h61.44
			c1.884,0,3.413-1.527,3.413-3.413V3.413C129.707,1.527,128.178,0,126.293,0z M122.88,92.16H68.267V6.827h54.613V92.16z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M126.293,92.16h-61.44c-1.884,0-3.413,1.527-3.413,3.413v92.16c0,1.886,1.529,3.413,3.413,3.413h61.44
			c1.884,0,3.413-1.528,3.413-3.413v-92.16C129.707,93.687,128.178,92.16,126.293,92.16z M122.88,184.32H68.267V98.987h54.613
			V184.32z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M64.853,0H3.413C1.529,0,0,1.527,0,3.413v92.16c0,1.886,1.529,3.413,3.413,3.413h61.44c1.884,0,3.413-1.527,3.413-3.413
			V3.413C68.267,1.527,66.738,0,64.853,0z M61.44,92.16H6.827V6.827H61.44V92.16z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M64.853,92.16H3.413C1.529,92.16,0,93.687,0,95.573v92.16c0,1.886,1.529,3.413,3.413,3.413h61.44
			c1.884,0,3.413-1.528,3.413-3.413v-92.16C68.267,93.687,66.738,92.16,64.853,92.16z M61.44,184.32H6.827V98.987H61.44V184.32z"
        />
      </g>
    </g>
  </svg>
)
