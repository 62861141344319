import React, { useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import { usePopper } from '@sponte/lib-utils/dist/hooks/usePopper'

import { SptBox } from '../../elements/Box'
import { SptText } from '../../elements/Text'

export const SptTooltip = ({ children, title, customTitle, borderRadius, placement = 'bottom-start', ...props }) => {
  const [active, setActive] = useState(false)

  const { referrence, popper, styles } = usePopper({ placement })

  const setActiveTrue = useCallback(() => setActive(true), [])
  const setActiveFalse = useCallback(() => setActive(false), [])

  return (
    <>
      <SptBox
        ref={referrence}
        onMouseEnter={setActiveTrue}
        onMouseLeave={setActiveFalse}
        onPointerLeave={setActiveFalse}
        onMouseOver={setActiveTrue}
        onFocus
      >
        {children}
      </SptBox>

      {active && (
        <SptBox
          ref={popper}
          styles={styles}
          px={10}
          py={3}
          borderRadius={borderRadius || 'md'}
          bg="almostBlack"
          position="absolute"
          {...props}
        >
          {customTitle || <SptText as="div" color="white" dangerouslySetInnerHTML={{ __html: title }} />}
        </SptBox>
      )}
    </>
  )
}

SptTooltip.displayName = 'SptTooltip'

SptTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
}

SptTooltip.defaultProps = {
  maxWidth: '300px'
}
