import { useCallback, useState } from 'react'

export const useStepper = (initialStep = 0, intervalStep = 1) => {
  const [step, setStepper] = useState(initialStep)

  const handleNextStep = useCallback(() => {
    setStepper((oldStep) => oldStep + intervalStep)
  }, [setStepper, intervalStep])

  const handlePreviousStep = useCallback(() => {
    setStepper((oldStep) => oldStep - intervalStep)
  }, [setStepper, intervalStep])

  const resetStep = useCallback(() => {
    setStepper(initialStep)
  }, [setStepper, initialStep])

  return [step, handlePreviousStep, handleNextStep, resetStep]
}
