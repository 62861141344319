import React, { useRef, useMemo } from 'react'

import useInputMask from 'use-input-mask'

import { INPUT_CPF_MASK, maskCpf } from '@sponte/lib-utils/dist/helpers/masks'

import { SptField } from '../Field'

export const SptFieldCpf = ({ value, onChange, ...props }) => {
  const inputRef = useRef(null)

  const handleChange = useInputMask({
    input: inputRef,
    onChange,
    mask: INPUT_CPF_MASK,
    guide: false,
    initialValue: maskCpf(value)
  })

  const fieldProps = useMemo(
    () => ({
      ...props,
      type: 'text',
      value: maskCpf(value),
      onChange: handleChange
    }),
    [props, handleChange]
  )

  return <SptField inputRef={inputRef} {...fieldProps} />
}

SptFieldCpf.propTypes = {}

SptFieldCpf.defaultProps = {}
