import React, { useMemo, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptMenuItem } from '../../atoms/MenuItem'
import { SptMenuList } from '../../atoms/MenuList'
import { SptProgressBar } from '../../atoms/ProgressBar'

const ProgressBarStyled = styled.div`
  div {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
`

const MenuListStyled = styled(SptMenuList)`
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  cursor: default;
`

const MenuItemStyled = styled(SptMenuItem)`
  svg {
    color: ${(props) => theme(`colors.${props.colorIcon}`)} !important;
    cursor: default;
  }
  white-space: pre-wrap;
  cursor: default;
`

const initialState = {
  progress: 100,
  validations: {
    hasMinValuePassword: true,
    hasLetterPassword: true,
    hasCapitalLetterPassword: true,
    hasNumberPassword: true,
    hasSpecialCharacter: true
  }
}

const validationSchema = Yup.object().shape({
  hasMinValuePassword: Yup.string().min(8),
  hasLetterPassword: Yup.string().matches(/[a-z]/),
  hasCapitalLetterPassword: Yup.string().matches(/[A-Z]/),
  hasNumberPassword: Yup.string().matches(/\d+/),
  hasSpecialCharacter: Yup.string().matches(/[!@#$%^&*(),.?":{}|<>]/)
})

export const SptPasswordStrength = ({ value }) => {
  const { t } = useTranslation()

  const messages = useMemo(() => {
    return {
      hasMinValuePassword: t('geral:validacoes.minimoCaracteres'),
      hasLetterPassword: t('geral:validacoes.conterLetras'),
      hasCapitalLetterPassword: t('geral:validacoes.conterLetraMaiuscula'),
      hasNumberPassword: t('geral:validacoes.conterNumeros'),
      hasSpecialCharacter: t('geral:validacoes.conterCaractereEspecial')
    }
  }, [t])

  const [state, setState] = useState({
    ...initialState,
    isFirstLoad: true
  })

  useEffect(
    () => {
      let isSubscribed = true

      validationSchema
        .validate(
          {
            hasMinValuePassword: value,
            hasLetterPassword: value,
            hasCapitalLetterPassword: value,
            hasNumberPassword: value,
            hasSpecialCharacter: value
          },
          { abortEarly: false }
        )
        .then(() => {
          if (isSubscribed) {
            setState(initialState)
          }
        })
        .catch((err) => {
          if (isSubscribed) {
            setState(() => {
              const errorsLength = err.inner.length
              const validationsLength = Object.keys(state.validations).length
              const progress = ((validationsLength - errorsLength) * 100) / validationsLength

              const validations = { ...initialState.validations }

              err.inner.forEach((e) => {
                validations[e.path] = false
              })

              return {
                progress,
                validations
              }
            })
          }
        })

      return () => {
        isSubscribed = false
      }
    },
    // eslint-disable-next-line
    [value]
  )

  const bg = useMemo(() => {
    return state.progress === 100 ? 'success' : 'error'
  }, [state.progress])

  if (state.isFirstLoad) {
    return null
  }

  return (
    <>
      <ProgressBarStyled>
        <SptProgressBar size="sm" percent={state.progress} bg={bg} />
      </ProgressBarStyled>

      <MenuListStyled>
        {state.validations && (
          <>
            <MenuItemStyled variant="normal">
              {t('geral:validacoes.senhaDeveConter')}
              <>:</>
            </MenuItemStyled>

            {Object.keys(state.validations).map((validation) => {
              const isValid = state.validations[validation]

              return (
                <MenuItemStyled
                  variant="normal"
                  key={validation}
                  icon={isValid ? 'spt-check-circle' : 'spt-info'}
                  colorIcon={isValid ? 'success' : 'error'}
                >
                  {messages[validation]}
                </MenuItemStyled>
              )
            })}
          </>
        )}
      </MenuListStyled>
    </>
  )
}

SptPasswordStrength.displayName = 'SptPasswordStrength'

SptPasswordStrength.propTypes = {
  value: PropTypes.string.isRequired
}

SptPasswordStrength.defaultProps = {}
