import React, { memo } from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

export const SptAvatarImageStyled = styled.img`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
`
SptAvatarImageStyled.displayName = 'SptAvatarImageStyled'

export const SptAvatarImage = memo(({ alt, src }) => {
  return <SptAvatarImageStyled alt={alt} src={src} />
})

SptAvatarImage.displayName = 'SptAvatarImage'

SptAvatarImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired
}
