import React from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { ifProp, theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'

import { SptListItemStyled } from '../ListItem'

const SptListStyled = styled(SptBox)`
  ${ifProp(
    'disabled',
    css`
      background-color: ${theme('colors.lightGrey')};
    `
  )};

  > ${SptListItemStyled} {
    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:not(:last-child) {
      border-bottom-width: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`

SptListStyled.displayName = 'SptListStyled'

export const SptList = ({ children, ...props }) => {
  return (
    <SptListStyled width={1} borderRadius="sm" {...props}>
      {children}
    </SptListStyled>
  )
}

SptList.displayName = 'SptList'

SptList.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node
}

SptList.defaultProps = {}
