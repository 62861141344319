import React, { useRef, useMemo } from 'react'

import moment from 'moment'

import { useTranslation } from 'react-i18next'

import { createAutoCorrectedDatePipe } from 'text-mask-addons'
import useInputMask from 'use-input-mask'

import { INPUT_DATE_MASK, INPUT_DATE_YEAR_MASK } from '@sponte/lib-utils/dist/helpers/masks'

import { SptIcon } from '../../atoms/Icon'

import { SptFieldSimple } from './FieldSimple'

export const SptFieldSimpleDate = ({ type, value = '', onChange, yearOnly, ...props }) => {
  const { t } = useTranslation()

  const inputRef = useRef(null)

  const icon = useMemo(() => <SptIcon>spt-agenda</SptIcon>, [])

  const dateMask = useMemo(() => t('geral:date_mask'), [t])

  const internalValue = moment(value.slice(0, 10), 'YYYY-MM-DD', true).isValid()
    ? moment(value).format(dateMask)
    : value

  const autoCorrectedDatePipe = useMemo(() => {
    // TODO: revisar formatação na lib
    return createAutoCorrectedDatePipe('dd/mm/yyyy')
  }, [])

  const handleOnChange = useInputMask({
    input: inputRef,
    onChange: (e) => {
      if (!onChange || typeof onChange !== 'function') {
        return
      }

      if (moment(e.target.value, dateMask, true).isValid()) {
        onChange({
          ...e,
          target: {
            ...e.target,
            id: props.id,
            type: 'text',
            name: props.name,
            value: moment(e.target.value, dateMask).format()
          }
        })
      } else {
        onChange(e)
      }
    },
    // Máscara pra quando campo é apenas ano
    mask: yearOnly ? INPUT_DATE_YEAR_MASK : INPUT_DATE_MASK,
    guide: false,
    // Verificação para não deixar corrigir quando é campo de apenas ano
    pipe: !yearOnly ? autoCorrectedDatePipe : undefined,
    keepCharPositions: true,
    initialValue: internalValue
  })

  return <SptFieldSimple inputRef={inputRef} prefix={icon} type="text" onChange={handleOnChange} {...props} />
}

SptFieldSimpleDate.displayName = 'SptFieldSimpleDate'

SptFieldSimpleDate.propTypes = {}

SptFieldSimpleDate.defaultProps = {}
