import React, { memo, useContext, useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Field, useField } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'

import { useMedia } from '@sponte/lib-utils/dist/hooks/useMedia'
import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'
import { useThrottle } from '@sponte/lib-utils/dist/hooks/useThrottle'

import { SptText } from '@sponte/lib-components/dist/elements/Text/Text'

import { SptIcon } from '@sponte/lib-components/dist/atoms/Icon/Icon'

import { SptFieldFormik } from '@sponte/lib-components/dist/molecules/FieldFormik'

import { useQueryTenantsContaGratisControllerObterPorSubdominio } from 'api/retaguarda'

import { CadastroTesteGratisContext } from './CadastroTesteGratisContext'

export const CadastroTesteGratisFormikFormFieldSubdominio = memo(() => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmall = useMedia(theme.mediaQueries.down.tablet, true, false)

  const { step } = useContext(CadastroTesteGratisContext)

  const [{ value: subdominio }, { error: errorSubdominio }, { setError: setErrorSubdominio }] = useField('subdominio')

  const { refetch, isError, isLoading } = useQueryTenantsContaGratisControllerObterPorSubdominio(
    { subdominio },
    { enabled: false, onError: () => {}, useErrorBoundary: false, suspense: false }
  )

  const buscarSubdominio = useThrottle(refetch, 500)

  useEffect(() => {
    if (subdominio) {
      buscarSubdominio()
    }
    // eslint-disable-next-line
  }, [subdominio])

  useEffect(() => {
    if (subdominio && !isError && !isLoading) {
      setErrorSubdominio(t('validacoes:dominioRegistrado'))
    }
    // eslint-disable-next-line
  }, [isError, isLoading])

  const { color, path } = useMemo(() => {
    if (isError && subdominio && !isLoading) {
      return {
        color: 'success',
        path: 'spt-done'
      }
    }

    if (subdominio && !isLoading) {
      return {
        color: 'error',
        path: 'spt-close'
      }
    }

    return { color: 'mediumGrey', path: 'spt-link' }
  }, [subdominio, isLoading, isError])

  if (step !== 1) {
    return null
  }

  return (
    <AnimatePresence>
      <motion.div animate={{ y: isSmall ? 40 : 50 }} transition={{ duration: 1 }}>
        <Field
          name="subdominio"
          required
          variant={!!subdominio && errorSubdominio ? 'error' : null}
          component={SptFieldFormik}
          placeholder="nomedasuaclinica"
          textHelp={!!subdominio && errorSubdominio ? errorSubdominio : t('cadastro:naoUtilizeespacoesNemAcentos')}
          loading={isLoading}
          showLoadingWhenIsSubmitting
          prefix={
            <SptIcon
              border={color !== 'mediumGrey' ? `2px solid ${theme.colors[color]}` : null}
              borderRadius="full"
              color={color}
            >
              {path}
            </SptIcon>
          }
          suffix={
            <SptText color="primary" fontWeight="medium" backgroundColor="lightGrey">
              .sistemamedplus.com.br
            </SptText>
          }
        />
      </motion.div>
    </AnimatePresence>
  )
})
