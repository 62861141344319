import React, { memo, useMemo, useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { RemoveScroll } from 'react-remove-scroll'

import { useCombobox } from 'downshift'

import { stopPropagationAndPreventDefault } from '@sponte/lib-utils/dist/helpers/events'
import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { usePopper } from '@sponte/lib-utils/dist/hooks/usePopper'
import { useThrottle } from '@sponte/lib-utils/dist/hooks/useThrottle'

import { SptBox } from '../../elements/Box'
import { SptText } from '../../elements/Text'

import { SptButton } from '../../atoms/Button'
import { SptMenuItem } from '../../atoms/MenuItem'
import { SptMenuList } from '../../atoms/MenuList'
import { SptScrollbar } from '../../atoms/Scrollbar'

import { stateReducerFieldSelect } from '../FieldSelect'
import { SptFieldTableSimple } from '../FieldTableSimple'

const defaultItemToString = (item) => (typeof item === 'string' ? item : getSafe(item, 'nome', '')) || ''

export const SptFieldTableSelect = memo(
  ({
    items: defaultItems = [],
    itemIsSelectedKey = 'id',
    itemToString = defaultItemToString,
    groupToString = defaultItemToString,
    getGroupItems = null,
    onChange = () => {},
    onInputChange = null,
    inputChangeTimeout = 300,
    value = null,
    displayTextHelp = true,
    dropDownIcon = true,
    disabled = false,
    inputRef,
    textHelperEmptyItems,
    ...props
  }) => {
    const { referrence, popper } = usePopper({
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: ({ placement }) => {
              if (placement === 'bottom-start' && !(props.size === 'sm' || !displayTextHelp)) {
                return [0, 0]
              }
              return []
            }
          }
        }
      ]
    })

    const { t } = useTranslation()

    const itemIsSelected = useCallback(
      (item, selectedItem) =>
        getSafe(item, itemIsSelectedKey, item) === getSafe(selectedItem, itemIsSelectedKey, selectedItem),
      [itemIsSelectedKey]
    )

    const id = useMemo(() => {
      return props.id || props.name
    }, [props.id, props.name])

    const items = useMemo(() => {
      if (getGroupItems) {
        return defaultItems.reduce((acc, group) => {
          return [...acc, ...getGroupItems(group)]
        }, [])
      }

      return defaultItems
    }, [defaultItems, getGroupItems])

    const handleInputValueChange = useThrottle(onInputChange, inputChangeTimeout)

    const {
      isOpen,
      inputValue,
      selectedItem,
      highlightedIndex,
      getComboboxProps,
      getInputProps,
      getMenuProps,
      getItemProps,
      getToggleButtonProps,
      selectItem
    } = useCombobox({
      id,
      items,
      itemToString,
      stateReducer: stateReducerFieldSelect,
      initialSelectedItem: value,
      defaultHighlightedIndex: -1,
      onInputValueChange: ({ inputValue: newInputValue }) => {
        handleInputValueChange((newInputValue || '').trim())
      },
      onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
        onChange({
          target: {
            id,
            type: 'text',
            name: props.name,
            value: newSelectedItem
          }
        })
      }
    })

    useEffect(() => {
      selectItem(value)
    }, [value])

    const icon = useMemo(() => {
      if (dropDownIcon) {
        return (
          <SptButton
            icon="spt-chevron-down"
            size="sm"
            mr={5}
            variant="text"
            palette="neutral"
            data-testid={`${props.name}-dropDownIcon`}
            {...getToggleButtonProps({ disabled })}
          />
        )
      }

      return null
    }, [dropDownIcon, disabled, getToggleButtonProps])

    const handleKeyDown = useCallback(
      (e) => {
        if (e.key === 'Escape' && (isOpen || value)) {
          stopPropagationAndPreventDefault(e)
        }
      },
      [value, isOpen]
    )

    const itemsToRender = useMemo(() => {
      let list = []

      if (getGroupItems) {
        list = defaultItems.reduce(
          (result, group, index) => {
            const groupItems = getGroupItems(group)

            if (groupItems.length === 0) {
              return result
            }

            let newIndex = result.index

            const newItems = [
              ...result.items,
              <SptMenuItem key={index} variant="header">
                {groupToString(group)}
              </SptMenuItem>,
              ...groupItems.map((item, itemIdx) => {
                newIndex += 1

                return (
                  <SptMenuItem
                    key={`${index}-${itemIdx}`}
                    icon={item.icon}
                    iconColor={item.iconColor}
                    active={newIndex === highlightedIndex}
                    selected={itemIsSelected(item, selectedItem)}
                    {...getItemProps({
                      item,
                      index: newIndex
                    })}
                  >
                    {itemToString(item)}
                  </SptMenuItem>
                )
              })
            ]

            return {
              items: newItems,
              index: newIndex
            }
          },
          { items: [], index: -1 }
        ).items
      } else {
        list = items.map((item, index) => {
          return (
            <SptMenuItem
              key={index}
              icon={item.icon}
              iconColor={item.iconColor}
              active={index === highlightedIndex}
              selected={itemIsSelected(item, selectedItem)}
              {...getItemProps({
                item,
                index
              })}
            >
              {itemToString(item)}
            </SptMenuItem>
          )
        })
      }

      if (list.length > 0) {
        return list
      }

      return (
        <SptMenuItem variant="header" truncate>
          <SptText>{textHelperEmptyItems || t('geral:feedbacks.nenhumRegistro')}</SptText>
        </SptMenuItem>
      )
    }, [getItemProps, itemToString, items, highlightedIndex, selectedItem, itemIsSelected, inputValue])

    return (
      <SptBox width={1} position="relative" {...getComboboxProps()}>
        <SptBox width={1} ref={referrence}>
          <SptFieldTableSimple
            {...getInputProps({
              ...props,
              readOnly: true,
              ref: inputRef,
              refKey: 'inputRef',
              type: 'text',
              disabled,
              suffix: <>{icon || props.suffix}</>,
              prefix: props.prefix,
              onKeyDown: handleKeyDown,
              displayTextHelp
            })}
          />
        </SptBox>

        {isOpen && (
          <RemoveScroll>
            <SptMenuList
              zIndex={10}
              {...getMenuProps({
                ref: popper
              })}
            >
              <SptScrollbar maxHeight={200}>{itemsToRender}</SptScrollbar>
            </SptMenuList>
          </RemoveScroll>
        )}
      </SptBox>
    )
  }
)

SptFieldTableSelect.stateChangeTypes = useCombobox.stateChangeTypes

SptFieldTableSelect.displayName = 'SptFieldTableSelect'

SptFieldTableSelect.propTypes = {
  items: PropTypes.array.isRequired,
  itemToString: PropTypes.func,
  groupToString: PropTypes.func,
  getGroupItems: PropTypes.func,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onInputChange: PropTypes.func,
  value: PropTypes.any,
  searchIcon: PropTypes.bool,
  displayTextHelp: PropTypes.bool,
  dropDownIcon: PropTypes.bool,
  clearable: PropTypes.bool
}

SptFieldTableSelect.defaultProps = {}
