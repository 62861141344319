import { useCallback, useState } from 'react'

import { EditorState, convertToRaw, convertFromRaw, SelectionState } from 'draft-js'

import { createContainer } from '@sponte/lib-utils/dist/hooks/useContainer'

const useRichText = ({ value, onChange = () => {}, ...props }) => {
  const [editorState, setEditorState] = useState(() => {
    // Creating the content on state initialization so the EditorState will not be overridden by a new one
    // Therefore, all plugins will not have their behavior affected
    if (value) {
      try {
        if (typeof value === 'object') {
          return EditorState.createWithContent(convertFromRaw(value))
        }
        return EditorState.createWithContent(JSON.parse(convertFromRaw(value)))
      } catch (e) {
        return EditorState.createEmpty()
      }
    }
    return EditorState.createEmpty()
  })

  const [readOnly, setReadOnly] = useState(false)
  const [margins, setMargins] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [isSending, setSending] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [savedEditor, setSavedEditor] = useState({})

  const focusEditor = useCallback(() => {
    const content = editorState.getCurrentContent()
    const blockMap = content.getBlockMap()
    const key = blockMap.last().getKey()
    const length = blockMap.last().getLength()
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length
    })
    return EditorState.acceptSelection(editorState, selection)
  }, [editorState])

  const handleEditorState = useCallback(
    (ev) => {
      setEditorState(ev)

      onChange({
        target: {
          id: props.id,
          type: props.type,
          name: props.name,
          value: convertToRaw(ev.getCurrentContent())
        },
        margins
      })
    },
    [props, setEditorState, onChange]
  )

  return {
    editorState,
    setEditorState,
    handleEditorState,
    focusEditor,
    margins,
    setMargins,
    isLoading,
    setLoading,
    readOnly,
    setReadOnly,
    formDirty,
    setFormDirty,
    savedEditor,
    setSavedEditor,
    isSending,
    setSending,
    ...props
  }
}

export const [RichTextAreaProvider, useRichTextContext] = createContainer(useRichText, (value) => value)
