import React, { memo, useContext, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Field, useField } from 'formik'
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'

import { useDisclosure } from '@sponte/lib-utils/dist/hooks/useDisclosure'
import { useMedia } from '@sponte/lib-utils/dist/hooks/useMedia'
import { useOnClickOutside } from '@sponte/lib-utils/dist/hooks/useOnClickOutside'
import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { usePopper } from '@sponte/lib-utils/src/hooks/usePopper'

import { SptBox } from '@sponte/lib-components/dist/elements/Box/Box'
import { SptFlex } from '@sponte/lib-components/dist/elements/Flex/Flex'
import { SptText } from '@sponte/lib-components/dist/elements/Text/Text'

import { SptIcon } from '@sponte/lib-components/dist/atoms/Icon/Icon'
import { SptPortal } from '@sponte/lib-components/dist/atoms/Portal/Portal'

import { SptFieldCheckbox } from '@sponte/lib-components/dist/molecules/FieldCheckbox/FieldCheckbox'
import { SptFieldFormik } from '@sponte/lib-components/dist/molecules/FieldFormik'

import { CadastroTesteGratisContext } from './CadastroTesteGratisContext'
import { CadastroTesteGratisFormikFormFieldSubdominio } from './CadastroTesteGratisFormikFormFieldSubdominio'

export const CadastroTesteGratisFormikForm = memo(() => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmall = useMedia(theme.mediaQueries.down.web, true, false)

  const { step } = useContext(CadastroTesteGratisContext)

  const [{ value: cpfCnpjChave }] = useField('cpfCnpj')
  const cpfCnpj = useMemo(() => [{ chave: 'CPF' }, { chave: 'CNPJ' }], [])

  const { isOpen, onClose, onToggle } = useDisclosure()
  const { referrence, popper, styles, popperNode, referrenceNode } = usePopper({
    placement: isSmall ? 'bottom-start' : 'right-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: isSmall ? [-50, 15] : [-10, 5]
        }
      }
    ]
  })

  useOnClickOutside([popperNode, referrenceNode], onClose)

  return (
    <>
      <AnimateSharedLayout type="crossfade">
        <SptFlex justifyContent="center" my={step === 0 && !isSmall ? 16 : 5}>
          <SptFlex width={3 / 5} flexDirection="column">
            <img src={theme.assets.logo} alt="medplus" height={isSmall ? 40 : 80} />

            <SptText fontSize="large" fontWeight="bold" color="secondary" textAlign="center" mt={isSmall ? 10 : 15}>
              {t('cadastro:oba')}
            </SptText>

            {step === 0 && (
              <SptText fontWeight="bold" color="primary" textAlign="center" mt={isSmall ? 10 : 15}>
                <AnimatePresence>
                  {step === 0 && (
                    <motion.span transition={{ ease: 'easeOut', duration: 1 }} exit={{ opacity: 0 }}>
                      {t('cadastro:vamosComecar')}
                    </motion.span>
                  )}
                </AnimatePresence>
              </SptText>
            )}
          </SptFlex>
        </SptFlex>

        {step === 0 && (
          <SptBox my={10}>
            <Field
              name="nome"
              component={SptFieldFormik}
              label={t('cadastro:qualSeuNome')}
              required
              prefix={<SptIcon>spt-user</SptIcon>}
            />
          </SptBox>
        )}

        {step === 1 && (
          <SptBox mt={isSmall ? 19 : 20} mb={10}>
            <AnimatePresence>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                <Field
                  name="cpfCnpj"
                  type="radioButtonGroup"
                  items={cpfCnpj}
                  itemToString={(i) => i.chave}
                  itemIsSelectedKey="chave"
                  size="lg"
                  component={SptFieldFormik}
                />
              </motion.div>
            </AnimatePresence>
          </SptBox>
        )}

        {step === 0 && (
          <SptBox my={10}>
            <Field
              name="email"
              required
              component={SptFieldFormik}
              label={t('cadastro:seuMelhorEmail')}
              prefix={<SptIcon>spt-mail</SptIcon>}
            />
          </SptBox>
        )}
        {step === 1 && (
          <AnimatePresence>
            <motion.div animate={{ y: isSmall ? -150 : -160 }} transition={{ duration: 1 }}>
              <Field
                name="telefone"
                type="phone"
                component={SptFieldFormik}
                label={t('cadastro:qualTelefone')}
                required
                prefix={<SptIcon>spt-telephone</SptIcon>}
              />
            </motion.div>
          </AnimatePresence>
        )}

        {step === 0 && (
          <SptBox my={10}>
            <AnimatePresence>
              <motion.div exit={{ y: isSmall ? 40 : 50 }} transition={{ duration: 1 }}>
                <Field
                  name="senha"
                  type="passwordStrength"
                  component={SptFieldFormik}
                  label={t('cadastro:crieUmaSenha')}
                  prefix={<SptIcon>spt-mail</SptIcon>}
                  required
                />
              </motion.div>
            </AnimatePresence>
          </SptBox>
        )}

        <CadastroTesteGratisFormikFormFieldSubdominio step={step} />

        {step === 1 && (
          <AnimatePresence>
            <motion.div animate={{ y: -110 }} transition={{ duration: 1 }}>
              <Field
                name="numeroDocumento"
                type={cpfCnpjChave.chave.toLowerCase()}
                component={SptFieldFormik}
                label={`${t('cadastro:informeSeu')} ${cpfCnpjChave.chave}`}
                required
                prefix={<SptIcon>spt-assignment-person</SptIcon>}
                suffix={
                  <SptIcon color="primary" ref={referrence} onClick={onToggle}>
                    spt-help
                  </SptIcon>
                }
              />
            </motion.div>
          </AnimatePresence>
        )}

        {step === 0 && (
          <SptFlex justifyContent="center">
            <SptFlex width={3 / 5} flexDirection="column">
              <SptText fontSize="medium" fontWeight="bold" color="darkGrey" textAlign="center">
                {t('cadastro:naoNecessarioCartao')}
              </SptText>
            </SptFlex>
          </SptFlex>
        )}

        {step === 1 && (
          <SptFlex alignItems="center" flex={1}>
            <Field
              name="concordoComTermos"
              component={SptFieldCheckbox}
              prefix={<SptIcon>spt-mail</SptIcon>}
              required
            />
            <SptBox ml={5}>
              <SptText color="darkGrey" fontWeight="medium">
                {`${t('cadastro:concordoCom')} `}
              </SptText>
              <a
                href="https://www.medplus.com.br/politica-de-privacidade/#dacoletadosdadoscomportamentais"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <SptText color="secondary" fontWeight="bold">
                  {`${t('cadastro:politicaProvacidade')}.`}
                </SptText>
              </a>
            </SptBox>
          </SptFlex>
        )}
      </AnimateSharedLayout>

      {isOpen && (
        <SptPortal>
          <SptBox
            border={`1px solid ${theme.colors.mediumGrey}`}
            borderTopRightRadius="sm"
            borderTopLeftRadius="sm"
            borderBottomRightRadius="sm"
            backgroundColor="white"
            width={{ _: 200, tablet: 350 }}
            zIndex={999999}
            ref={popper}
            style={{ ...styles }}
            p={5}
          >
            <SptFlex flexDirection="column">
              <SptText fontSize="medium" fontWeight="bold" color="primary">
                {t('cadastro:porqueUsamosCpfCnpj')}
              </SptText>
              <SptText
                fontSize="small"
                fontWeight="medium"
                color="almostBlack"
                mt={3}
                lineHeight={1.8}
                letterSpacing={1}
              >
                {t('cadastro:motivoDoCpfCnpj')}
              </SptText>
            </SptFlex>
          </SptBox>
        </SptPortal>
      )}
    </>
  )
})
