import React from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { theme, ifProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptFlex } from '../../elements/Flex'

const SptCardHeaderStyled = styled(SptFlex)`
  ${ifProp(
    'checkBorderMobile',
    css`
      border-radius: 0;
    `,
    css`
      border-top-left-radius: ${theme('radii.sm')}px;
      border-top-right-radius: ${theme('radii.sm')}px;
    `
  )};
`

SptCardHeaderStyled.displayName = 'SptCardHeaderStyled'

export const SptCardHeader = (props) => {
  return <SptCardHeaderStyled {...props} />
}

SptCardHeader.displayName = 'SptCardHeader'

SptCardHeader.propTypes = {
  children: PropTypes.node
}

SptCardHeader.defaultProps = {
  width: 1,
  alignItens: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid',
  borderColor: 'mediumGrey',
  checkBorderMobile: false
}
