import React, { memo, useMemo, forwardRef } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { space } from 'styled-system'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { prop, palette, switchProp, ifProp, ifNotProp, theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptIcon } from '../Icon'
import { SptLoading } from '../Loading'

export const SptButtonSizes = {
  sm: css`
    height: 32px;
  `,

  md: css`
    height: 40px;
  `,

  lg: css`
    height: 48px;
  `
}

export const SptButtonBadgeSizes = {
  sm: css`
    height: 15px;
    width: 15px;

    ${ifProp(
      { buttonSize: 'lg' },
      css`
        right: 0px;
        top: 0px;
      `,
      css`
        right: -3px;
        top: -5px;
      `
    )}
  `,

  md: css`
    height: 20px;
    width: 20px;
    right: -5px;
    top: -5px;
  `,

  lg: css`
    height: 23px;
    width: 23px;
    right: -5px;
    top: -5px;
  `
}

export const SptButtonVariants = {
  contained: css`
    border-color: ${palette()};
    background-color: ${palette()};

    > ${SptText}, > svg,
    > circle {
      color: ${theme('colors.white')} !important;
      stroke: ${theme('colors.white')} !important;
    }

    &:hover {
      border-color: ${palette('dark')};
      background-color: ${palette('dark')};
    }

    &:disabled {
      border-color: ${theme('colors.mediumGrey')};
      background-color: ${theme('colors.mediumGrey')};
    }
  `,

  outlined: css`
    border-color: ${theme('colors.mediumGrey')};

    > ${SptText}, > svg,
    > circle {
      color: ${palette()} !important;
      stroke: ${palette()} !important;
    }

    &:hover {
      border-color: ${palette('dark')};

      > ${SptText}, > svg,
      > circle {
        color: ${palette('dark')} !important;
        stroke: ${palette('dark')} !important;
      }
    }

    &:disabled {
      border-color: ${theme('colors.mediumGrey')};
      background-color: transparent;

      > ${SptText}, > svg,
      > circle {
        color: ${theme('colors.mediumGrey')} !important;
        stroke: ${theme('colors.mediumGrey')} !important;
      }
    }
  `,

  text: css`
    > ${SptText}, > svg,
    > circle {
      color: ${palette()} !important;
      stroke: ${palette()} !important;
    }

    &:focus {
      border-color: transparent;
    }

    &:hover {
      background-color: ${palette('light')};

      > ${SptText}, > svg,
      > circle {
        color: ${palette('dark')} !important;
        stroke: ${palette('dark')} !important;
      }
    }

    &:disabled {
      background-color: transparent;

      > ${SptText}, > svg,
      > circle {
        color: ${theme('colors.mediumGrey')} !important;
        stroke: ${theme('colors.mediumGrey')} !important;
      }
    }
  `
}

export const SptButtonFabVariants = {
  top: css`
    position: absolute;
    top: 0;
    margin-top: ${theme('space.10')}px;
  `,

  bottom: css`
    position: fixed;
    bottom: 0;
    margin-bottom: ${theme('space.10')}px;
    box-shadow: ${theme('shadows.5')};
  `
}

export const SptButtonContainer = styled.div``

export const SptButtonStyled = styled.button`
  ${space};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: ${theme('radii.lg')}px;
  text-decoration: none;
  text-transform: ${prop('textTransform', 'uppercase')};

  &:focus {
    border-color: ${palette('dark')};
    box-shadow: inset 0px 0px 0px 1px ${palette('light')};

    &:hover {
      box-shadow: none;
    }
  }

  svg {
    cursor: pointer;
    margin-left: -1px;
    margin-right: -1px;
  }

  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  ${ifNotProp(
    'disabled',
    css`
      &:active:after {
        transform: scale(0, 0);
        opacity: 0.3;
        transition: 0s;
      }
    `
  )};

  ${ifProp(
    'block',
    css`
      width: 100%;
      justify-content: center;
    `
  )};

  ${ifProp(
    'fab',
    css`
      ${theme('mediaQueries.down.web')} {
        z-index: 100;
        right: 0;
        padding: 0 ${theme('space.6')}px;
        margin-right: ${theme('space.10')}px;
        width: 48px;
        height: 48px;

        > ${SptText} {
          display: none;
        }

        ${switchProp('fab', SptButtonFabVariants, SptButtonFabVariants.bottom)};
      }
    `
  )};

  ${switchProp('size', SptButtonSizes)};

  ${switchProp('variant', SptButtonVariants)};
`

export const SptButtonBadgeStyled = styled(SptFlex)`
  ${switchProp('size', SptButtonBadgeSizes)};
`

SptButtonStyled.displayName = 'SptButtonStyled'

export const SptButton = memo(
  forwardRef(({ badge, children, size, icon, iconLeft, loading, truncate, ...props }, ref) => {
    const tag = useMemo(() => {
      return props.href ? 'a' : 'button'
    }, [props.href])

    const buttonProps = useMemo(() => {
      const btnProps = {
        pr: 10,
        pl: 10,
        size
      }

      let padding = (Object.keys(SptButtonSizes).indexOf(size) + 1) * 2

      if (!children) {
        btnProps.pr = padding
        btnProps.pl = padding

        return btnProps
      }

      padding = padding < 4 ? 4 : padding

      if (icon) {
        btnProps.pr = padding
      }

      if (iconLeft) {
        btnProps.pl = padding
      }

      if (loading) {
        btnProps.pl = padding
      }

      return btnProps
    }, [size, icon, iconLeft, loading, children])

    const iconPrefix = useMemo(() => {
      if (loading) {
        return <SptLoading data-testid="loading" />
      }

      if (!iconLeft) {
        return null
      }

      return <SptIcon data-testid="iconLeft">{iconLeft}</SptIcon>
    }, [iconLeft, loading])

    const iconSuffix = useMemo(() => {
      if (!icon || (!children && loading)) {
        return null
      }

      return <SptIcon data-testid="icon">{icon}</SptIcon>
    }, [icon, loading, children])

    const badgeContent = useMemo(() => {
      if (!badge || !getSafe(badge, 'active', true) || children) {
        return null
      }

      const badgePropsDefault = {
        alignItems: 'center',
        bg: getSafe(badge, 'color', 'red'),
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 'full',
        borderStyle: 'solid',
        justifyContent: 'center',
        position: 'absolute',
        size: getSafe(badge, 'size', size),
        buttonSize: size
      }

      return <SptButtonBadgeStyled {...badgePropsDefault}>{getSafe(badge, 'content', null)}</SptButtonBadgeStyled>
    }, [badge, size])

    const content = useMemo(() => {
      if (!children) {
        return null
      }

      const childrenProps = {}

      let padding = (Object.keys(SptButtonSizes).indexOf(size) + 1) * 2

      padding = padding < 4 ? 4 : padding

      if (iconPrefix) {
        childrenProps.pl = padding
      }

      if (iconSuffix) {
        childrenProps.pr = padding
      }

      return (
        <SptText fontWeight="bold" truncate={truncate} {...childrenProps}>
          {children}
        </SptText>
      )
    }, [size, children, iconPrefix, iconSuffix])

    const ButtonContainer = useMemo(() => {
      if (badgeContent) {
        return SptButtonContainer
      }

      return React.Fragment
    }, [badgeContent])

    const buttonContainerProps = useMemo(() => {
      if (badgeContent) {
        return {
          width: props.block && 1,
          position: 'relative'
        }
      }

      return {}
    }, [badgeContent, props.block])

    return (
      <ButtonContainer {...buttonContainerProps}>
        <SptButtonStyled as={tag} ref={ref} py={0} {...{ ...buttonProps, ...props }}>
          {iconPrefix}
          {content}
          {iconSuffix}
        </SptButtonStyled>

        {badgeContent}
      </ButtonContainer>
    )
  })
)

SptButton.displayName = 'SptButton'

SptButton.propTypes = {
  fab: PropTypes.oneOf(Object.keys(SptButtonFabVariants)),
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  iconLeft: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SptButtonSizes)).isRequired,
  variant: PropTypes.oneOf(Object.keys(SptButtonVariants)).isRequired,
  badge: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    active: PropTypes.bool,
    content: PropTypes.node
  }),
  truncate: PropTypes.bool
  // TODO: remover
  // palette: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'neutral']).isRequired
}

SptButton.defaultProps = {
  size: 'md',
  variant: 'contained',
  palette: 'primary',
  disabled: false,
  loading: false,
  block: false,
  type: 'button',
  badge: null
}
