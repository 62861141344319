import React, { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { useDisclosure } from '@sponte/lib-utils/dist/hooks/useDisclosure'

import { SptBox } from '../../elements/Box'
import { SptFlex } from '../../elements/Flex'
import { SptCol, SptRow } from '../../elements/Grid'
import { SptText } from '../../elements/Text'

import { SptButton } from '../../atoms/Button'
import { SptCardFooter } from '../../atoms/Card'
import { SptHr } from '../../atoms/Hr'
import { SptScrollbar } from '../../atoms/Scrollbar'

import { SptDrawer } from '../Drawer'
import { SptDrawerFormHeaderSimple } from '../DrawerFormHeader'
// eslint-disable-next-line import/no-cycle
import { SptFieldFormik } from '../FieldFormik'
import { SptFormTitle } from '../FormTitle'

import { ButtonWithToolTip } from './ComponentsStyleds'
import { useRichTextContext } from './RichTextAreaContext'

export const SptRichTextAreaContentControlsMargins = (props) => {
  const drawerProps = useDisclosure()

  return (
    <>
      <ButtonWithToolTip
        tipText="Adicionar margens"
        icon="spt-format-indent-increase"
        palette="neutral"
        variant="text"
        onClick={() => drawerProps.onOpen(props)}
      />

      {drawerProps.isOpen && <SptRichTextAreaContentControlsMarginsDrawer {...drawerProps} />}
    </>
  )
}

export const SptRichTextAreaContentControlsMarginsDrawer = ({ isOpen, onClose, ...props }) => {
  const { t } = useTranslation()

  return (
    <SptDrawer anchor="right" width={{ _: 1, web: 700 }} boxShadow={2} opened={isOpen} onClose={onClose}>
      <SptDrawerFormHeaderSimple title={t('geral:margens')} onClose={onClose} />

      <SptRichTextAreaContentControlsMarginsContent onClose={onClose} {...props} />
    </SptDrawer>
  )
}

export const SptRichTextAreaContentControlsMarginsContent = ({ onClose }) => {
  const { t } = useTranslation()

  const { margins, setMargins } = useRichTextContext()

  const initialValues = useMemo(() => {
    return {
      topo: margins.mt,
      rodape: margins.mb,
      esquerda: margins.ml,
      direita: margins.mr
    }
  }, [margins])

  const validationSchema = useMemo(() => {
    return Yup.object({
      topo: Yup.number()
        .max(10, t('geral:validacoes.numeroMaximo', { max: 10 }))
        .typeError(t('geral:validacoes.numero')),
      rodape: Yup.number()
        .max(10, t('geral:validacoes.numeroMaximo', { max: 10 }))
        .typeError(t('geral:validacoes.numero')),
      esquerda: Yup.number()
        .max(10, t('geral:validacoes.numeroMaximo', { max: 10 }))
        .typeError(t('geral:validacoes.numero')),
      direita: Yup.number()
        .max(10, t('geral:validacoes.numeroMaximo', { max: 10 }))
        .typeError(t('geral:validacoes.numero'))
    })
  }, [t])

  const onSubmit = useCallback(
    (values) => {
      setMargins({
        mt: values.topo,
        mb: values.rodape,
        mr: values.direita,
        ml: values.esquerda
      })

      onClose()
    },
    [onClose, setMargins]
  )

  return (
    <SptScrollbar>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isSubmitting, values }) => (
          <Form noValidate>
            <SptBox p={15} pt={0}>
              <SptFormTitle icon="spt-form" title={t('geral:alinhamento')} />

              <SptRow>
                <SptCol width={{ _: 1, tablet: 1 / 2 }}>
                  <Field
                    name="topo"
                    label={t('geral:topo')}
                    suffix={<SptRichTextAreaContentControlsMarginsFieldSuffix />}
                    required
                    component={SptFieldFormik}
                  />
                </SptCol>

                <SptCol width={{ _: 1, tablet: 1 / 2 }}>
                  <Field
                    name="rodape"
                    label={t('geral:rodape')}
                    suffix={<SptRichTextAreaContentControlsMarginsFieldSuffix />}
                    required
                    component={SptFieldFormik}
                  />
                </SptCol>
              </SptRow>

              <SptRow>
                <SptCol width={{ _: 1, tablet: 1 / 2 }}>
                  <Field
                    name="esquerda"
                    label={t('geral:esquerda')}
                    suffix={<SptRichTextAreaContentControlsMarginsFieldSuffix />}
                    required
                    component={SptFieldFormik}
                  />
                </SptCol>

                <SptCol width={{ _: 1, tablet: 1 / 2 }}>
                  <Field
                    name="direita"
                    label={t('geral:direita')}
                    suffix={<SptRichTextAreaContentControlsMarginsFieldSuffix />}
                    required
                    component={SptFieldFormik}
                  />
                </SptCol>
              </SptRow>

              <SptHr />

              <SptFormTitle icon="spt-form" title={t('geral:previsualizacao')} />

              <SptFlex
                flexDirection="column"
                height="99mm"
                width="70mm"
                margin="auto"
                border="1px solid"
                borderColor="darkGrey"
                borderRadius="sm"
                mb={10}
                pb={values.rodape && parseFloat(values.rodape) <= 10 ? `${parseFloat(values.rodape) / 3}cm` : 0}
                pt={values.topo && parseFloat(values.topo) <= 10 ? `${parseFloat(values.topo) / 3}cm` : 0}
                pl={values.esquerda && parseFloat(values.esquerda) <= 10 ? `${parseFloat(values.esquerda) / 3}cm` : 0}
                pr={values.direita && parseFloat(values.direita) <= 10 ? `${parseFloat(values.direita) / 3}cm` : 0}
                overflow="overlay"
              >
                <SptText fontSize="large" fontWeight="bold" mx="auto" mb={4}>
                  Titulo
                </SptText>

                <SptText mb={4}>1. Introdução</SptText>

                <SptText mb={2}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec tortor porttitor, convallis nulla
                  id, sagittis ex. In hac habitasse platea dictumst. Vestibulum mattis leo eu lectus pulvinar sodales.
                  Morbi ultrices bibendum finibus. Fusce nulla dolor, malesuada a leo et, pretium tempus purus. Cras eu
                  cursus ligula. Etiam non dictum elit. Aliquam tincidunt porttitor erat quis aliquet. Pellentesque diam
                  tortor, rutrum vel libero nec, porttitor suscipit magna.
                </SptText>

                <SptText>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec tortor porttitor, convallis nulla
                  id, sagittis ex. In hac habitasse platea dictumst. Vestibulum mattis leo eu lectus pulvinar sodales.
                  Morbi ultrices bibendum finibus. Fusce nulla dolor, malesuada a leo et, pretium tempus purus. Cras eu
                  cursus ligula. Etiam non dictum elit. Aliquam tincidunt porttitor erat quis aliquet. Pellentesque diam
                  tortor, rutrum vel libero nec, porttitor suscipit magna.
                </SptText>
              </SptFlex>
            </SptBox>

            <SptCardFooter>
              <SptButton type="submit" iconLeft="spt-done" loading={isSubmitting} disabled={isSubmitting}>
                {t('geral:salvar')}
              </SptButton>
            </SptCardFooter>
          </Form>
        )}
      </Formik>
    </SptScrollbar>
  )
}

const SptRichTextAreaContentControlsMarginsFieldSuffix = () => {
  return (
    <SptFlex
      borderLeft="1px solid"
      borderColor="mediumGrey"
      height="100%"
      alignItems="center"
      width={50}
      justifyContent="flex-end"
      marginRight={10}
      marginLeft={10}
      truncate
    >
      <SptText fontWeight="bold" truncate>
        cm
      </SptText>
    </SptFlex>
  )
}
