import React from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import { SptFlex } from '../../elements/Flex'

import { SptButtonStyled } from '../../atoms/Button'

import { SptFieldButtonMultiselectStyled } from '../FieldButtonMultiselect'
import { SptFieldButtonSelectStyled } from '../FieldButtonSelect'

export const SptButtonGroupStyled = styled(SptFlex)`
  width: 100%;

  > ${SptFieldButtonMultiselectStyled}, > ${SptFieldButtonSelectStyled} {
    flex: 1;

    :first-of-type ${SptButtonStyled} {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :last-of-type ${SptButtonStyled} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    :not(:first-of-type):not(:last-of-type) ${SptButtonStyled} {
      border-radius: 0;
    }

    :not(:first-of-type) ${SptButtonStyled} {
      border-left: none;
    }
  }

  > ${SptButtonStyled} {
    flex: 1;

    :first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    :not(:first-of-type):not(:last-of-type) {
      border-radius: 0;
    }

    :not(:first-of-type) {
      border-left: none;
    }
  }
`

SptButtonGroupStyled.displayName = 'SptButtonGroupStyled'

export const SptButtonGroup = ({ children, ...props }) => {
  const content = React.Children.map(children, (child) => {
    return React.cloneElement(child, props)
  })

  return <SptButtonGroupStyled {...props}>{content}</SptButtonGroupStyled>
}

SptButtonGroup.displayName = 'SptButtonGroup'

SptButtonGroup.propTypes = {
  children: PropTypes.node
}

SptButtonGroup.defaultProps = {}
