import React, { useRef, useMemo } from 'react'

import useInputMask from 'use-input-mask'

import { INPUT_CNPJ_MASK, maskCpnj } from '@sponte/lib-utils/dist/helpers/masks'

import { SptField } from '../Field'

export const SptFieldCnpj = ({ value, onChange, ...props }) => {
  const inputRef = useRef(null)

  const handleChange = useInputMask({
    input: inputRef,
    onChange,
    mask: INPUT_CNPJ_MASK,
    guide: false,
    initialValue: maskCpnj(value)
  })

  const fieldProps = useMemo(
    () => ({
      ...props,
      type: 'text',
      value: maskCpnj(value),
      onChange: handleChange
    }),
    [props, handleChange]
  )

  return <SptField inputRef={inputRef} {...fieldProps} />
}

SptFieldCnpj.propTypes = {}

SptFieldCnpj.defaultProps = {}
