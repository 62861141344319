import React, { forwardRef } from 'react'

import { SptCard } from '../Card'

export const SptMenuList = forwardRef((props, ref) => {
  return <SptCard ref={ref} py={4} tabIndex="-1" role="menu" overflow="hidden auto" data-testid={props.id} {...props} />
})

SptMenuList.displayName = 'SptMenuList'

SptMenuList.propTypes = {}

SptMenuList.defaultProps = {}
