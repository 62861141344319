import { useState, useEffect } from 'react'

export function useMedia(queries = [], values = [], defaultValue) {
  const q = Array.isArray(queries) ? queries : [queries]
  const v = Array.isArray(values) ? values : [values]

  const mediaQueryLists = q.map((query) => window.matchMedia(query.replace('@media', '').trim()))

  const getValue = () => {
    const index = mediaQueryLists.findIndex((mediaQuery) => mediaQuery.matches)

    return typeof v[index] !== 'undefined' ? v[index] : defaultValue
  }

  const [value, setValue] = useState(getValue)

  useEffect(() => {
    const handler = () => {
      setValue(getValue)
    }

    mediaQueryLists.forEach((mediaQuery) => mediaQuery.addListener(handler))

    return () => {
      mediaQueryLists.forEach((mediaQuery) => mediaQuery.removeListener(handler))
    }
  }, [])

  return value
}
