import React from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { ifProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptText } from '../../elements/Text'

const SptBreadcrumbItemStyled = styled(SptText)`
  &,
  svg {
    ${ifProp(
      'onClick',
      css`
        cursor: pointer;
      `
    )};
  }
`

SptBreadcrumbItemStyled.displayName = 'SptBreadcrumbItemStyled'

export const SptBreadcrumbItem = ({ children, ...props }) => {
  return (
    <SptBreadcrumbItemStyled color="darkGrey" fontSize={14} {...props}>
      {children}
    </SptBreadcrumbItemStyled>
  )
}

SptBreadcrumbItem.displayName = 'SptBreadcrumbItem'

SptBreadcrumbItem.propTypes = {
  children: PropTypes.string
}

SptBreadcrumbItem.defaultProps = {}
