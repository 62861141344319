import React, { memo, useRef, useMemo, useState, useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { fontFamily } from 'styled-system'

import { callAllEventHandlers } from '@sponte/lib-utils/dist/helpers/callAll'
import { generateId } from '@sponte/lib-utils/dist/helpers/generateId'

import { useForkRef } from '@sponte/lib-utils/dist/hooks/useForkRef'

import { theme, palette, ifProp, switchProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'
import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptTooltip } from '../../atoms'
import { SptIcon } from '../../atoms/Icon'
import { SptInput } from '../../atoms/Input'
import { SptLoading } from '../../atoms/Loading'
import { SptTextarea } from '../../atoms/Textarea'

export const SptFieldSizes = {
  sm: css`
    height: 32px;
  `,

  md: css`
    height: 40px;
  `,

  lg: css`
    height: 48px;
  `
}

export const SptFieldVariants = {
  info: css`
    border-color: ${palette('info')};

    + span {
      color: ${palette('info')};
    }
  `,

  success: css`
    border-color: ${palette('success')};

    + span {
      color: ${palette('success')};
    }
  `,

  warning: css`
    border-color: ${palette('warning')};

    + span {
      color: ${palette('warning')};
    }
  `,

  error: css`
    border-color: ${palette('error')};

    + span {
      color: ${palette('error')};
    }
  `
}

export const SptFieldShapes = {
  square: css`
    border-radius: ${theme('radii.none')}px;
  `,

  rounded: css`
    border-radius: ${theme('radii.sm')}px;
  `,

  pill: css`
    border-radius: ${theme('radii.lg')}px;
  `,

  fullPill: css`
    border-radius: ${theme('radii.full')}px;
  `
}

const SptFieldIconVariants = {
  info: <SptIcon color="info">spt-info</SptIcon>,

  success: <SptIcon color="success">spt-check-circle</SptIcon>,

  warning: <SptIcon color="warning">spt-warning</SptIcon>,

  error: <SptIcon color="error">spt-alert</SptIcon>
}

const SptFieldStyled = styled(SptFlex)`
  height: auto;
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-color: ${ifProp('focused', palette(), theme('colors.mediumGrey'))};
  ${fontFamily};

  svg {
    z-index: 1;
  }

  label {
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(8px) scale(1.25);
    transform-origin: left;

    ${ifProp(
      (props) => props.hasValue || props.focused,
      css`
        transform: translateY(0) scale(1);
      `
    )};
  }

  ${ifProp(
    'disabled',
    css`
      background-color: ${theme('colors.lightGrey')};
      border-color: ${theme('colors.darkGrey')};
    `
  )};

  ${switchProp('size', SptFieldSizes)};

  ${switchProp('variant', SptFieldVariants)};

  ${switchProp('shape', SptFieldShapes)};

  ${ifProp(
    'textarea',
    css`
      height: auto !important;
    `
  )};

  ${ifProp(
    'width',
    css`
      width: ${(props) => props.width};
    `
  )};
`

SptFieldStyled.displayName = 'SptFieldStyled'

const SptFieldAddonStyled = styled(SptFlex).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  height: 100%;

  > span {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 ${theme('space.10')}px;
  }
`

SptFieldAddonStyled.displayName = 'SptFieldAddonStyled'

const SptFieldPrefixStyled = styled(SptFieldAddonStyled).attrs({
  mr: 6
})`
  > svg:first-of-type {
    margin-left: ${theme('space.6')}px;
  }

  > button:first-of-type {
    margin-left: ${theme('space.4')}px;
  }

  > span {
    border-right: 1px solid ${theme('colors.mediumGrey')};
  }
`

SptFieldPrefixStyled.displayName = 'SptFieldPrefixStyled'

const SptFieldSuffixStyled = styled(SptFieldAddonStyled).attrs({
  ml: 6
})`
  > svg:last-of-type {
    margin-right: ${theme('space.6')}px;
  }

  > button:last-of-type {
    margin-right: ${theme('space.4')}px;
  }

  > span {
    border-left: 1px solid ${theme('colors.mediumGrey')};
  }
`

SptFieldSuffixStyled.displayName = 'SptFieldSuffixStyled'

const SptFieldLabelStyled = memo(({ id, htmlFor, required, children }) => (
  <SptText as="label" variant="label" id={id} htmlFor={htmlFor} mr={8}>
    {children}
    {required && (
      <SptText fontSize="small" color="error" pl={1}>
        *
      </SptText>
    )}
  </SptText>
))

SptFieldLabelStyled.displayName = 'SptFieldLabelStyled'

const SptFieldTextHelpStyled = memo(({ id, children, color }) => (
  <SptText variant="label" color={color && color} px={10} id={id} data-testid={id}>
    {children || <>&nbsp;</>}
  </SptText>
))

SptFieldTextHelpStyled.displayName = 'SptFieldTextHelpStyled'

export const SptField = memo(
  ({
    id,
    name,
    suffix,
    prefix,
    label,
    textHelp,
    required,
    size,
    shape,
    variant,
    palette: colorPalette,
    disabled,
    loading,
    value,
    onClick,
    displayTextHelp,
    onBlur,
    onFocus,
    textarea,
    children,
    inputRef: inputRefProp,
    boxRef,
    maxLength,
    hasValue: hasValueProp,
    width,
    info,
    textHelpColor,
    showCharactersAmount,
    ...props
  }) => {
    const inputRef = useRef()

    const handleInputRef = useForkRef(inputRef, inputRefProp)

    const [focused, setFocus] = useState(false)

    const setInputFocus = useCallback(() => {
      if (inputRef.current && !disabled) {
        inputRef.current.focus()
      }
    }, [disabled])

    const [hasValue, setHasValue] = useState(hasValueProp || (inputRef.current && !!inputRef.current.value))

    useEffect(() => {
      setHasValue(hasValueProp || !!hasValue || (inputRef.current && !!inputRef.current.value))
    }, [value, hasValueProp, focused, inputRef.current])

    const { inputId, labelId, describeId } = useMemo(() => {
      const baseInputId = id || name || generateId()

      return {
        inputId: baseInputId,
        labelId: `${baseInputId}-label`,
        describeId: `${baseInputId}-describe`
      }
    }, [id, name])

    const suffixComp = useMemo(() => {
      if (loading) {
        return <SptLoading variant="spinner" palette="primary" />
      }

      if (suffix) {
        return suffix
      }

      if (variant && SptFieldIconVariants[variant]) {
        return SptFieldIconVariants[variant]
      }

      if (info) {
        return (
          <SptTooltip title={info} zIndex={1000}>
            <SptBox mr={6}>
              <SptIcon>spt-info</SptIcon>
            </SptBox>
          </SptTooltip>
        )
      }

      return null
    }, [suffix, variant, loading, info])

    const fieldProps = useMemo(
      () => ({
        onClick: onClick || setInputFocus,
        hasValue,
        focused,
        size,
        variant,
        shape,
        palette: colorPalette,
        pr: suffixComp ? 0 : 10,
        pl: prefix ? 0 : 10,
        disabled,
        textarea,
        htmlFor: inputId
      }),
      [inputId, hasValue, focused, size, shape, variant, colorPalette, disabled, onClick, prefix, suffixComp]
    )

    const inputProps = useMemo(
      () => ({
        id: inputId,
        'data-testid': inputId,
        name,
        value,
        disabled,
        maxLength,
        ref: handleInputRef,
        onBlur: callAllEventHandlers(onBlur, () => setFocus(false)),
        onFocus: callAllEventHandlers(onFocus, () => setFocus(true)),
        'aria-labelledby': labelId,
        'aria-describedby': describeId,
        inputProps: inputRef,
        ...props
      }),
      [inputId, name, value, disabled, handleInputRef, onBlur, onFocus, labelId, describeId, props, inputRef]
    )

    const prefixComponent = useMemo(() => {
      if (prefix) {
        return <SptFieldPrefixStyled>{prefix}</SptFieldPrefixStyled>
      }

      return prefix
    }, [prefix])

    const suffixComponent = useMemo(() => {
      if (suffixComp) {
        return <SptFieldSuffixStyled>{suffixComp}</SptFieldSuffixStyled>
      }

      return suffixComp
    }, [suffixComp])

    const labelComponent = useMemo(() => {
      if (label) {
        return (
          <SptFieldLabelStyled id={labelId} htmlFor={inputId} required={required}>
            {label}
          </SptFieldLabelStyled>
        )
      }

      return label
    }, [inputId, labelId, label, required])

    const countCharacters = useMemo(() => {
      if (typeof value === 'string') {
        return (
          <SptText as="label" variant="label" mx={6}>
            {maxLength ? `${value.length} / ${maxLength}` : value.length}
          </SptText>
        )
      }

      return null
    }, [value])

    const textHelpComponent = useMemo(() => {
      if (!displayTextHelp) {
        return null
      }

      return (
        <SptFieldTextHelpStyled id={describeId} color={textHelpColor}>
          {textHelp}
        </SptFieldTextHelpStyled>
      )
    }, [describeId, displayTextHelp, textHelp])

    const inputComponent = !textarea ? <SptInput {...inputProps} /> : <SptTextarea {...inputProps} />

    return (
      <SptBox width={1}>
        <SptFieldStyled
          ref={boxRef}
          mb={displayTextHelp ? 1 : 0}
          alignItems="center"
          overflow="hidden"
          {...fieldProps}
          onClick={setInputFocus}
          width={width}
        >
          {prefixComponent}

          <SptFlex py={3} flex={1} flexDirection="column">
            <SptFlex flexDirection="row" justifyContent="space-between" mr={2}>
              {labelComponent}
              {!!showCharactersAmount && countCharacters}
            </SptFlex>
            {inputComponent}
          </SptFlex>

          {suffixComponent}
        </SptFieldStyled>

        {textHelpComponent}
      </SptBox>
    )
  }
)

SptField.displayName = 'SptField'

SptField.propTypes = {
  size: PropTypes.oneOf(Object.keys(SptFieldSizes)),
  variant: PropTypes.oneOf(['', ...Object.keys(SptFieldVariants)]),
  shape: PropTypes.oneOf([...Object.keys(SptFieldShapes)]),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  textHelp: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  palette: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'neutral']).isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  displayTextHelp: PropTypes.bool,
  maxLength: PropTypes.number,
  hasValue: PropTypes.bool,
  width: PropTypes.number,
  info: PropTypes.string
}

SptField.defaultProps = {
  size: 'lg',
  palette: 'primary',
  shape: 'pill',
  loading: false,
  disabled: false,
  required: false,
  displayTextHelp: true,
  hasValue: false
}
