import React from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { useTranslation } from 'react-i18next'

import { theme, switchProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'

export const SptProgressCircularSizes = {
  xs: css`
    width: 40px;
    height: 40px;
  `,

  sm: css`
    width: 60px;
    height: 60px;
  `,

  md: css`
    width: 80px;
    height: 80px;
  `,

  lg: css`
    width: 110px;
    height: 110px;
  `
}

export const SptProgressCircularStyled = styled(SptBox).attrs({ as: 'svg' })`
  ${switchProp('size', SptProgressCircularSizes)};

  path {
    fill: none;
    stroke-width: ${(props) => (props.showImage ? '3.5' : '2.5')};
  }

  .bg {
    stroke: ${theme('colors.mediumGrey')};
  }

  .circle {
    stroke: ${(props) => props.theme.colors[props.color || 'primary']};
    transform: rotate(-180deg) translate(-36px, -36px);
    animation: progress 1s ease-out forwards;
  }

  image {
    transform: scale(1.7) translate(-10px, -10px);
  }

  text {
    text-anchor: middle;
    font-family: ${theme('fontFamily.default')};
    font-weight: 700;
  }

  .value {
    fill: ${(props) => props.theme.colors[props.color || 'primary']};
    font-size: 10px;
  }

  .maxValue {
    fill: ${theme('colors.darkGrey')};
    font-size: 5px;
  }

  .texto {
    font-size: 11px;
    fill: white;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
`

SptProgressCircularStyled.displayName = 'SptProgressCircularStyled'

export const SptProgressCircular = ({ value = 0, maxValue = 1, showLabel, showImage, showText, ...props }) => {
  const { t } = useTranslation()

  const val = value > maxValue ? maxValue : value
  const percentage = (100 * value) / maxValue
  const patternImageId = Math.floor(Math.random() * 10000 + 1)

  const fillObject = (showImageProgressCircular, showTextProgressCircular) => {
    if (showImageProgressCircular) {
      return `url(#imgpattern${patternImageId})`
    }

    if (showTextProgressCircular) {
      return '#c6ccd7'
    }

    return 'none'
  }

  return (
    <SptProgressCircularStyled viewBox="0 0 36 36" {...props} showImage={showImage}>
      <defs>
        <pattern id={`imgpattern${patternImageId}`} x="0" y="0" width="1" height="1">
          <image width="100%" height="100%" href={showImage} />
        </pattern>
      </defs>

      <path className="bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />

      <path
        className="circle"
        strokeDasharray={`${percentage}, 100`}
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      />

      <circle cx="18" cy="18" r="15" fill={fillObject(showImage, showText)} />

      {showText && (
        <text x="18" y="22" className="texto">
          {showText}
        </text>
      )}

      {showLabel && (
        <>
          <text x="18" y="18" className="value">
            {val}
          </text>
          <text x="18" y="24" className="maxValue">
            {`${t('geral:de').toLowerCase()} ${maxValue}`}
          </text>
        </>
      )}
    </SptProgressCircularStyled>
  )
}

SptProgressCircular.displayName = 'SptProgressCircular'

SptProgressCircular.propTypes = {
  size: PropTypes.oneOf(Object.keys(SptProgressCircularSizes)).isRequired,
  color: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  showLabel: PropTypes.bool
}

SptProgressCircular.defaultProps = {
  size: 'md',
  color: 'primary',
  value: 0,
  maxValue: 1,
  showLabel: true
}
