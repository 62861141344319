import React, { memo, useState, useCallback, cloneElement } from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import FocusLock from 'react-focus-lock'
import { RemoveScroll } from 'react-remove-scroll'

import { callAllEventHandlers } from '@sponte/lib-utils/dist/helpers/callAll'
import { eventPersist, eventStopPropagation } from '@sponte/lib-utils/dist/helpers/events'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptOverlay } from '../Overlay'
import { SptPortal } from '../Portal'

export const SptModalWrapper = styled.div`
  z-index: ${theme('zIndices.modal')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

SptModalWrapper.displayName = 'SptModalWrapper'

export const SptModal = memo(
  ({
    opened,
    initialFocusRef = null,
    onClose = () => {},
    onClick = () => {},
    onKeyDown = () => {},
    children,
    disableOnKeyDown = false,
    disableFocusLock,
    ...props
  }) => {
    const [exited, setExited] = useState(!opened)

    const handleClick = useCallback(() => {
      onClose()
    }, [onClose])

    const handleKeyDown = useCallback(
      (event) => {
        if (event.key === 'Escape') {
          onClose()
        }
      },
      [onClose]
    )

    const handleEnter = useCallback(() => {
      setExited(false)
    }, [setExited])

    const handleExited = useCallback(() => {
      setExited(true)
    }, [setExited])

    if (!opened && exited) {
      return null
    }

    return (
      <SptPortal>
        <SptModalWrapper>
          <SptOverlay
            opened={opened}
            onClick={callAllEventHandlers(eventPersist, onClick, handleClick, eventStopPropagation)}
          />

          {disableFocusLock ? (
            <RemoveScroll>
              {cloneElement(children, {
                opened,
                onEnter: handleEnter,
                onExited: handleExited,
                onKeyDown:
                  !disableOnKeyDown &&
                  callAllEventHandlers(eventPersist, onKeyDown, handleKeyDown, eventStopPropagation),
                ...props
              })}
            </RemoveScroll>
          ) : (
            <FocusLock
              returnFocus
              onActivation={() => {
                if (initialFocusRef && initialFocusRef.current) {
                  initialFocusRef.current.focus()
                }
              }}
            >
              <RemoveScroll>
                {cloneElement(children, {
                  opened,
                  onEnter: handleEnter,
                  onExited: handleExited,
                  onKeyDown:
                    !disableOnKeyDown &&
                    callAllEventHandlers(eventPersist, onKeyDown, handleKeyDown, eventStopPropagation),
                  ...props
                })}
              </RemoveScroll>
            </FocusLock>
          )}
        </SptModalWrapper>
      </SptPortal>
    )
  }
)

SptModal.displayName = 'SptModal'

SptModal.propTypes = {
  opened: PropTypes.bool,
  initialFocusRef: PropTypes.any,
  onClose: PropTypes.func,
  children: PropTypes.node
}

SptModal.defaultProps = {
  opened: true
}
