import React from 'react'

import { SptText } from '../../elements/Text'

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()

    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
  }, callback)
}

const Link = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData()

  return (
    <SptText as="a" href={url} target="_blank" style={{ textDecoration: 'underline' }} color="blue">
      {children}
    </SptText>
  )
}

export const linkDecorator = ({ strategy, component } = {}) => {
  return {
    strategy: strategy || findLinkEntities,
    component: component || Link
  }
}

export const getBlockAlignment = (block) => {
  let style = 'left'

  block.findStyleRanges((e) => {
    if (e.hasStyle('center')) {
      style = 'center'
    }

    if (e.hasStyle('right')) {
      style = 'right'
    }

    if (e.hasStyle('justify')) {
      style = 'justify'
    }
  })

  return style
}

export const TEXT_COLORS = [
  'info',
  'success',
  'warning',
  'error',
  'red',
  'pink',
  'purple',
  'deepPurple',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'green',
  'lime',
  'yellow',
  'orange',
  'deepOrange',
  'brown',
  'almostBlack'
]
