import React from 'react'

import styled from 'styled-components'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptIcon } from '../Icon'

import { SptMenuItemDivider } from './MenuItemDivider'

const SptMenuItemAddStyled = styled(SptFlex)`
  cursor: pointer;
`

SptMenuItemAddStyled.displayName = 'SptMenuItemAddStyled'

export const SptMenuItemAdd = ({ children, ...props }) => {
  return (
    <>
      <SptMenuItemDivider />

      <SptMenuItemAddStyled bg="lightGrey" my={-4} py={8} pl={10} pr={5} flexDirection="column" {...props}>
        <SptFlex alignItems="center" justifyContent="space-between">
          <SptText fontWeight="medium">{children}</SptText>
          <SptIcon color="primary">spt-plus</SptIcon>
        </SptFlex>
      </SptMenuItemAddStyled>
    </>
  )
}

SptMenuItemAdd.displayName = 'SptMenuItemAdd'

SptMenuItemAdd.propTypes = {}

SptMenuItemAdd.defaultProps = {}
