import React, { useEffect, useRef, useState } from 'react'

import TableIcon from './TableIcon'

export default ({ children, editorState, modifier, onChange, render }) => {
  const preventNextClose = useRef(false)

  const [columns, setColumns] = useState(3)
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState(2)

  useEffect(() => {
    const closePopover = () => {
      if (!preventNextClose.current && open) {
        setOpen(false)
      }

      preventNextClose.current = false
    }

    document.addEventListener('click', closePopover)

    return () => {
      document.removeEventListener('click', closePopover)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPopoverClick = () => {
    preventNextClose.current = true
  }

  const openPopover = () => {
    if (!open) {
      preventNextClose.current = true
      setOpen(true)
    }
  }

  const addTable = () => {
    onChange(
      modifier(editorState, {
        columns,
        rows
      })
    )
  }

  const changeCols = (evt) => {
    setColumns(evt.target.value)
  }

  const changeRows = (evt) => {
    setRows(evt.target.value)
  }

  return (
    <div>
      {!render && (
        <button onMouseUp={openPopover} type="button">
          {children && children}
          {!children && <TableIcon />}
        </button>
      )}
      {render && render({ onClick: openPopover })}
      {/* eslint-disable-next-line */}
      <div onClick={onPopoverClick}>
        <div style={{ marginBottom: 5 }}>Add a table</div>
        <div>
          <span style={{ display: 'block' }}>rows: </span>
          <input type="text" placeholder="Rows" onChange={changeRows} value={rows} />
        </div>
        <div>
          <span style={{ display: 'block' }}>cols: </span>
          <input type="text" placeholder="Columns" onChange={changeCols} value={columns} />
        </div>
        <button type="button" onClick={addTable} style={{ float: 'right' }}>
          Add
        </button>
      </div>
    </div>
  )
}
