import React, { useRef, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import useInputMask from 'use-input-mask'

import { INPUT_PERCENTAGE_MASK } from '@sponte/lib-utils/dist/helpers/masks'
import { onlyNumberFromCurrency } from '@sponte/lib-utils/dist/helpers/number'

import { SptField } from '../Field'

export const SptFieldPercentage = ({ value, onChange, returnWhenNaN = 0, ...props }) => {
  const { t } = useTranslation()
  const inputRef = useRef(null)

  const internalValue = value ? t('geral:percent_formatter', { value }) : undefined

  const changeValue = (number) => {
    if (Number.isNaN(parseFloat(number))) {
      return returnWhenNaN
    }

    if (number) {
      return onlyNumberFromCurrency(number)
    }

    return undefined
  }

  const handleOnChange = useInputMask({
    input: inputRef,
    onChange: (e) => {
      if (!onChange || typeof onChange !== 'function') {
        return
      }
      onChange({
        ...e,
        target: {
          ...e.target,
          id: props.id,
          type: 'text',
          name: props.name,
          value: changeValue(e.target.value)
        }
      })
    },
    mask: INPUT_PERCENTAGE_MASK,
    guide: false,
    initialValue: internalValue
  })

  const fieldProps = useMemo(
    () => ({
      ...props,
      type: 'text',
      hasValue: !!internalValue,
      onChange: handleOnChange
    }),
    [props, internalValue, handleOnChange]
  )

  return <SptField inputRef={inputRef} {...fieldProps} />
}

SptFieldPercentage.displayName = 'SptFieldPercentage'

SptFieldPercentage.propTypes = {}

SptFieldPercentage.defaultProps = {}
