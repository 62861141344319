import React, { memo, useMemo } from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import ContentLoader from 'react-content-loader'

import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { prop } from '@sponte/lib-utils/dist/theme/tools'

const SptContentLoaderStyled = styled(ContentLoader)`
  width: ${prop('width')};
  height: ${prop('height')};
`

SptContentLoaderStyled.displayName = 'SptContentLoaderStyled'

/**
 * http://danilowoz.com/create-content-loader/
 */
export const SptContentLoader = memo(({ title, primaryColor, secondaryColor, radius, ratio, children, ...props }) => {
  const theme = useTheme()

  const contentLoaderProps = {
    ...props,
    ariaLabel: title || 'Carregando',
    primaryColor: theme.colors[primaryColor],
    secondaryColor: theme.colors[secondaryColor],
    gradientRatio: ratio
  }

  const content = useMemo(() => {
    if (children) {
      return children
    }

    const customProps = {
      ...props
    }

    if (radius) {
      customProps.rx = radius
      customProps.ry = radius
    }

    return <rect {...customProps} />
  }, [children, radius, props])

  return <SptContentLoaderStyled {...contentLoaderProps}>{content}</SptContentLoaderStyled>
})

export const SptContentLoaderCode = memo((props) => {
  return (
    <SptContentLoader {...props}>
      <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
      <rect x="80" y="0" rx="3" ry="3" width="100" height="10" />
      <rect x="190" y="0" rx="3" ry="3" width="10" height="10" />

      <rect x="15" y="20" rx="3" ry="3" width="130" height="10" />
      <rect x="155" y="20" rx="3" ry="3" width="130" height="10" />

      <rect x="15" y="40" rx="3" ry="3" width="90" height="10" />
      <rect x="115" y="40" rx="3" ry="3" width="60" height="10" />
      <rect x="185" y="40" rx="3" ry="3" width="60" height="10" />

      <rect x="0" y="60" rx="3" ry="3" width="30" height="10" />
    </SptContentLoader>
  )
})

export const SptContentLoaderList = memo((props) => {
  return (
    <SptContentLoader {...props}>
      <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
      <rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
    </SptContentLoader>
  )
})

export const SptContentLoaderInstagram = memo((props) => {
  return (
    <SptContentLoader {...props}>
      <circle cx="30" cy="30" r="30" />
      <rect x="75" y="13" rx="4" ry="4" width="100" height="13" />
      <rect x="75" y="37" rx="4" ry="4" width="50" height="8" />
      <rect x="0" y="70" rx="5" ry="5" width="400" height="400" />
    </SptContentLoader>
  )
})

export const SptContentLoaderFacebook = memo((props) => {
  return (
    <SptContentLoader {...props}>
      <rect x="70" y="15" rx="4" ry="4" width="117" height="6.4" />
      <rect x="70" y="35" rx="3" ry="3" width="85" height="6.4" />
      <rect x="0" y="80" rx="3" ry="3" width="350" height="6.4" />
      <rect x="0" y="100" rx="3" ry="3" width="380" height="6.4" />
      <rect x="0" y="120" rx="3" ry="3" width="201" height="6.4" />
      <circle cx="30" cy="30" r="30" />
    </SptContentLoader>
  )
})

export const SptContentLoaderBulletList = memo((props) => {
  return (
    <SptContentLoader {...props}>
      <circle cx="10" cy="20" r="8" />
      <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="50" r="8" />
      <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="80" r="8" />
      <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="110" r="8" />
      <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
    </SptContentLoader>
  )
})

export const SptContentLoaderChatList = memo((props) => {
  return (
    <SptContentLoader {...props}>
      <circle cx="20" cy="20" r="20" />
      <rect x="57" y="13" rx="5" ry="5" width="160" height="11" />
      <circle cx="20" cy="133" r="20" />
      <circle cx="20" cy="191" r="20" />
      <rect x="57" y="185" rx="5" ry="5" width="160" height="11" />
      <rect x="0" y="73" rx="5" ry="5" width="140" height="11" />
      <rect x="57" y="128" rx="5" ry="5" width="160" height="11" />
      <circle cx="20" cy="248" r="20" />
      <rect x="57" y="242" rx="5" ry="5" width="160" height="11" />
      <circle cx="20" cy="305" r="20" />
      <rect x="57" y="299" rx="5" ry="5" width="160" height="11" />
    </SptContentLoader>
  )
})

SptContentLoader.displayName = 'SptContentLoader'

const propTypes = {
  radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  animate: PropTypes.bool,
  ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  baseUrl: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preserveAspectRatio: PropTypes.oneOf([
    'none',
    'xMinYMin meet',
    'xMidYMin meet',
    'xMaxYMin meet',
    'xMinYMid meet',
    'xMidYMid meet',
    'xMaxYMid meet',
    'xMinYMax meet',
    'xMidYMax meet',
    'xMaxYMax meet',
    'xMinYMin slice',
    'xMidYMin slice',
    'xMaxYMin slice',
    'xMinYMid slice',
    'xMidYMid slice',
    'xMaxYMid slice',
    'xMinYMax slice',
    'xMidYMax slice',
    'xMaxYMax slice'
  ]),
  primaryColor: PropTypes.string,
  rtl: PropTypes.bool,
  secondaryColor: PropTypes.string,
  speed: PropTypes.number,
  interval: PropTypes.number,
  style: PropTypes.object,
  uniquekey: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ratio: PropTypes.number
}

SptContentLoader.propTypes = propTypes

SptContentLoader.defaultProps = {
  primaryColor: 'lightGrey',
  secondaryColor: 'mediumGrey',
  ratio: 2,
  width: '400px',
  height: '130px',
  interval: 0.25,
  preserveAspectRatio: 'none',
  speed: 2,
  style: {}
}

SptContentLoaderCode.propTypes = propTypes
SptContentLoaderList.propTypes = propTypes
SptContentLoaderFacebook.propTypes = propTypes
SptContentLoaderInstagram.propTypes = propTypes
SptContentLoaderBulletList.propTypes = propTypes
SptContentLoaderChatList.propTypes = propTypes

SptContentLoaderInstagram.defaultProps = {
  height: '480px'
}
