import React, { useRef } from 'react'

import { SptFlex } from '../../../dist/elements/Flex/Flex'

import { SptCard } from '../../atoms/Card'

import { SptFieldTextHelpStyled } from './ComponentsStyleds/FieldTextHelpStyled'
import { RichTextAreaFullPageStyled } from './ComponentsStyleds/RichTextAreaFullPageStyled'
import { RichTextAreaStyled } from './ComponentsStyleds/RichTextAreaStyled'
// eslint-disable-next-line import/no-cycle
import { SptRichTextAreaContentControls } from './RichTextAreaContentControls'
import { SptRichTextAreaContentEditor } from './RichTextAreaContentEditor'
import { useRichTextContext } from './RichTextAreaContext'

export const SptRichTextAreaContent = () => {
  const { textHelp, variant, focusEditor, margins } = useRichTextContext()
  const editorRef = useRef(null)

  return (
    <>
      <SptCard boxShadow={0} border="1px solid" borderColor="mediumGrey" borderRadius="sm">
        <SptRichTextAreaContentControls ref={editorRef} />

        <RichTextAreaStyled
          mb={margins.mb ? `${parseFloat(margins.mb)}cm` : 0}
          ml={margins.ml ? `${parseFloat(margins.ml)}cm` : 0}
          mr={margins.mr ? `${parseFloat(margins.mr)}cm` : 0}
          mt={margins.mt ? `${parseFloat(margins.mt)}cm` : 0}
          p={Object.keys(margins).length === 0 ? 10 : 0}
          onClick={focusEditor}
        >
          <SptRichTextAreaContentEditor />
        </RichTextAreaStyled>
      </SptCard>
      {/* <SptFieldTextHelpStyled variant={variant}>{textHelp}</SptFieldTextHelpStyled> */}
    </>
  )
}

export const SptRichTextAreaContentFullPage = () => {
  const { focusEditor, margins } = useRichTextContext()
  const editorRef = useRef(null)

  return (
    <>
      <SptCard boxShadow={0} borderColor="mediumGrey" borderRadius="sm">
        <SptRichTextAreaContentControls ref={editorRef} />
      </SptCard>

      <SptFlex overflow="overlay">
        <SptFlex flex={1} justifyContent="center" bg="lightGrey" height="max-content" minHeight="125vh" py={10}>
          <SptCard
            boxShadow={0}
            border="1px solid"
            borderColor="mediumGrey"
            minWidth="220mm"
            width="220mm"
            borderRadius="sm"
          >
            <RichTextAreaFullPageStyled
              onClick={focusEditor}
              mb={margins.mb ? `${parseFloat(margins.mb)}cm` : 0}
              ml={margins.ml ? `${parseFloat(margins.ml)}cm` : 0}
              mr={margins.mr ? `${parseFloat(margins.mr)}cm` : 0}
              mt={margins.mt ? `${parseFloat(margins.mt)}cm` : 0}
            >
              <SptRichTextAreaContentEditor ref={editorRef} />
            </RichTextAreaFullPageStyled>
          </SptCard>
        </SptFlex>
      </SptFlex>
    </>
  )
}
