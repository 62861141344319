import styled from 'styled-components'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

export const DailyEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DayNumberContainer = styled.div`
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  font-size: 14px;
  font-family: ${theme('fonts.default')};
  font-weight: 400;

  &:hover {
    cursor: pointer;
    background: ${props => props.isToday ? '#85d2ef' : '#f2f4f8'};
  }

  background: ${props => props.isToday ? '#85d2ef' : ''};
`

export const EventsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`

export const DayEvents = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`

export const Event = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 0 1px;

  background: ${(props) => props.color};
`
