import React, { useMemo, useCallback } from 'react'

import { SptButton } from '../../atoms/Button'

import { SptField } from '../Field'

export const SptFieldNumber = ({ min = 0, max, value, name, onChange, clearable, ...props }) => {
  const { disabled: disabledProp } = props

  const val = useMemo(() => {
    if (!Number.isNaN(parseInt(value, 10))) {
      if (parseInt(value, 10) > 0) {
        return parseInt(value, 10)
      }

      return '0'
    }

    return ''
  }, [value])

  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        onChange({
          ...e,
          target: {
            ...e.target,
            id: name,
            name,
            type: 'text',
            value: parseInt(e.target.value, 10) > max ? max : parseInt(e.target.value, 10) || (clearable ? '' : '0')
          }
        })
      }
    },
    [name, onChange]
  )

  const handleAdd = useCallback(
    (e) => {
      handleChange({ ...e, target: { ...e.target, value: val + 1 } })
    },
    [val, handleChange]
  )

  const handleRemove = useCallback(
    (e) => {
      handleChange({ ...e, target: { ...e.target, value: val - 1 } })
    },
    [val, handleChange]
  )

  const prefix = useMemo(
    () => (
      <SptButton
        size="sm"
        ml={5}
        variant="outlined"
        data-testid="prefix"
        icon="spt-minus"
        onClick={handleRemove}
        disabled={disabledProp || (typeof min === 'number' && val <= min)}
      />
    ),
    [min, val, disabledProp, handleRemove]
  )

  const suffix = useMemo(
    () => (
      <SptButton
        size="sm"
        mr={5}
        variant="outlined"
        data-testid="suffix"
        icon="spt-plus"
        onClick={handleAdd}
        disabled={disabledProp || (typeof max === 'number' && val >= max)}
      />
    ),
    [max, val, disabledProp, handleAdd]
  )

  const fieldProps = useMemo(
    () => ({
      ...props,
      name,
      type: 'text',
      value: val,
      onChange: handleChange,
      prefix,
      suffix
    }),
    [props, prefix, suffix]
  )

  return <SptField {...fieldProps} />
}

SptFieldNumber.propTypes = {}

SptFieldNumber.defaultProps = {}
