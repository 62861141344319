import React, { memo } from 'react'

import PropTypes from 'prop-types'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptIcon } from '../../atoms/Icon'

export const SptFormTitle = memo(({ icon, title, subtitle, actions, ...props }) => {
  return (
    <SptFlex my={15} alignItems="center" {...props}>
      {icon && (
        <SptIcon color="darkGrey" mr={6}>
          {icon}
        </SptIcon>
      )}

      <SptFlex alignItems="flex-start" flexDirection="column" flex={1}>
        {title && (
          <SptText fontWeight="bold" color="darkGrey" pr={6}>
            {title.toUpperCase()}
          </SptText>
        )}

        {subtitle && (
          <SptText color="darkGrey" fontSize="small">
            {subtitle}
          </SptText>
        )}
      </SptFlex>

      {actions && <SptFlex>{actions}</SptFlex>}
    </SptFlex>
  )
})

SptFormTitle.displayName = 'SptFormTitle'

SptFormTitle.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
}

SptFormTitle.defaultProps = {}
