import React, { useMemo } from 'react'

import { useField } from 'formik'

import { SptCheckbox } from '../../atoms/Checkbox'
import { SptCheckboxIcon } from '../../atoms/CheckboxIcon'
import { SptSwitch } from '../../atoms/Switch'

const fields = {
  switch: SptSwitch
}

export const SptFieldCheckbox = ({
  form,
  field,
  icon,
  type,
  displayTextHelp,
  disabled,
  onChange = () => {},
  ...props
}) => {
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField(field.name)
  const { status } = form

  const handleChange = (valor) => {
    setValue(valor)
    onChange(valor)
  }

  const Comp = useMemo(() => {
    if (type) {
      return fields[type]
    }

    if (icon) {
      return SptCheckboxIcon
    }

    return SptCheckbox
  }, [icon])

  return (
    <Comp
      checked={value}
      onBlur={onBlur}
      onChange={handleChange}
      error={(displayTextHelp || touched) && error}
      displayTextHelp={displayTextHelp || touched}
      disabled={disabled || status === 'DISABLE_ALL_FORM'}
      {...props}
    />
  )
}

SptFieldCheckbox.displayName = 'SptFieldCheckbox'

SptFieldCheckbox.propTypes = {}

SptFieldCheckbox.defaultProps = {}
