import React, { memo, useRef, useMemo, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { callAllEventHandlers } from '@sponte/lib-utils/dist/helpers/callAll'
import { generateId } from '@sponte/lib-utils/dist/helpers/generateId'

import { useForkRef } from '@sponte/lib-utils/dist/hooks/useForkRef'

import { ifProp, theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'
import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptFieldBoxLetterCurrency } from './FieldBoxLetterCurrency'
import { SptFieldBoxLetterDate } from './FieldBoxLetterDate'
import { SptFieldBoxLetterHour } from './FieldBoxLetterHour'
import { SptFieldBoxLetterSimple } from './FieldBoxLetterSimple'

export const SptFlexBorder = styled(SptFlex)`
  border: 1px solid #c6ccd7;
  border-radius: 3px;
  padding: ${theme('space.1')}px ${theme('space.3')}px;
  margin: ${theme('space.2')}px;
  ${ifProp(
    'error',
    css`
      border-color: ${theme('colors.error')} !important;
    `
  )};
`

export const SptBoxBorder = styled(SptBox)`
  border: 1px solid #c6ccd7;
  border-radius: 3px;
  padding: ${theme('space.1')}px ${theme('space.3')}px;
  margin: ${theme('space.2')}px;
  ${ifProp(
    'error',
    css`
      border-color: ${theme('colors.error')} !important;
    `
  )};
`

const HeaderStyled = styled(SptFlex)`
  background-color: #f2f4f8;
  margin: ${theme('space.2')}px;
`

export const SptHeaderSection = memo(({ title, height = '25px', alignItems = 'center', flex = 1, ...props }) => {
  return (
    <HeaderStyled height={height} alignItems={alignItems} flex={flex} {...props}>
      <SptText ml={5} fontSize={10} fontWeight="bold" color="almostBlack">
        {title}
      </SptText>
    </HeaderStyled>
  )
})

SptHeaderSection.displayName = 'HeaderSection'

SptHeaderSection.propType = {
  title: PropTypes.string.isRequired
}

SptHeaderSection.defaultProps = {
  flex: 1,
  height: '25px',
  alignItems: 'center'
}

export const SptFieldPrefixStyled = memo(({ children }) => {
  return (
    <SptFlex mr={3} alignItems="center" width="10px">
      <SptText fontWeight="bold" fontSize="small">
        {children}
      </SptText>
    </SptFlex>
  )
})

SptFieldPrefixStyled.displayName = 'SptFieldPrefixStyled'

export const SptLabelBoxLetter = memo(({ id, htmlFor, required, children, hasPrefix }) => (
  <SptFlex flex={1} mb={2} justifyContent="flex-start" ml={hasPrefix ? 3 : 0}>
    <SptText as="label" variant="label" id={id} htmlFor={htmlFor}>
      {children}
      {required && (
        <SptText fontSize="small" color="error" pl={1}>
          *
        </SptText>
      )}
    </SptText>
  </SptFlex>
))

SptLabelBoxLetter.displayName = 'SptLabelBoxLetter'

const FieldBoxLetterComp = {
  date: SptFieldBoxLetterDate,
  hours: SptFieldBoxLetterHour,
  currency: SptFieldBoxLetterCurrency
}

export const SptFieldBoxLetter = memo(
  ({
    id,
    name,
    prefix,
    label,
    textHelp,
    required,
    variant,
    variantField,
    palette: colorPalette,
    disabled,
    value,
    displayTextHelp,
    onBlur,
    onFocus,
    textarea,
    children,
    inputRef: inputRefProp,
    boxRef,
    maxLength,
    hasValue: hasValueProp,
    justifyContentInput = 'flex-start',
    onChange,
    ...props
  }) => {
    const inputRef = useRef()

    const handleInputRef = useForkRef(inputRef, inputRefProp)

    const [focused, setFocus] = useState(false)

    const [hasValue, setHasValue] = useState(hasValueProp || (inputRef.current && !!inputRef.current.value))

    useEffect(() => {
      setHasValue(hasValueProp || !!hasValue || (inputRef.current && !!inputRef.current.value))
    }, [value, hasValueProp, focused, inputRef.current])

    const { inputId, labelId, describeId } = useMemo(() => {
      const baseInputId = id || name || generateId()

      return {
        inputId: baseInputId,
        labelId: `${baseInputId}-label`,
        describeId: `${baseInputId}-describe`
      }
    }, [id, name])

    const inputProps = useMemo(
      () => ({
        'data-testid': inputId,
        name,
        value,
        disabled,
        ref: handleInputRef,
        onBlur: callAllEventHandlers(onBlur, () => setFocus(false)),
        onFocus: callAllEventHandlers(onFocus, () => setFocus(true)),
        'aria-labelledby': labelId,
        'aria-describedby': describeId,
        onChange,
        maxLength,
        autoComplete: 'none',
        ...props
      }),
      [inputId, value, disabled, handleInputRef, onBlur, onFocus, labelId, describeId, props]
    )

    const prefixComponent = useMemo(() => {
      if (prefix) {
        return <SptFieldPrefixStyled>{prefix}</SptFieldPrefixStyled>
      }

      return prefix
    }, [prefix])

    const labelComponent = useMemo(() => {
      if (label) {
        return (
          <SptLabelBoxLetter id={labelId} htmlFor={inputId} required={required} hasPrefix={!!prefix}>
            {label}
          </SptLabelBoxLetter>
        )
      }

      return label
    }, [inputId, labelId, label, required])

    const Comp = useMemo(() => FieldBoxLetterComp[variantField] || SptFieldBoxLetterSimple, [variantField])

    return (
      <SptBox p={2}>
        {labelComponent}
        <SptFlex justifyContent={justifyContentInput}>
          {prefixComponent}
          <Comp {...inputProps} />
        </SptFlex>
      </SptBox>
    )
  }
)

SptFieldBoxLetter.displayName = 'SptFieldBoxLetter'

SptFieldBoxLetter.propTypes = {
  variant: PropTypes.oneOf(PropTypes.string),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  textHelp: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  palette: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'neutral']).isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  displayTextHelp: PropTypes.bool,
  maxLength: PropTypes.number,
  hasValue: PropTypes.bool
}

SptFieldBoxLetter.defaultProps = {
  size: 'lg',
  palette: 'primary',
  disabled: false,
  required: false,
  displayTextHelp: true,
  hasValue: false
}
