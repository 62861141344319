import React from 'react'

import styled from 'styled-components'

import { DayPickerSingleDateController } from 'react-dates'

import moment from 'moment'

import { theme, fontSize } from '@sponte/lib-utils/dist/theme/tools'

import 'moment/locale/pt-br'

import { SptButton } from '../Button'

export const SptCalendarStyled = styled.div`
  .CalendarDay__default {
    font-family: ${theme('fonts.default')};
    font-weight: ${theme('fontWeights.medium')};
    ${fontSize('medium')}
    border: none;
    line-height: 35px;
  }

  .CalendarDay__default:hover {
    border: none;
    background: ${theme('colors.lightGrey')};
    color: ${theme('colors.darkGrey')};
  }

  .CalendarDay {
    border-radius: 20px;
  }

  .DayPicker__withBorder {
    border-radius: 10px;
  }

  .DayPickerNavigation {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .DayPickerNavigation_button button {
    margin-top: ${theme('space.6')}px;
    margin-left: ${theme('space.6')}px;
    margin-right: ${theme('space.6')}px;
  }

  .CalendarMonth_caption {
    font-weight: ${theme('fontWeights.medium')};
    ${fontSize('default')}
    width: 117%;
    margin-left: -22px;
    background: ${theme('colors.lightGrey')};
    padding-bottom: 22px;
    margin-bottom: 30px;
  }

  .DayPicker_weekHeader {
    background: ${theme('colors.white')};
    font-weight: ${theme('fontWeights.medium')};
    color: ${theme('colors.almostBlack')};
    margin-top: 10px;
  }

  .CalendarDay__highlighted_calendar {
    border: 1px solid ${theme('colors.primary')};
    position: absolute;
    background: none;
  }

  .CalendarDay__selected {
    background: ${theme('colors.primary')};
    border: 1px double ${theme('colors.primary')};
    font-weight: ${theme('fontWeights.bold')};
  }
`

SptCalendarStyled.displayName = 'SptCalendarStyled'

export const SptCalendar = () => {
  moment.locale('pt-BR', {
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    weekdaysMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
  })

  return (
    <SptCalendarStyled>
      <DayPickerSingleDateController
        hideKeyboardShortcutsPanel
        navPrev={<SptButton icon="spt-caret-left" palette="neutral" variant="text" />}
        navNext={<SptButton icon="spt-caret-right" palette="neutral" variant="text" />}
      />
    </SptCalendarStyled>
  )
}

SptCalendar.displayName = 'SptCalendar'

SptCalendar.propTypes = {}

SptCalendar.defaultProps = {}
