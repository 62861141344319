import React, { useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import { RemoveScroll } from 'react-remove-scroll'

import { useSelect } from 'downshift'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { useMedia } from '@sponte/lib-utils/dist/hooks/useMedia'
import { usePopper } from '@sponte/lib-utils/dist/hooks/usePopper'
import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { SptBox } from '../../elements/Box'
import { SptText } from '../../elements/Text'

import { SptButton } from '../../atoms/Button'
import { SptHr } from '../../atoms/Hr'
import { SptMenuItem } from '../../atoms/MenuItem'
import { SptMenuList } from '../../atoms/MenuList'
import { SptModalWrapper } from '../../atoms/Modal'
import { SptOverlay } from '../../atoms/Overlay'
import { SptPortal } from '../../atoms/Portal'
import { SptScrollbar } from '../../atoms/Scrollbar'

export const SptFieldButtonMultiselectStyled = styled(SptBox)``

const defaultItemToString = (item) => (typeof item === 'string' ? item : getSafe(item, 'nome', '')) || ''

export const SptFieldButtonMultiselect = ({
  items: defaultItems = [],
  itemIsSelectedKey = 'id',
  itemToString = defaultItemToString,
  groupToString = defaultItemToString,
  getGroupItems = null,
  onChange = () => {},
  value = [],
  children,
  id: idProp,
  name: nameProp,
  title,
  ...props
}) => {
  const theme = useTheme()
  const isMobile = useMedia(theme.mediaQueries.down.web, true, false)

  const { referrence, popper, styles } = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed'
  })

  const itemIsSelected = useCallback(
    (item) => {
      return !!value.find(
        (selectedItem) =>
          getSafe(item, itemIsSelectedKey, item) === getSafe(selectedItem, itemIsSelectedKey, selectedItem)
      )
    },
    [value, itemIsSelectedKey]
  )

  const id = useMemo(() => {
    return idProp || nameProp
  }, [idProp, nameProp])

  const items = useMemo(() => {
    if (getGroupItems) {
      return defaultItems.reduce((acc, group) => {
        return [...acc, ...getGroupItems(group)]
      }, [])
    }

    return defaultItems
  }, [defaultItems, getGroupItems])

  const { isOpen, highlightedIndex, getMenuProps, getItemProps, getToggleButtonProps } = useSelect({
    id,
    items,
    selectedItem: value,
    defaultHighlightedIndex: -1,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!onChange || !selectedItem) {
        return
      }

      onChange({
        target: {
          id,
          name: props.name,
          type: 'text',
          value: itemIsSelected(selectedItem)
            ? value.filter(
                (item) =>
                  getSafe(item, itemIsSelectedKey, item) !== getSafe(selectedItem, itemIsSelectedKey, selectedItem)
              )
            : [...value, selectedItem]
        }
      })
    }
  })

  const itemsToRender = useMemo(() => {
    let list = []

    if (getGroupItems) {
      list = defaultItems.reduce(
        (result, group, index) => {
          const groupItems = getGroupItems(group)

          if (groupItems.length === 0) {
            return result
          }

          let newIndex = result.index

          const newItems = [
            ...result.items,
            <SptMenuItem key={index} variant="header">
              {groupToString(group)}
            </SptMenuItem>,
            ...groupItems.map((item, itemIdx) => {
              newIndex += 1

              return (
                <SptMenuItem
                  key={`${index}-${itemIdx}`}
                  icon={item.icon}
                  iconColor={item.iconColor}
                  avatar={item.avatar}
                  active={newIndex === highlightedIndex}
                  selected={itemIsSelected(item)}
                  {...getItemProps({
                    item,
                    index: newIndex
                  })}
                >
                  {itemToString(item)}
                </SptMenuItem>
              )
            })
          ]

          return {
            items: newItems,
            index: newIndex
          }
        },
        { items: [], index: -1 }
      ).items
    } else {
      list = items.map((item, index) => {
        return (
          <SptMenuItem
            key={index}
            icon={item.icon}
            iconColor={item.iconColor}
            avatar={item.avatar}
            active={index === highlightedIndex}
            selected={itemIsSelected(item)}
            {...getItemProps({
              item,
              index
            })}
          >
            {itemToString(item)}
          </SptMenuItem>
        )
      })
    }

    if (list.length > 0) {
      return list
    }

    return (
      <SptMenuItem variant="header" truncate>
        <SptText>Nenhum registro encontrado</SptText>
      </SptMenuItem>
    )
  }, [getItemProps, itemToString, items, highlightedIndex, itemIsSelected])

  const menuToRender = useMemo(() => {
    if (isMobile) {
      return (
        <SptPortal>
          <SptModalWrapper>
            <SptOverlay opened={isOpen} />

            <RemoveScroll>
              <SptMenuList
                zIndex={9999}
                width={1}
                {...getMenuProps({
                  position: 'fixed',
                  bottom: 0,
                  right: 0,
                  height: '70%'
                })}
              >
                <SptScrollbar>
                  {title && (
                    <SptBox mt={5}>
                      <SptText ml={10}>{title}</SptText>
                      <SptHr />
                    </SptBox>
                  )}
                  {itemsToRender}
                </SptScrollbar>
              </SptMenuList>
            </RemoveScroll>
          </SptModalWrapper>
        </SptPortal>
      )
    }

    return (
      <RemoveScroll>
        <SptMenuList
          zIndex={10}
          width={300}
          {...getMenuProps({
            ref: popper,
            style: styles
          })}
        >
          <SptScrollbar maxHeight={200}>{itemsToRender}</SptScrollbar>
        </SptMenuList>
      </RemoveScroll>
    )
  }, [isMobile, itemsToRender, popper, styles])

  return (
    <SptFieldButtonMultiselectStyled width={1} position="relative" data-testid="container-button-select">
      <SptBox ref={referrence}>
        <SptButton {...getToggleButtonProps(props)}>{children}</SptButton>
      </SptBox>

      {isOpen && menuToRender}
    </SptFieldButtonMultiselectStyled>
  )
}

SptFieldButtonMultiselect.displayName = 'SptFieldButtonMultiselect'

SptFieldButtonMultiselect.propTypes = {
  items: PropTypes.array.isRequired,
  itemToString: PropTypes.func,
  groupToString: PropTypes.func,
  getGroupItems: PropTypes.func,
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.any
}

SptFieldButtonMultiselect.defaultProps = {}
