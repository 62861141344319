import PropTypes from 'prop-types'

import styled from 'styled-components'

import { SptFlex } from '../../elements/Flex'

export const SptCardBody = styled(SptFlex)`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

SptCardBody.displayName = 'SptCardBody'

SptCardBody.propTypes = {
  children: PropTypes.node
}

SptCardBody.defaultProps = {
  flex: 1,
  flexDirection: 'column'
}
