import React, { memo, useRef, useMemo, useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { callAllEventHandlers } from '@sponte/lib-utils/dist/helpers/callAll'
import { generateId } from '@sponte/lib-utils/dist/helpers/generateId'

import { useForkRef } from '@sponte/lib-utils/dist/hooks/useForkRef'

import { ifProp, switchProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'
import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptInput } from '../../atoms/Input'
import { SptTextarea } from '../../atoms/Textarea'

import { SptFieldSizes, SptFieldVariants } from '../Field/Field'

const SptFieldSimpleStyled = styled(SptFlex)`
  height: auto;
  background-color: transparent;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  svg {
    z-index: 1;
  }

  label {
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(8px) scale(1.25);
    transform-origin: left;

    ${ifProp(
      (props) => props.hasValue || props.focused,
      css`
        transform: translateY(0) scale(1);
      `
    )};
  }

  ${switchProp('size', SptFieldSizes)};

  ${ifProp(
    (props) => props.textarea,
    css`
      height: auto;
    `
  )};

  ${switchProp('variant', SptFieldVariants)};
`

SptFieldSimpleStyled.displayName = 'SptFieldSimpleStyled'

const SptFieldSimpleLabelStyled = memo(({ id, htmlFor, required, children }) => (
  <SptText as="label" variant="label" id={id} htmlFor={htmlFor}>
    {children}
    {required && (
      <SptText fontSize="small" color="error" pl={1}>
        *
      </SptText>
    )}
  </SptText>
))

SptFieldSimpleLabelStyled.displayName = 'SptFieldSimpleLabelStyled'

const SptFieldSimpleTextHelpStyled = memo(({ id, children }) => (
  <SptText variant="label" px={10} id={id}>
    {children || <>&nbsp;</>}
  </SptText>
))

SptFieldSimpleTextHelpStyled.displayName = 'SptFieldSimpleTextHelpStyled'

export const SptFieldSimple = memo(
  ({
    id,
    name,
    label,
    textHelp,
    required,
    size,
    palette,
    disabled,
    loading,
    value,
    onClick,
    displayTextHelp,
    onBlur,
    onFocus,
    textarea,
    children,
    variant,
    inputRef: inputRefProp,
    ...props
  }) => {
    const inputRef = useRef()

    const handleInputRef = useForkRef(inputRef, inputRefProp)

    const [focused, setFocus] = useState(false)

    const setInputFocus = useCallback(() => {
      if (inputRef && inputRef.current && !disabled) {
        inputRef.current.focus()
      }
    }, [inputRef, disabled])

    const hasValue = useMemo(() => !!value || (inputRef && inputRef.current && !!inputRef.current.value), [
      inputRef,
      value,
      focused
    ])

    const { inputId, labelId, describeId } = useMemo(() => {
      const baseInputId = id || name || generateId()

      return {
        inputId: baseInputId,
        labelId: `${baseInputId}-label`,
        describeId: `${baseInputId}-describe`
      }
    }, [id, name])

    const fieldProps = useMemo(
      () => ({
        onClick: onClick || setInputFocus,
        hasValue,
        focused,
        size,
        variant,
        palette,
        disabled,
        textarea,
        htmlFor: inputId
      }),
      [inputId, hasValue, focused, size, palette, disabled, onClick]
    )

    const inputProps = useMemo(
      () => ({
        id: inputId,
        name,
        value,
        disabled,
        ref: handleInputRef,
        onBlur: callAllEventHandlers(onBlur, () => setFocus(false)),
        onFocus: callAllEventHandlers(onFocus, () => setFocus(true)),
        'aria-labelledby': labelId,
        'aria-describedby': describeId,
        ...props
      }),
      [inputId, name, value, disabled, handleInputRef, onBlur, onFocus, labelId, describeId, props]
    )

    const labelComponent = useMemo(() => {
      if (label) {
        return (
          <SptFieldSimpleLabelStyled id={labelId} htmlFor={inputId} required={required}>
            {label}
          </SptFieldSimpleLabelStyled>
        )
      }

      return label
    }, [inputId, labelId, label, required])

    const textHelpComponent = useMemo(() => {
      if (!displayTextHelp) {
        return null
      }

      return <SptFieldSimpleTextHelpStyled id={describeId}>{textHelp}</SptFieldSimpleTextHelpStyled>
    }, [describeId, displayTextHelp, textHelp])

    const inputComponent = !textarea ? <SptInput {...inputProps} /> : <SptTextarea {...inputProps} />

    return (
      <SptBox width={1}>
        <SptFieldSimpleStyled mb={displayTextHelp ? 1 : 0} alignItems="center" overflow="hidden" {...fieldProps}>
          <SptBox py={3} width={1}>
            {labelComponent}
            {inputComponent}
          </SptBox>
        </SptFieldSimpleStyled>

        {textHelpComponent}
      </SptBox>
    )
  }
)

SptFieldSimple.displayName = 'SptFieldSimple'

SptFieldSimple.propTypes = {
  size: PropTypes.oneOf(Object.keys(SptFieldSizes)),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  textHelp: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  palette: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'neutral']).isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  displayTextHelp: PropTypes.bool
}

SptFieldSimple.defaultProps = {
  size: 'lg',
  palette: 'primary',
  loading: false,
  disabled: false,
  required: false,
  displayTextHelp: true
}
