export default {
  geral: {
    avancar: 'Avance',
    obrigado: 'Gracias'
  },
  cadastro: {
    oba: '¡Hola! ¡Nos encantará tenerte aquí durante los próximos 15 días!',
    qualSeuNome: '¿Cuál es tu nombre?',
    seuMelhorEmail: 'Tu mejor correo electrónico',
    vamosComecar: '¿Vamos a empezar?',
    crieUmaSenha: 'Crea una contraseña',
    naoNecessarioCartao: 'No se requiere tarjeta de crédito ;)',
    informeSeu: 'Ingresa el tuyo aquí',
    nomeSuaClinica: 'nombredesuclínica',
    qualTelefone: '¿Cuál es tu teléfono?',
    concordoCom: 'Estoy de acuerdo con el',
    politicaProvacidade: 'Política de privacidad',
    naoUtilizeespacoesNemAcentos: 'No use espacios, acentos o caracteres especiales',
    porqueUsamosCpfCnpj: '¿Por qué pedimos su CPF / CNPJ?',
    motivoDoCpfCnpj:
      'Su CPF / CNPJ es su identificador en nuestro sistema. Es necesario crear la base de datos personalizada para usted y garantizar la seguridad en el acceso. Tenga la seguridad de que lo usaremos solo para este propósito.',
    acesseSeuEmail:
      'Mientras tanto, necesito que acceda a su correo electrónico y haga clic en el enlace que le envié para validar su cuenta y disfrutar de todas las funciones de MedPlus de forma gratuita.',
    nosVemosEmBreve: 'Nos vemos pronto ;)',
    suaBaseFoiCriada: 'Tu base ya esta siendo creada.'
  },

  validacoes: {
    dominioRegistrado: 'Dominio ya registrado'
  }
}
