import React, { memo, useMemo, useCallback, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import moment from 'moment'

import { SptBox } from '../../elements/Box'
import { SptFlex } from '../../elements/Flex'

import { SptFieldBoxLetterInputStyled } from './FieldBoxLetterInputStyled'
import { keysNotAllow, numberOnly } from './utils'

export const SptFieldBoxLetterDate = memo(({ id, name, value, disabled = false, onChange, setValue, ...props }) => {
  const internalValue = useMemo(() => {
    let arrayValue = []
    if (value && value.slice(0, 10).length === 10) {
      arrayValue = moment(value).format('DDMMYYYY')
    } else if (value) {
      arrayValue = String(value).replaceAll('/', '').replaceAll('-', '').slice(0, 8)
    }

    return Array.from({ length: 8 }).map((_, i) => (i > arrayValue.length - 1 ? '' : arrayValue[i]))
  }, [value])

  const inputsRefs = useRef(null)

  useEffect(() => {
    inputsRefs.current = document.getElementsByName(`${name}-input`)
  }, [])

  const setInputFocus = useCallback(() => {
    if (!value && !disabled) {
      document.getElementById(`${name}-0`).focus()
    }
  }, [disabled, value, name])

  const handleKeyDown = useCallback(
    (e, i) => {
      if (keysNotAllow(e.key) || numberOnly(e.key)) {
        return
      }
      const prevCharacter = inputsRefs.current[i - 1]
      const nextCharacter = inputsRefs.current[i + 1]
      if (e.key === 'ArrowRight') {
        if (nextCharacter) {
          nextCharacter.focus()
        }
        return
      }

      if (e.key === 'ArrowLeft') {
        if (prevCharacter) {
          prevCharacter.focus()
        }
        return
      }

      const valueCurrent =
        e.key.length === 1
          ? internalValue.map((ar, index) => (index === i ? e.key : ar))
          : internalValue.map((ar, index) => (index === i ? '' : ar))

      const valueJoin = valueCurrent.join('')
      const dateFormated =
        valueCurrent.filter((ar) => ar !== '').length === 8
          ? moment()
              .set({
                date: parseInt(valueJoin.slice(0, 2), 10),
                month: parseInt(valueJoin.slice(2, 4), 10) - 1,
                year: parseInt(valueJoin.slice(4), 10)
              })
              .format()
          : valueJoin

      if (typeof onChange === 'function') {
        onChange({
          ...e,
          target: {
            ...e.target,
            id: props.id,
            type: 'text',
            name,
            value: dateFormated
          }
        })
      }

      if (typeof setValue === 'function') {
        setValue(dateFormated)
      }

      if ((e.key === 'Backspace' || e.key === 'Delete') && prevCharacter) {
        prevCharacter.focus()
        return
      }

      if (e.key.length === 1 && nextCharacter) {
        nextCharacter.focus()
      }
    },
    [name, internalValue]
  )

  return (
    <SptFlex
      width={{
        web: '100px',
        hd: '120px',
        fullhd: '180px'
      }}
      onClick={setInputFocus}
    >
      {internalValue.map((caracter, i) => (
        <>
          <SptFieldBoxLetterInputStyled
            key={i}
            id={`${name}-${i}`}
            {...props}
            maxLength={1}
            name={`${name}-input`}
            hasBorderRight={i === 7 || i === 1 || i === 3}
            onKeyDown={(e) => handleKeyDown(e, i)}
            disabled={disabled}
            value={caracter}
          />
          {(i === 1 || i === 3) && <SptBox mr="18px" />}
        </>
      ))}
    </SptFlex>
  )
})

SptFieldBoxLetterDate.displayName = 'SptFieldBoxLetterDate'

SptFieldBoxLetterDate.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  hasValue: PropTypes.bool
}
