import React from 'react'

import { SptButton } from './Button'

export const SptCloseButton = (props) => {
  return <SptButton {...props} />
}

SptCloseButton.displayName = 'SptCloseButton'

SptCloseButton.propTypes = {
  ...SptButton.propTypes
}

SptCloseButton.defaultProps = {
  size: 'sm',
  icon: 'spt-close',
  variant: 'text',
  palette: 'neutral'
}
