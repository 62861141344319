import React, { useState, useMemo, useCallback } from 'react'

import { SptIcon } from '../../atoms/Icon'

import { SptField } from '../Field'

export const SptFieldPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleType = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword, setShowPassword])

  const prefix = useMemo(() => <SptIcon>spt-lock</SptIcon>, [])

  const suffix = useMemo(
    () => (
      <SptIcon data-testid="passwordIcon" onClick={toggleType} color="primary">
        {showPassword ? 'spt-visibility' : 'spt-visibility-off'}
      </SptIcon>
    ),
    [showPassword, toggleType]
  )

  const fieldProps = useMemo(
    () => ({
      ...props,
      showPassword,
      suffix,
      prefix
    }),
    [props, showPassword, suffix, prefix]
  )

  return <SptField {...fieldProps} />
}

SptFieldPassword.propTypes = {}

SptFieldPassword.defaultProps = {}
