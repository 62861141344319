import React from 'react'

import createAlignmentPlugin from '@draft-js-plugins/alignment'
import { composeDecorators } from '@draft-js-plugins/editor'
import createFocusPlugin from '@draft-js-plugins/focus'
import createImagePlugin from '@draft-js-plugins/image'
import createLinkifyPlugin from '@draft-js-plugins/linkify'
import createResizeablePlugin from '@draft-js-plugins/resizeable'
import createVideoPlugin from '@draft-js-plugins/video'

import { SptText } from '../../elements/Text'

import { createTablePlugin } from './draft-js-table-plugin'

const linkifyPlugin = createLinkifyPlugin({
  component(props) {
    // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content
    return (
      <SptText
        {...props}
        as="a"
        textDecoration="underline"
        color="blue"
        target="_blank"
        onClick={() => {
          window.open(props.href, '_blank')
        }}
      />
    )
  }
})

export const getPlugins = (setReadOnly = () => {}) => {
  const focusPlugin = createFocusPlugin()
  const resizeablePlugin = createResizeablePlugin()
  const alignmentPlugin = createAlignmentPlugin()
  const decorator = composeDecorators(resizeablePlugin.decorator, alignmentPlugin.decorator, focusPlugin.decorator)
  const imagePlugin = createImagePlugin({ decorator })
  const videoPlugin = createVideoPlugin({ decorator })
  const tablePlugin = createTablePlugin({
    onToggleReadOnly: setReadOnly
  })

  return [linkifyPlugin, videoPlugin, focusPlugin, alignmentPlugin, resizeablePlugin, imagePlugin, tablePlugin]
}
