import React from 'react'

import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptAvatar } from '../Avatar'
import { SptButton } from '../Button'

export const SptProfile = ({ onClickButton, avatar, name, id, domain }) => {
  const { t } = useTranslation()
  return (
    <SptFlex flexDirection="column" alignItems="center">
      <SptAvatar mb={10} size="sm" name={name} image={avatar} bg="primaryLight" />

      <SptText mb={5} fontWeight="bold">
        {name}
        <>#</>
        {id}
      </SptText>

      <SptText mb={10} color="mediumGrey">
        {domain}
      </SptText>

      <SptButton onClick={onClickButton} variant="outlined">
        {t('geral:meuPerfil')}
      </SptButton>
    </SptFlex>
  )
}

SptProfile.displayName = 'SptProfile'

SptProfile.propTypes = {
  onClickButton: PropTypes.func,
  avatar: PropTypes.node,
  name: PropTypes.string,
  id: PropTypes.string,
  domain: PropTypes.string
}

SptProfile.defaultProps = {}
