import React, { useMemo, cloneElement } from 'react'

import PropTypes from 'prop-types'

import { Transition } from 'react-transition-group'

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
}

export const SptAnimationFade = ({ style = {}, children, ...props }) => {
  const { timeout } = props

  const defaultStyle = useMemo(
    () => ({
      transition: `opacity ${timeout}ms ease-in-out`,
      opacity: 0
    }),
    [timeout]
  )

  return (
    <Transition {...props}>
      {(state, childProps) => {
        return cloneElement(children, {
          style: {
            ...style,
            ...(children && children.props ? children.props.style : {}),
            ...defaultStyle,
            ...transitionStyles[state]
          },
          ...childProps
        })
      }}
    </Transition>
  )
}

SptAnimationFade.displayName = 'SptAnimationFade'

SptAnimationFade.propTypes = {
  /**
   * Um unico elemento filho
   */
  children: PropTypes.element,

  /**
   * Se `true`, o componente vai fazer a transição para aparecer
   */
  in: PropTypes.bool
}

SptAnimationFade.defaultProps = {
  timeout: 300
}
