import styled from 'styled-components'

import { SptFlex } from '../Flex'

export const SptRow = styled(SptFlex)``

SptRow.displayName = 'SptRow'

SptRow.propTypes = {
  ...SptFlex.propTypes
}

SptRow.defaultProps = {
  mx: -5,
  flexWrap: 'wrap'
}
