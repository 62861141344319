import React, { useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import { useHotkeys } from '@sponte/lib-utils/dist/hooks/useHotkeys'

import { SptButton } from '../../atoms/Button'

import { SptField } from '../Field'

export const SptFieldSelectHorizontal = ({ value, onChange, items = [], ...props }) => {
  const { id: idProp, name: nameProp, disabled: disabledProp } = props

  const id = useMemo(() => idProp || nameProp, [idProp, nameProp])

  const currentIndex = items.indexOf(value)

  const hasNext = currentIndex < items.length - 1

  const hasPrev = currentIndex > 0

  const handleChange = useCallback(
    (val) => {
      if (onChange) {
        onChange({
          target: {
            id,
            name: nameProp,
            type: 'text',
            value: val
          }
        })
      }
    },
    [id, nameProp, onChange]
  )

  const handleNext = () => {
    if (hasNext) {
      handleChange(items[currentIndex + 1])
    }
  }

  const handlePrev = () => {
    if (hasPrev) {
      handleChange(items[currentIndex - 1])
    }
  }

  const handleKeydown = (key) => {
    switch (key) {
      case 'right':
        handleNext()

        break

      case 'left':
        handlePrev()

        break

      default:
        break
    }
  }

  useHotkeys(handleKeydown, ['right', 'left'], [handleKeydown])

  return (
    <SptField
      readOnly
      type="text"
      value={value}
      prefix={
        <SptButton
          data-testid="prefix"
          size="sm"
          icon="spt-caret-left"
          onClick={handlePrev}
          disabled={disabledProp || !hasPrev}
        />
      }
      suffix={
        <SptButton
          data-testid="suffix"
          size="sm"
          icon="spt-caret-right"
          onClick={handleNext}
          disabled={disabledProp || !hasNext}
        />
      }
      {...props}
    />
  )
}

SptFieldSelectHorizontal.propTypes = {
  value: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired
}

SptFieldSelectHorizontal.defaultProps = {}
