import React, { useCallback } from 'react'

import { ButtonWithToolTip } from './ComponentsStyleds'
import { addTable } from './draft-js-table-plugin'
import { useRichTextContext } from './RichTextAreaContext'

export const SptRichTextAreaContentControlsTable = () => {
  const { editorState, handleEditorState } = useRichTextContext()

  const addTables = useCallback(() => {
    handleEditorState(addTable(editorState, { rows: 5, columns: 5 }))
  }, [handleEditorState, editorState])

  return (
    <ButtonWithToolTip
      tipText="Adicionar tabela"
      size="sm"
      palette="neutral"
      variant="text"
      icon="spt-table-view"
      data-testid="button-add-table"
      onClick={addTables}
    />
  )
}
