import React, { useMemo, cloneElement } from 'react'

import PropTypes from 'prop-types'

import { Transition } from 'react-transition-group'

export const SptAnimationSlideDirections = {
  rightToLeft: {
    entering: { transform: 'translate3d(0%, 0, 0)' },
    entered: { transform: 'translate3d(0%, 0, 0)' },
    exiting: { transform: 'translate3d(100%, 0, 0)' },
    exited: { transform: 'translate3d(100%, 0, 0)' }
  },
  leftToRight: {
    entering: { transform: 'translate3d(0%, 0, 0)' },
    entered: { transform: 'translate3d(0%, 0, 0)' },
    exiting: { transform: 'translate3d(-100%, 0, 0)' },
    exited: { transform: 'translate3d(-100%, 0, 0)' }
  },
  topToBottom: {
    entering: { transform: 'translate3d(0, 0%, 0)' },
    entered: { transform: 'translate3d(0, 0%, 0)' },
    exiting: { transform: 'translate3d(0, -100%, 0)' },
    exited: { transform: 'translate3d(0, -100%, 0)' }
  },
  bottomToTop: {
    entering: { transform: 'translate3d(0, 0%, 0)' },
    entered: { transform: 'translate3d(0, 0%, 0)' },
    exiting: { transform: 'translate3d(0, 100%, 0)' },
    exited: { transform: 'translate3d(0, 100%, 0)' }
  }
}

export const SptAnimationSlide = ({ style = {}, direction, children, ...props }) => {
  const { timeout } = props

  const defaultStyle = useMemo(
    () => ({
      transition: `transform ${timeout}ms ease-in-out`,
      transform: 'translate3d(0%, 0, 0)'
    }),
    [timeout]
  )

  return (
    <Transition {...props}>
      {(state, childProps) => {
        return cloneElement(children, {
          style: {
            ...style,
            ...(children.props && children.props ? children.props.style : {}),
            ...defaultStyle,
            ...SptAnimationSlideDirections[direction][state]
          },
          ...childProps
        })
      }}
    </Transition>
  )
}

SptAnimationSlide.displayName = 'SptAnimationSlide'

SptAnimationSlide.propTypes = {
  /**
   * Um unico elemento filho
   */
  children: PropTypes.element,

  /**
   * Se `true`, o componente vai fazer a transição para aparecer
   */
  in: PropTypes.bool,

  /**
   * Direção da animação
   */
  direction: PropTypes.oneOf(Object.keys(SptAnimationSlideDirections))
}

SptAnimationSlide.defaultProps = {
  timeout: 300,
  direction: 'leftToRight'
}
