import React, { useMemo, forwardRef } from 'react'

import { useMedia } from '@sponte/lib-utils/dist/hooks/useMedia'
import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { SptBox } from '../../elements/Box'

export const SptCard = forwardRef((props, ref) => {
  const theme = useTheme()
  const isSmall = useMedia(theme.mediaQueries.down.tablet, true, false)

  const borderRadius = useMemo(() => {
    return isSmall && props.checkBorderMobile ? 0 : 'sm'
  }, [isSmall, props.checkBorderMobile])

  return <SptBox ref={ref} {...props} borderRadius={borderRadius} />
})

SptCard.displayName = 'SptCard'

SptCard.propTypes = {
  ...SptBox.propTypes
}

SptCard.defaultProps = {
  width: 1,
  boxShadow: 2,
  borderRadius: 'sm',
  bg: 'white',
  checkBorderMobile: false
}
