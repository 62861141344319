import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { move } from '@sponte/lib-utils/dist/helpers/array'

import { SptBox } from '../../elements/Box'

export const SptDragList = ({ children, order, setOrder, ...props }) => {
  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return
      }

      if (
        result.destination.droppableId === result.source.droppableId &&
        result.destination.index === result.source.index
      ) {
        return
      }

      setOrder(move(result.source.index, result.destination.index, order))
    },
    [order]
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <SptBox {...provided.droppableProps} ref={provided.innerRef} {...props}>
            {typeof children === 'function' ? children(provided, snapshot) : children}
            {provided.placeholder}
          </SptBox>
        )}
      </Droppable>
    </DragDropContext>
  )
}

SptDragList.displayName = 'SptDragList'

SptDragList.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  order: PropTypes.array.isRequired,
  setOrder: PropTypes.func.isRequired
}

SptDragList.defaultProps = {}
