import React, { memo } from 'react'

import { RichUtils } from 'draft-js'

import { ButtonWithToolTip } from './ComponentsStyleds'
import { useRichTextContext } from './RichTextAreaContext'

const INLINE_STYLES = [
  {
    icon: 'spt-bold',
    style: 'BOLD',
    tipText: 'Bold'
  },
  {
    icon: 'spt-italic',
    style: 'ITALIC',
    tipText: 'Itálico'
  },
  {
    icon: 'spt-underline',
    style: 'UNDERLINE',
    tipText: 'Sublinhado'
  }
]

export const SptRichTextAreaContentControlsInlineStyle = memo(() => {
  const { editorState, handleEditorState } = useRichTextContext()

  const currentInlineStyle = editorState.getCurrentInlineStyle()

  const handleToggleInlineStyle = (inlineStyle) => (e) => {
    e.preventDefault()

    handleEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  return INLINE_STYLES.map((inlineStyle) => (
    <ButtonWithToolTip
      key={inlineStyle.style}
      size="sm"
      tipText={inlineStyle.tipText}
      icon={inlineStyle.icon}
      variant="text"
      palette={currentInlineStyle.has(inlineStyle.style) ? 'primary' : 'neutral'}
      onMouseDown={handleToggleInlineStyle(inlineStyle.style)}
    />
  ))
})

SptRichTextAreaContentControlsInlineStyle.displayName = 'SptRichTextAreaContentControlsInlineStyle'

SptRichTextAreaContentControlsInlineStyle.propTypes = {}

SptRichTextAreaContentControlsInlineStyle.defaultProps = {}
