import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { space } from 'styled-system'

import { theme, switchProp, ifProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptFlex } from '../../elements'
import { SptText } from '../../elements/Text'

import { SptAvatar } from '../Avatar'
import { SptIcon } from '../Icon'
import { SptLoading } from '../Loading/Loading'

export const SptMenuItemVariants = {
  normal: css`
    background-color: transparent;
    cursor: pointer;
    color: ${theme('colors.almostBlack')};

    :hover {
      background-color: ${theme('colors.lightGrey')};
    }
  `,

  header: css`
    background-color: transparent;
    &:not(:first-child) {
      border-top: 2px solid ${theme('colors.lightGrey')};
    }
    span {
      font-size: 12px;
      color: ${theme('colors.darkGrey')};
    }
  `
}

const SptMenuItemStyled = styled.button`
  ${space};
  width: 100%;
  border: none;
  text-align: left;

  display: flex;
  align-items: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    cursor: pointer;
  }

  ${switchProp('variant', SptMenuItemVariants)};

  ${ifProp(
    'active',
    css`
      background-color: ${theme('colors.lightGrey')};
    `
  )}

  ${ifProp(
    'selected',
    css`
      span {
        font-weight: ${theme('fontWeights.bold')};
      }
    `
  )}

  ${ifProp(
    'disabled',
    css`
      pointer-events: none;
      background-color: ${theme('colors.white')} !important;

      svg,
      span {
        color: ${theme('colors.darkGrey')} !important;
      }
    `
  )}
`

SptMenuItemStyled.displayName = 'SptMenuItemStyled'

export const SptMenuItem = forwardRef(
  ({ role: roleBase, icon, iconColor, avatar, children, isLoading, ...props }, ref) => {
    let role

    if (roleBase) {
      role = roleBase
    } else if (props.variant === 'normal') {
      role = 'menuitem'
    } else {
      role = 'menuheader'
    }

    return (
      <SptMenuItemStyled ref={ref} py={5} px={6} type="button" role={role} data-testid={props.id} {...props}>
        {avatar && (
          <SptFlex flexShrink={1}>
            <SptAvatar size="sm" bg="lightGrey" mr={10} {...avatar} />
          </SptFlex>
        )}

        {isLoading && <SptLoading size="sm" mr={9} />}

        {icon && !avatar && !isLoading ? (
          <SptIcon color={iconColor} mr={6} size="sm">
            {icon}
          </SptIcon>
        ) : null}

        <SptText fontWeight="medium" color={isLoading ? 'mediumGrey' : 'almostBlack'} truncate>
          {children}
        </SptText>
      </SptMenuItemStyled>
    )
  }
)

SptMenuItem.displayName = 'SptMenuItem'

SptMenuItem.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(SptMenuItemVariants)).isRequired
}

SptMenuItem.defaultProps = {
  variant: 'normal',
  iconColor: 'darkGrey'
}
