import React from 'react'

import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { sortEvents } from '@sponte/lib-utils/src/helpers/sortEvents'

import { DailyEventsContainer, DayNumberContainer, EventsContainer, DayEvents, Event } from './styles'

export const SptBigCalendarDaysWithEvents = React.memo(({ eventsArray = [], isToday, children }) => {
  const theme = useTheme()

  const renderEvents = React.useCallback(() => {
    return sortEvents(eventsArray).map((event, index) => {
      if (index <= 3) {
        return (
          <DayEvents key={index + 1}>
            <Event color={theme.colors[event.cor]} />
          </DayEvents>
        )
      }
      if (index === 4) {
        return (
          <DayEvents key={index + 1}>
            <Event color={theme.colors.mediumGrey} />
          </DayEvents>
        )
      }

      return <> </>
    })
  })

  return (
    <DailyEventsContainer>
      <DayNumberContainer isToday={isToday}>{children}</DayNumberContainer>
      <EventsContainer>{renderEvents()}</EventsContainer>
    </DailyEventsContainer>
  )
})
