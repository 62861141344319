import React, { useRef, useLayoutEffect, useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import Transition from 'react-transition-group/Transition'

import { callAll } from '@sponte/lib-utils/dist/helpers/callAll'

import { ifProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'
import { SptFlex } from '../../elements/Flex'

const duration = 400

const SptCollapseStyled = styled(SptBox)`
  overflow: hidden;
  transition: height ${duration}ms cubic-bezier(0.075, 0.82, 0.165, 1);
  height: ${ifProp({ state: 'entered' }, 'auto', '0')};
`

SptCollapseStyled.displayName = 'SptCollapseStyled'

export const SptCollapse = forwardRef(
  ({ in: inProp, children, style, onEnter, onEntering, onEntered, onExit, onExiting, onExited, ...props }, ref) => {
    const timeoutRef = useRef(null)
    const wrapperRef = useRef(null)

    useLayoutEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
      }
    }, [])

    const getWrapperHeight = useCallback(() => {
      return wrapperRef.current.clientHeight
    }, [wrapperRef])

    const handleShow = useCallback(
      (node) => {
        const wrapperHeight = getWrapperHeight()
        // eslint-disable-next-line no-param-reassign
        node.style.height = `${wrapperHeight}px`
      },
      [getWrapperHeight]
    )

    const handleHide = useCallback(
      (node) => {
        timeoutRef.current = setTimeout(() => {
          // eslint-disable-next-line no-param-reassign
          node.style.height = '0px'
        }, 0)
      },
      [timeoutRef]
    )

    const handleShowing = useCallback((node) => {
      // eslint-disable-next-line no-param-reassign
      node.style.height = `auto`
    }, [])

    return (
      <Transition
        onEnter={callAll(handleShow, onEnter)}
        onEntering={callAll(handleShow, onEntering)}
        onEntered={callAll(handleShowing, onEntered)}
        onExit={callAll(handleShow, onExit)}
        onExiting={callAll(handleHide, onExiting)}
        onExited={callAll(onExited)}
        in={inProp}
        timeout={duration}
        appear
      >
        {(state, childProps) => {
          return (
            <SptCollapseStyled ref={ref} state={state} style={style} {...childProps} {...props}>
              <div ref={wrapperRef}>
                <SptFlex>
                  <SptBox width={1}>{children}</SptBox>
                </SptFlex>
              </div>
            </SptCollapseStyled>
          )
        }}
      </Transition>
    )
  }
)

SptCollapse.displayName = 'SptCollapse'

SptCollapse.propTypes = {
  in: PropTypes.bool
}

SptCollapse.defaultProps = {
  in: false
}
