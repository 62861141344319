import React, { memo } from 'react'

import PropTypes from 'prop-types'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptAvatar } from '../Avatar'
import { SptCardHeader } from '../Card'

export const SptPageFormHeader = memo(({ code, title, image, headerActions, tabs }) => {
  return (
    <SptCardHeader position="relative" flexDirection="column">
      <SptFlex
        px={15}
        pt={15}
        pb={8}
        alignItems={{
          _: 'center',
          web: 'inherit'
        }}
        flexDirection={{
          _: 'column',
          web: 'row'
        }}
      >
        <SptAvatar size="lg" image={image} icon="spt-camera" bg="mediumGrey" />

        <SptFlex
          flex={1}
          mx={15}
          flexDirection="column"
          alignItems={{
            _: 'center',
            web: 'flex-start'
          }}
          mt={{
            _: 15,
            web: 0
          }}
        >
          <SptText color="primary" fontWeight="bold" mb={5}>
            <>#</>
            {code}
          </SptText>

          <SptText color="almostBlack" fontWeight="bold" fontSize="xlarge" textAlign="center" mb={7}>
            {title}
          </SptText>

          {headerActions && <SptFlex>{headerActions}</SptFlex>}
        </SptFlex>
      </SptFlex>

      {tabs && <SptFlex>{tabs}</SptFlex>}
    </SptCardHeader>
  )
})

SptPageFormHeader.propTypes = {
  code: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  tabs: PropTypes.node,
  headerActions: PropTypes.node
}

SptPageFormHeader.defaultProps = {}
