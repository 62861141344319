import React from 'react'

import styled, { css } from 'styled-components'

import PerfectScrollbar from '@opuscapita/react-perfect-scrollbar'

import { theme, prop, ifProp } from '@sponte/lib-utils/dist/theme/tools'

export const SptScrollbarStyled = styled(PerfectScrollbar)`
  ${ifProp(
    'maxHeight',
    css`
      max-height: ${prop('maxHeight')}px;
    `
  )};

  flex: 1;

  .ps__rail-x {
    height: ${theme('space.5')}px;
  }

  .ps__rail-y {
    width: ${theme('space.5')}px;
  }

  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    opacity: 0.8;
    background-color: ${theme('colors.mediumGrey')};
  }

  .ps__thumb-x {
    bottom: 1px;
    height: ${theme('space.4')}px;
    border-radius: ${theme('radii.1')}px;
    background-color: ${theme('colors.almostBlack')};
  }

  .ps__thumb-y {
    right: 1px;
    width: ${theme('space.4')}px;
    border-radius: ${theme('space.3')}px;
    background-color: ${theme('colors.almostBlack')};
  }

  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    height: ${theme('space.4')}px;
    background-color: ${theme('colors.almostBlack')};
  }

  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    width: ${theme('space.4')}px;
    background-color: ${theme('colors.almostBlack')};
  }
`

SptScrollbarStyled.displayName = 'SptScrollbarStyled'

export const SptScrollbar = (props) => {
  return <SptScrollbarStyled {...props} />
}

SptScrollbar.displayName = 'SptScrollbar'

SptScrollbar.propTypes = {}

SptScrollbar.defaultProps = {}
