import { useEffect } from 'react'

import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

export const SptFormDisableAllFields = ({ disableAllForm = false }) => {
  const { setStatus } = useFormikContext()

  useEffect(() => {
    if (disableAllForm) {
      setStatus('DISABLE_ALL_FORM')
    } else {
      setStatus(null)
    }
  }, [disableAllForm])

  return null
}

SptFormDisableAllFields.displayName = 'SptFormDisableAllFields'

SptFormDisableAllFields.propTypes = {
  disableAllForm: PropTypes.bool
}

SptFormDisableAllFields.defaultProps = {}
