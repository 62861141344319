import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { usePrompt } from '@sponte/lib-utils/dist/hooks/usePrompt'

export const SptFormDirtyBlockNavigation = ({ message = 'geral:feedbacks.desejaContinuar' }) => {
  const { t } = useTranslation()

  const { dirty, touched } = useFormikContext()
  usePrompt(dirty && Object.keys(touched).length, t(message))

  return null
}

SptFormDirtyBlockNavigation.displayName = 'SptFormDirtyBlockNavigation'

SptFormDirtyBlockNavigation.propTypes = {
  message: PropTypes.string
}

SptFormDirtyBlockNavigation.defaultProps = {}
