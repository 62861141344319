import React, { memo, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import createVideoPlugin from '@draft-js-plugins/video'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { useDisclosure } from '@sponte/lib-utils/dist/hooks/useDisclosure'
import { useOnClickOutside } from '@sponte/lib-utils/dist/hooks/useOnClickOutside'
import { usePopper } from '@sponte/lib-utils/dist/hooks/usePopper'

import { SptButton } from '../../atoms/Button'
import { SptCard } from '../../atoms/Card'
import { SptPortal } from '../../atoms/Portal'

// eslint-disable-next-line import/no-cycle
import { SptFieldFormik } from '../FieldFormik'

import { ButtonWithToolTip } from './ComponentsStyleds'
import { useRichTextContext } from './RichTextAreaContext'

export const SptRichTextAreaContentControlsVideo = memo(() => {
  const { t } = useTranslation()

  const { handleEditorState, editorState, focusEditor } = useRichTextContext()

  const modifier = useMemo(() => ({ video: createVideoPlugin().addVideo }), [])

  const { styles, popper, referrence, popperNode, referrenceNode } = usePopper()
  const { isOpen, onOpen, onClose } = useDisclosure({
    reducer: (_, { type }, changes) => {
      if (type === useDisclosure.types.close) {
        setTimeout(() => focusEditor(), 300)
      }

      return changes
    }
  })

  useOnClickOutside(
    [referrenceNode, popperNode],
    () => {
      if (isOpen) {
        onClose()
      }
    },
    [referrenceNode, popperNode]
  )

  const validationSchema = useMemo(() => {
    return Yup.object({
      url: Yup.string().url(t('geral:validacoes.urlIncorreta')).required(t('geral:validacoes.obrigatorio'))
    })
  }, [t])

  const [initialValues, setInitialValues] = React.useState({
    url: ''
  })

  const promptForLink = (e) => {
    e.preventDefault()

    const selection = editorState.getSelection()

    if (selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent()
      const startKey = editorState.getSelection().getStartKey()
      const startOffset = editorState.getSelection().getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

      let url = ''
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        url = linkInstance.getData().url
      }

      setInitialValues({
        url
      })

      onOpen()
    }
  }

  const confirmLink = (values, formik) => {
    handleEditorState(modifier(editorState, { src: values.url }))

    formik.resetForm({ values: {} })

    setInitialValues({})

    onClose()

    setTimeout(() => focusEditor(), 300)
  }

  return (
    <>
      <ButtonWithToolTip
        ref={referrence}
        size="sm"
        tipText="Inserir vídeo"
        // TODO: ASK FOR NEW VIDEO ICON FOR BETTER UX/AFFORDANCE
        icon="spt-link"
        variant="text"
        palette="neutral"
        onMouseDown={promptForLink}
      />

      {isOpen && (
        <SptPortal>
          <SptCard ref={popper} style={styles} width={280} zIndex={1300} p={8}>
            <Formik
              onSubmit={confirmLink}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur={false}
            >
              <Form noValidate>
                <Field label={t('geral:videoUrl')} name="url" autoFocus required component={SptFieldFormik} />

                <SptButton type="submit" mt={4} block>
                  {t('geral:adicionar')}
                </SptButton>
              </Form>
            </Formik>
          </SptCard>
        </SptPortal>
      )}
    </>
  )
})

SptRichTextAreaContentControlsVideo.displayName = 'SptRichTextAreaContentControlsVideo'

SptRichTextAreaContentControlsVideo.propTypes = {}

SptRichTextAreaContentControlsVideo.defaultProps = {}
