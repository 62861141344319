import React, { useState, memo, forwardRef, useEffect } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { theme, fontSize, prop, ifProp } from '@sponte/lib-utils/dist/theme/tools'

const SptTextareaStyled = styled.textarea`
  border: 0;
  color: ${theme('colors.almostBlack')};
  ${fontSize('default')};
  font-family: ${theme('fonts.default')};
  background-color: transparent;
  font-weight: 500;
  border: none;
  resize: none;
  width: 100%;
  height: 100%;

  ${ifProp(
    'height',
    css`
      height: ${prop('height')};
    `
  )}

  ${ifProp(
    'hiddenOverflow',
    css`
      overflow-y: hidden;
    `
  )}

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    top: 10px;
    background-color: ${theme('colors.lightGrey')};
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme('colors.mediumGrey')};
    border-radius: ${theme('radii.lg')}px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ::placeholder {
    color: ${theme('colors.darkGrey')};
  }

  ::autofill {
    background-color: transparent;
  }
`

SptTextareaStyled.displayName = 'SptTextareaStyled'

export const SptTextarea = memo(
  forwardRef(({ minRows, maxRows, onChange, value, inputProps, ...props }, ref) => {
    const [textareaHeight, setTextareaHeight] = useState(`auto`)
    const [rows, setRows] = useState(
      maxRows !== 'auto' || (value || '').split(/\n/).length < minRows ? minRows : (value || '').split(/\n/).length
    )

    useEffect(() => {
      setTextareaHeight(`${getSafe(inputProps, 'current.scrollHeight')}px`)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (value === '') {
        setTextareaHeight(`auto`)
      }
    }, [value])

    const handleChange = (e) => {
      const qtdRowsWrited = e.target.value.split(/\n/).length

      if (maxRows === 'auto') {
        setTextareaHeight(`${getSafe(inputProps, 'current.scrollHeight')}px`)
      } else if (qtdRowsWrited > maxRows) {
        setRows(maxRows)
      } else if (qtdRowsWrited < minRows) {
        setRows(minRows)
      } else {
        setRows(qtdRowsWrited)
      }

      if (onChange) {
        onChange(e)
      }
    }

    if (maxRows === 'auto') {
      return (
        <SptTextareaStyled
          ref={ref}
          height={textareaHeight}
          rows={1}
          onChange={handleChange}
          value={value}
          hiddenOverflow
          {...props}
        />
      )
    }

    return <SptTextareaStyled ref={ref} rows={rows} onChange={handleChange} value={value} {...props} />
  })
)

SptTextarea.displayName = 'SptTextarea'

SptTextarea.propTypes = {
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  onChange: PropTypes.func
}

SptTextarea.defaultProps = {
  minRows: 1,
  maxRows: 10
}
