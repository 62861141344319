import React, { Suspense } from 'react'

import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'react-snackbar-alert'

import '@sponte/lib-utils/dist/yup-validations'

import createTheme from '@sponte/lib-themes/dist/medplus'
import { SptThemeProvider } from '@sponte/lib-themes/dist/ThemeProvider'

import { SptGlobalCss } from '@sponte/lib-components/dist/elements/GlobalCss'

import { SptLoading } from '@sponte/lib-components/dist/atoms/Loading'
import { SptSnackbar } from '@sponte/lib-components/dist/atoms/Snackbar'

import i18n from 'i18n'

// eslint-disable-next-line
import { ReactQueryProvider } from 'providers/reactQuery'

// configura o tema padrão do projeto
const theme = createTheme({
  logo: '/assets/logo.svg',
  illustration: '/assets/ilustracao.svg',
  notFound: '/assets/notFound.svg',
  notFoundPage: '/assets/notFoundPage.svg',
  backgroundFreeTest: '/assets/backgroundFreeTest.svg'
})

const Providers = ({ children }) => {
  return (
    <SptThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <SnackbarProvider
          timeout={3000}
          sticky={false}
          dismissable={false}
          position="bottom-left"
          component={SptSnackbar}
        >
          <SptGlobalCss />

          <Suspense fallback={<SptLoading full size="xl" position="absolute" />}>
            <ReactQueryProvider>
              <BrowserRouter>{children}</BrowserRouter>
            </ReactQueryProvider>
          </Suspense>
        </SnackbarProvider>
      </I18nextProvider>
    </SptThemeProvider>
  )
}

export default Providers
