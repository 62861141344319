import React from 'react'

import { useTranslation } from 'react-i18next'

import { useDisclosure } from '@sponte/lib-utils/src/hooks/useDisclosure'

import { SptMenuItem, SptMenuItemDivider } from '@sponte/lib-components/dist/atoms/MenuItem'
import { SptMenuList } from '@sponte/lib-components/dist/atoms/MenuList'

import InputCell from './InputCell'

export default ({
  hasEditOptions,
  value,
  label,
  onChange,
  onToggleReadOnly,
  onRowAddBefore,
  onRowDelete,
  onRowAddAfter
}) => {
  const { t } = useTranslation()

  const drawerTagsProps = useDisclosure()

  return (
    <td
      data-value={value.trim()}
      data-label={label.trim()}
      style={{ verticalAlign: 'middle', border: '1px solid', position: 'relative' }}
    >
      <InputCell
        onChange={onChange}
        value={value}
        onToggleReadOnly={onToggleReadOnly}
        drawerTagsProps={drawerTagsProps}
        render={({ showEditOptions }) => {
          return (
            showEditOptions && (
              <SptMenuList position="absolute" zIndex={9} minWidth="max-content">
                {hasEditOptions && (
                  <>
                    <SptMenuItem icon="spt-arrow-up" onClick={onRowAddBefore}>
                      {t('geral:adicionarLinhaAcima')}
                    </SptMenuItem>

                    <SptMenuItem icon="spt-delete" onClick={onRowDelete}>
                      {t('geral:removerLinha')}
                    </SptMenuItem>

                    <SptMenuItem icon="spt-arrow-down" onClick={onRowAddAfter}>
                      {t('geral:adicionarLinhaAbaixo')}
                    </SptMenuItem>

                    <SptMenuItemDivider />
                  </>
                )}

                <SptMenuItem
                  icon="spt-add-tag"
                  onClick={() => {
                    drawerTagsProps.onOpen()
                  }}
                >
                  {t('geral:adicionarTag')}
                </SptMenuItem>
              </SptMenuList>
            )
          )
        }}
      />
    </td>
  )
}
