export const COLORS = [
  'white',
  'lightGrey',
  'mediumGrey',
  'darkGrey',
  'almostBlack',
  'black',
  'primary',
  'primaryLight',
  'primaryDark',
  'secondary',
  'secondaryLight',
  'secondaryDark',
  'info',
  'infoLight',
  'infoDark',
  'success',
  'successLight',
  'successDark',
  'warning',
  'warningLight',
  'warningDark',
  'error',
  'errorLight',
  'errorDark',
  'red',
  'redLight',
  'redDark',
  'pink',
  'pinkLight',
  'pinkDark',
  'purple',
  'purpleLight',
  'purpleDark',
  'deepPurple',
  'deepPurpleLight',
  'deepPurpleDark',
  'indigo',
  'indigoLight',
  'indigoDark',
  'blue',
  'blueLight',
  'blueDark',
  'cyan',
  'cyanLight',
  'cyanDark',
  'teal',
  'tealLight',
  'tealDark',
  'green',
  'greenLight',
  'greenDark',
  'lime',
  'limeLight',
  'limeDark',
  'yellow',
  'yellowLight',
  'yellowDark',
  'orange',
  'orangeLight',
  'orangeDark',
  'deepOrange',
  'deepOrangeLight',
  'deepOrangeDark',
  'brown',
  'brownLight',
  'brownDark'
]

export const PALETTES = [
  'neutral',
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
  'red',
  'pink',
  'purple',
  'deepPurple',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'green',
  'lime',
  'yellow',
  'orange',
  'deepOrange',
  'brown',
  'almostBlack'
]

export const COMMON_PALETTES = [
  'neutral',
  'red',
  'pink',
  'purple',
  'deepPurple',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'green',
  'lime',
  'yellow',
  'orange',
  'deepOrange',
  'brown',
  'almostBlack'
]

export const CALENDAR_EVENTS = [
  'red',
  'redLight',
  'redDark',
  'pink',
  'pinkLight',
  'pinkDark',
  'purple',
  'purpleLight',
  'purpleDark',
  'deepPurple',
  'deepPurpleLight',
  'deepPurpleDark',
  'indigo',
  'indigoLight',
  'indigoDark',
  'blue',
  'blueLight',
  'blueDark',
  'cyan',
  'cyanLight',
  'cyanDark',
  'teal',
  'tealLight',
  'tealDark',
  'green',
  'greenLight',
  'greenDark',
  'lime',
  'limeLight',
  'limeDark',
  'yellow',
  'yellowLight',
  'yellowDark',
  'orange',
  'orangeLight',
  'orangeDark',
  'deepOrange',
  'deepOrangeLight',
  'deepOrangeDark',
  'brown',
  'brownLight',
  'brownDark'
]

export const FONTS = ['Montserrat', 'Roboto', 'Sans-serif']

export const FONTSIZES = [10, 12, 14, 16, 24]
