import styled, { css } from 'styled-components'

import { ifProp, theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptInput } from '../../atoms/Input'

export const SptFieldBoxLetterInputStyled = styled(SptInput)`
  height: 18px !important;
  background-color: transparent;
  border-bottom: 1px solid ${theme('colors.mediumGrey')};
  border-left: 1px solid ${theme('colors.mediumGrey')}};
  text-align: center;
  ${ifProp(
    'hasBorderRight',
    css`
      border-right: 1px solid ${theme('colors.mediumGrey')}};
    `
  )};
  ${ifProp(
    'error',
    css`
      border-color: ${theme('colors.error')} !important;
    `
  )};
`
