import PropTypes from 'prop-types'

import styled from 'styled-components'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptCard } from '../Card'

export const SptPage = styled(SptCard)`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  border-radius: 0;

  ${theme('mediaQueries.down.web')} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

SptPage.displayName = 'SptPage'

SptPage.propTypes = {
  children: PropTypes.node
}

SptPage.defaultProps = {}
