import React from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

import { ifProp, theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'

import { SptIcon } from '../../atoms/Icon'

const Wrapper = styled(SptBox)`
  .slick-slider {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .slick-slider ul {
    flex: 1 0 100%;
    display: flex !important;
    justify-content: center;
  }

  .slick-list {
    flex: 1;
  }

  .slick-track {
    width: ${ifProp('infinite', '100%', '100% !important')};
    display: flex;
  }

  .slick-dots {
    width: 100%;
    position: absolute;
    display: flex;
    bottom: -20px;
    justify-content: center;

    :not(.slick-active) {
      svg {
        color: ${theme('colors.mediumGrey')};
      }
    }

    .slick-active {
      svg {
        color: ${theme('colors.darkGrey')};
      }
    }
  }
`

export const SptSlider = ({ children, ...props }) => {
  const { infinite } = props

  return (
    <Wrapper width={1} infinite={infinite} alignItems="center">
      <Slider {...props}>{children}</Slider>
    </Wrapper>
  )
}

SptSlider.propTypes = {
  children: PropTypes.node,
  slidesToScroll: PropTypes.number,
  slidesToShow: PropTypes.number,
  variableWidth: PropTypes.bool,
  nextArrow: PropTypes.node,
  prevArrow: PropTypes.node,
  arrows: PropTypes.bool,
  dots: PropTypes.bool
}

SptSlider.defaultProps = {
  slidesToScroll: 1,
  slidesToShow: 1,
  variableWidth: true,
  customPaging: () => (
    <SptIcon fontSize="7px" mx={1}>
      spt-fiber-manual-record
    </SptIcon>
  ),
  arrows: false,
  infinite: false
}
