import React from 'react'

import RowCell from './RowCell'

export default ({ block, blockProps, columns, isOnlyRow, onToggleReadOnly, row, rowIndex }) => {
  const onChangeCell = ({ row: rowChangeCell, cell }) => (value) => {
    blockProps.setEditorState(
      blockProps.editCell({
        row: rowChangeCell,
        cell: { ...cell, value },
        editorState: blockProps.getEditorState(),
        block
      })
    )
  }

  const removeRow = ({ index }) => () => {
    blockProps.setEditorState(
      blockProps.removeRow({
        index,
        rows: blockProps.rows,
        columns: blockProps.columns,
        editorState: blockProps.getEditorState(),
        block
      })
    )
  }

  const addRow = ({ index }) => () => {
    blockProps.setEditorState(
      blockProps.addRow({
        index,
        rows: blockProps.rows,
        columns: blockProps.columns,
        editorState: blockProps.getEditorState(),
        block
      })
    )
  }

  return (
    <tr>
      {row.value.map((cell, i) => (
        <RowCell
          key={cell.key}
          hasEditOptions={i === 0}
          value={cell.value}
          label={columns[i].value}
          onToggleReadOnly={onToggleReadOnly}
          onChange={onChangeCell({ row, cell })}
          onRowAddBefore={addRow({ index: rowIndex })}
          onRowDelete={isOnlyRow ? null : removeRow({ index: rowIndex })}
          onRowAddAfter={addRow({ index: rowIndex + 1 })}
        />
      ))}
    </tr>
  )
}
