import React, { useRef } from 'react'

import moment from 'moment'
import useInputMask from 'use-input-mask'

import { INPUT_DATE_MONTH_YEAR_MASK } from '@sponte/lib-utils/dist/helpers/masks'

import { SptField } from '../Field'

export const SptFieldDateCreditCard = ({ value = '', onChange, ...props }) => {
  const inputRef = useRef(null)

  const dateMask = 'MM/YY'

  const internalValue =
    value && moment(value, dateMask, true).isValid() ? moment(value, dateMask, true).format(dateMask) : value

  const handleOnChange = useInputMask({
    input: inputRef,
    onChange: (e) => {
      if (!onChange || typeof onChange !== 'function') {
        return
      }
      if (moment(e.target.value, dateMask, true).isValid()) {
        onChange({
          ...e,
          target: {
            ...e.target,
            id: props.id,
            type: 'text',
            name: props.name,
            value: moment(e.target.value, dateMask).format(dateMask)
          }
        })
      } else {
        onChange(e)
      }
    },
    mask: INPUT_DATE_MONTH_YEAR_MASK,
    guide: false,
    keepCharPositions: true,
    initialValue: internalValue
  })

  return <SptField inputRef={inputRef} type="text" value={internalValue} onChange={handleOnChange} {...props} />
}

SptFieldDateCreditCard.displayName = 'SptFieldDateCreditCard'

SptFieldDateCreditCard.propTypes = {}

SptFieldDateCreditCard.defaultProps = {}
