import React from 'react'

import { callAllEventHandlers } from '@sponte/lib-utils/dist/helpers/callAll'

import { useFocus } from '@sponte/lib-utils/dist/hooks/useFocus'
import { usePopper } from '@sponte/lib-utils/dist/hooks/usePopper'

import { SptBox } from '../../elements/Box'

import { SptFieldPassword } from '../FieldPassword'
import { SptPasswordStrength } from '../PasswordStrength'

export const SptFieldPasswordStrength = ({ onFocus, onBlur, ...props }) => {
  const [isFocused, binds] = useFocus()

  const focusBinds = {
    onFocus: callAllEventHandlers(binds.onFocus, onFocus),
    onBlur: callAllEventHandlers(binds.onBlur, onBlur)
  }

  const { referrence, popper, styles } = usePopper({
    placement: 'bottom-start'
  })

  return (
    <SptBox width={1} position="relative">
      <SptBox width={1} ref={referrence}>
        <SptFieldPassword {...props} {...focusBinds} />
      </SptBox>

      {isFocused && (
        <SptBox
          width={1}
          zIndex={10}
          {...{
            ref: popper,
            style: {
              ...styles,
              top: styles.top - 18
            }
          }}
        >
          <SptPasswordStrength {...props} />
        </SptBox>
      )}
    </SptBox>
  )
}

SptFieldPasswordStrength.displayName = 'SptFieldPasswordStrength'

SptFieldPasswordStrength.propTypes = {}

SptFieldPasswordStrength.defaultProps = {}
