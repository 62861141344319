import React, { memo, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { useMedia } from '@sponte/lib-utils/dist/hooks/useMedia'
import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { SptFlex } from '@sponte/lib-components/dist/elements/Flex/Flex'
import { SptText } from '@sponte/lib-components/dist/elements/Text/Text'

import { SptIcon } from '@sponte/lib-components/dist/atoms/Icon/Icon'
import { SptScrollbar } from '@sponte/lib-components/dist/atoms/Scrollbar/Scrollbar'

import { CadastroTesteGratisContext } from './CadastroTesteGratisContext'
import { CadastroTesteGratisStepper } from './CadastroTesteGratisStepper'
import { CadastroTesteGratisWrapperMobile } from './CadastroTesteGratisWrapperMobile'
import { CadastroTesteGratisWrapperWeb } from './CadastroTesteGratisWrapperWeb'

const CadastroTesteGratisFinishComp = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmall = useMedia(theme.mediaQueries.down.tablet, true, false)

  const { newUser } = useContext(CadastroTesteGratisContext)

  return (
    <SptScrollbar>
      <SptFlex flex={1} justifyContent="center" my={isSmall ? 10 : 16}>
        <SptFlex flex={1} flexDirection="column" alignItems="center" p={isSmall ? 5 : 10}>
          <SptFlex width={3 / 5} justifyContent="center">
            <img src={theme.assets.logo} alt="medplus" height={80} />
          </SptFlex>

          <SptFlex
            border={`10px solid ${theme.colors.primary}`}
            borderRadius="full"
            height={120}
            width={120}
            mt={isSmall ? 10 : 17}
            justifyContent="center"
            alignItems="center"
          >
            <SptIcon color="primary" fontSize={100}>
              spt-done
            </SptIcon>
          </SptFlex>

          <SptText color="secondary" fontWeight="bold" fontSize="xlarge" mt={isSmall ? 10 : 17} textAlign="center">
            {`${t('geral:obrigado')}, ${newUser}`}
          </SptText>

          <SptText color="primary" fontWeight="bold" fontSize="large" mt={5}>
            {t('cadastro:suaBaseFoiCriada')}
          </SptText>

          <SptText color="secondary" fontWeight="bold" fontSize="large" mt={16} textAlign="center">
            {t('cadastro:acesseSeuEmail')}
          </SptText>
          <SptFlex
            flex={1}
            mt={isSmall ? 15 : 17}
            ml={-10}
            justifyContent="space-between"
            alignItems="center"
            flexDirection={isSmall ? 'column' : 'row'}
          >
            <CadastroTesteGratisStepper step={2} setStep={() => {}} finishedStep />
            <SptText color="primary" fontWeight="bold" fontSize="large">
              {t('cadastro:nosVemosEmBreve')}
            </SptText>
          </SptFlex>
        </SptFlex>
      </SptFlex>
    </SptScrollbar>
  )
}

export const CadastroTesteGratisFinish = memo(() => {
  const theme = useTheme()
  const isSmall = useMedia(theme.mediaQueries.down.tablet, true, false)

  if (isSmall) {
    return (
      <CadastroTesteGratisWrapperMobile>
        <CadastroTesteGratisFinishComp />
      </CadastroTesteGratisWrapperMobile>
    )
  }

  return (
    <CadastroTesteGratisWrapperWeb>
      <CadastroTesteGratisFinishComp />
    </CadastroTesteGratisWrapperWeb>
  )
})
