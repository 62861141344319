import React, { useRef, useCallback, useEffect } from 'react'

import useInputMask from 'use-input-mask'

import { INPUT_FLOAT_MASK } from '@sponte/lib-utils/dist/helpers/masks'
import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { SptField } from '../Field'

export const SptFieldNumberFloat = ({ value: valueProp, name, onChange, onBlur, withoutMask, ...props }) => {
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.value = valueProp ? valueProp.toString().replace('.', ',') : ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleKeyDown = (e) => {
    if (withoutMask) {
      if (e.keyCode === 188 || e.keyCode === 190) {
        inputRef.current.value = `${inputRef.current.value.replaceAll(',', '')}`
      }
    } else if (e.keyCode === 190) {
      inputRef.current.value = `${inputRef.current.value},`
    }
  }

  const handleOnChangeWithoutMask = (e) => {
    onChange({
      ...e,
      target: {
        ...e.target,
        id: name,
        name,
        value: !inputRef.current.value ? '' : inputRef.current.value.replace(',', '.').replaceAll(/[a-zA-Z]/g, '')
      }
    })
  }

  const handleOnChange = useInputMask({
    input: inputRef,
    onChange: (e) => {
      onChange({
        ...e,
        target: {
          ...e.target,
          id: name,
          name,
          value: !inputRef.current.value ? '' : parseFloat(inputRef.current.value.replace(',', '.'))
        }
      })
    },
    mask: INPUT_FLOAT_MASK,
    guide: false
  })

  const handleBlur = useCallback(
    (e) => {
      if (onBlur && valueProp !== parseFloat(e.target.value.replace(',', '.'))) {
        onBlur({
          target: {
            ...e.target,
            id: name,
            name,
            value: !e.target.value ? '' : parseFloat(e.target.value.replace(',', '.'))
          }
        })
      }
    },
    [onBlur]
  )

  return (
    <SptField
      inputRef={inputRef}
      value={
        withoutMask
          ? getSafe(inputRef, 'current.value', valueProp || '')
              .replace('.', ',')
              .replaceAll(/[a-zA-Z]/g, '')
          : getSafe(inputRef, 'current.value', valueProp)
      }
      onChange={withoutMask ? handleOnChangeWithoutMask : handleOnChange}
      onKeyDown={handleKeyDown}
      onBlur={!withoutMask && handleBlur}
      {...props}
    />
  )
}

SptFieldNumberFloat.propTypes = {}

SptFieldNumberFloat.defaultProps = {}
