export default {
  geral: {
    app: 'MedPlus EN',
    avancar: 'Advance',
    obrigado: 'Thanks'
  },
  cadastro: {
    oba: 'Hey! We ll love having you here for the next 15 days!',
    qualSeuNome: 'What is your name?',
    seuMelhorEmail: 'Your best email',
    vamosComecar: 'Lets start?',
    crieUmaSenha: 'Create a passworda',
    naoNecessarioCartao: 'No credit card required ;)',
    informeSeu: 'Enter yours here',
    nomeSuaClinica: 'nameofyourclinic',
    qualTelefone: 'What is your phone?',
    concordoCom: 'I agree with the',
    politicaProvacidade: 'Privacy Policy',
    naoUtilizeespacoesNemAcentos: 'Do not use spaces, accents or special characters',
    porqueUsamosCpfCnpj: 'Why do we ask for your CPF/CNPJ?',
    motivoDoCpfCnpj:
      'Your CPF/CNPJ is your identifier in our system. It is necessary to create the custom database for you and guarantee security on access. Rest assured, we will only use it for this purpose.',
    acesseSeuEmail:
      'In the meantime, I need you to access your email and click on the link I sent you to validate your account and enjoy all the features of MedPlus for free!',
    nosVemosEmBreve: 'See you soon ;)',
    suaBaseFoiCriada: 'Your base is already being created.'
  },

  validacoes: {
    dominioRegistrado: 'Domain already registered'
  }
}

