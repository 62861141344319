import { useRef, useState, useEffect, useCallback } from 'react'

import { createPopper } from '@popperjs/core'

import { useDeepCompareEffect } from './useDeepCompareEffect'

function useCallbackRef() {
  const [node, setNode] = useState(null)

  const ref = useCallback((n) => {
    if (n !== null) {
      setNode(n)
    }
  }, [])

  return [node, ref]
}

export function usePopper({ placement = 'bottom', positionFixed = false, modifiers = [], strategy = 'absolute' } = {}) {
  const popperInstance = useRef(null)
  const [popperStyles, setPopperStyles] = useState({})
  const [referrenceNode, referrenceRef] = useCallbackRef()
  const [popperNode, popperRef] = useCallbackRef()

  const updatePopperState = useCallback((data) => {
    const { styles } = data

    setPopperStyles(styles)

    return data
  }, [])

  useDeepCompareEffect(() => {
    if (popperInstance.current !== null) {
      popperInstance.current.destroy()
    }

    if (referrenceNode !== null && popperNode !== null) {
      popperInstance.current = createPopper(referrenceNode, popperNode, {
        placement,
        options: {
          padding: 5
        },
        applyStyle: { enabled: false },
        strategy,
        updateStateModifier: {
          enabled: true,
          order: 900,
          requiresIfExists: ['offset'],
          fn: updatePopperState
        },
        modifiers
      })
    }

    return () => {
      if (popperInstance.current !== null) {
        popperInstance.current.destroy()
      }
    }
  }, [popperInstance, referrenceNode, popperNode, placement, positionFixed, modifiers])

  useEffect(() => {
    if (popperInstance.current !== null) {
      popperInstance.current.update()
    }
  }, [popperInstance])

  return {
    referrence: referrenceRef,
    popper: popperRef,
    styles: popperStyles,
    popperNode,
    referrenceNode
  }
}
