import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { pickRandomObjectValue } from '@sponte/lib-utils/dist/helpers/object'
import { getNameInitials } from '@sponte/lib-utils/dist/helpers/string'

import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { fontSize, switchProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptIcon, SptIconStyled } from '../Icon'

import { SptAvatarImage } from './AvatarImage'

export const SptAvatarSizes = {
  xs: css`
    width: 40px;
    height: 40px;

    ${SptText},
    ${SptIconStyled} {
      ${fontSize('large')};
    }
  `,

  sm: css`
    width: 60px;
    height: 60px;

    ${SptText},
    ${SptIconStyled} {
      ${fontSize('xlarge')};
    }
  `,

  md: css`
    width: 80px;
    height: 80px;

    ${SptText},
    ${SptIconStyled} {
      ${fontSize('xlarge')};
    }
  `,

  lg: css`
    width: 110px;
    height: 110px;

    ${SptText},
    ${SptIconStyled} {
      ${fontSize('5')};
    }
  `,

  xlg: css`
    width: 160px;
    height: 160px;

    ${SptText},
    ${SptIconStyled} {
      ${fontSize('6')};
    }
  `
}

export const SptAvatarStyled = styled(SptFlex)`
  img {
    width: 100%;
    border-radius: 100%;
    padding: ${(props) => (props.borderWidth ? '2px' : 0)};
  }

  ${switchProp('size', SptAvatarSizes)};
`

SptAvatarStyled.displayName = 'SptAvatarStyled'

export const SptAvatar = ({ name, nameColor = 'white', image, icon, iconColor = 'white', children, bg, ...props }) => {
  const theme = useTheme()

  const content = useMemo(() => {
    if (image) {
      return <SptAvatarImage alt={name} src={image} />
    }

    if (name) {
      return <SptText color={nameColor}>{getNameInitials(name)}</SptText>
    }

    if (icon) {
      return <SptIcon color={iconColor}>{icon}</SptIcon>
    }

    return children
  }, [name, nameColor, image, icon, iconColor, children])

  const background = useMemo(() => {
    if (!bg || bg === 'random') {
      return pickRandomObjectValue(theme.colors)
    }

    return bg
  }, [bg])

  return (
    <SptAvatarStyled
      overflow="hidden"
      borderRadius="full"
      alignItems="center"
      justifyContent="center"
      bg={background}
      {...props}
    >
      {content}
    </SptAvatarStyled>
  )
}

SptAvatar.displayName = 'SptAvatar'

SptAvatar.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SptAvatarSizes)).isRequired,
  ...SptFlex.propTypes
}

SptAvatar.defaultProps = {
  size: 'md'
}
