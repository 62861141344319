import React, { forwardRef } from 'react'

import { SptBox } from '../../../elements'

import { SptButton } from '../../../atoms/Button'
import { SptTooltip } from '../../../atoms/Tooltip'

export const ButtonWithToolTip = forwardRef(({ tipText, ...props }, ref) => {
  return (
    <SptBox ref={ref}>
      <SptTooltip title={tipText} zIndex={99999}>
        <SptButton {...props} />
      </SptTooltip>
    </SptBox>
  )
})
