import { createNumberMask } from 'text-mask-addons'

export function removeMask(str) {
  return str ? str.toString().replace(/\D/g, '') : ''
}

export const INPUT_CPF_MASK = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
export const INPUT_CEP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
export const INPUT_CNPJ_MASK = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
]

export const INPUT_THREE_NUMBER_MASK = [/\d/, /\d/, /\d/]
export const INPUT_THREE_NUMBER_MASK_WITHOUT_ZERO_AT_FIRST = [/[1-9]/, /\d/, /\d/]
export const INPUT_DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
export const INPUT_DATE_MONTH_YEAR_MASK = [/\d/, /\d/, '/', /\d/, /\d/]
export const INPUT_DATE_YEAR_MASK = [/\d/, /\d/, /\d/, /\d/]
export const INPUT_TIME_MASK = [/\d/, /\d/, ':', /\d/, /\d/]

export const INPUT_THREE_NUMBER_BAR_FOUR_NUMBER = [/\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
export const INPUT_FOUR_NUMBER_BAR_FOUR_NUMBER = [/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

const maskPercentage = createNumberMask({
  prefix: '',
  suffix: '%',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  integerLimit: 9,
  decimalSymbol: ',',
  requireDecimal: true
})

export const INPUT_PERCENTAGE_MASK = (value) => {
  const mask = maskPercentage(value)

  const decimalsRegex = /,([0-9]{1,2})/
  const result = decimalsRegex.exec(value)

  if (!!result && result[1].length < 2) {
    mask.push('0')
  } else if (!result) {
    mask.push('00')
  }

  return mask
}

export const INPUT_FLOAT_MASK = (value) => {
  const mask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: null,
    requireDecimal: false
  })

  return mask(value)
}

const maskMoney = createNumberMask({
  prefix: 'R$ ',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  integerLimit: 9,
  decimalSymbol: ',',
  requireDecimal: true
})

export const INPUT_MONEY_MASK = (value) => {
  const mask = maskMoney(value)

  const decimalsRegex = /,([0-9]{1,2})/
  const result = decimalsRegex.exec(value)

  if (!!result && result[1].length < 2) {
    mask.push('0')
  } else if (!result) {
    mask.push('00')
  }

  return mask
}

export const INPUT_TEXT_DATE_MASK = (value, string, integerLimit = 3) => {
  const maskTextDate = createNumberMask({
    prefix: '',
    suffix: ` ${string}`,
    allowDecimal: false,
    integerLimit,
    includeThousandsSeparator: false
  })

  const mask = maskTextDate(value)

  return mask
}

export const INPUT_PHONE_MASK = (value) => {
  const len = removeMask(value).length

  let prefix = ['(', /\d/, /\d/, ')', ' ']
  let middle = [/\d/, /\d/, /\d/, /\d/]
  const suffix = ['-', /\d/, /\d/, /\d/, /\d/]

  if (len > 11) {
    prefix = ['+', /\d/, /\d/, ' ', ...prefix]
  }

  if (len === 11 || len === 13) {
    middle = [/\d/, ...middle]
  }

  return [...prefix, ...middle, ...suffix]
}

export function maskCpf(value) {
  return removeMask(value)
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function maskCpnj(value) {
  return removeMask(value)
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function maskPhone(value) {
  const val = removeMask(value)

  if (val.length > 11) {
    return val
      .replace(/(\d{2})(\d)/, '+$1 $2')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1')
  }

  return val
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}

export function maskCep(value) {
  return removeMask(value)
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

export function maskPis(value) {
  return removeMask(value)
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{5})(\d)/, '$1.$2')
    .replace(/(\d{5}\.)(\d{2})(\d)/, '$1$2-$3')
    .replace(/(-\d{1})\d+?$/, '$1')
}
