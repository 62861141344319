import React, { memo, useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptIcon } from '../Icon'

const SptCheckboxContainer = styled(SptFlex)`
  cursor: pointer;

  svg {
    cursor: pointer;
  }
`

SptCheckboxContainer.displayName = 'SptCheckboxContainer'

const SptCheckboxStyled = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

SptCheckboxStyled.displayName = 'SptCheckboxStyled'

export const SptCheckbox = memo(
  ({
    colored,
    checked,
    checkboxActive,
    checkboxInactive,
    label,
    onChange,
    icon,
    iconChecked,
    disabled,
    name,
    value,
    error,
    textHelp: textHelpProp,
    textHelpColor = 'darkGrey',
    displayTextHelp = true,
    ...props
  }) => {
    const handleChange = useCallback(() => {
      if (!disabled && onChange) {
        onChange(!checked)
      }
    }, [checked, disabled, onChange])

    const textColor = useMemo(() => {
      if (disabled) {
        return 'mediumGrey'
      }

      if (!colored) {
        return 'almostBlack'
      }

      return checked ? 'primary' : 'mediumGrey'
    }, [disabled, colored, checked])

    const iconColor = useMemo(() => {
      if (error) {
        return 'error'
      }

      if (!disabled && checked) {
        return 'primary'
      }

      return 'mediumGrey'
    }, [disabled, checked, error])

    const textHelp = useMemo(() => {
      if (!displayTextHelp) {
        return null
      }

      if (error) {
        return error
      }

      return textHelpProp
    }, [error, displayTextHelp, textHelpProp])

    const checkboxIcon = checked ? checkboxActive || iconChecked : checkboxInactive || icon

    return (
      <>
        <SptCheckboxContainer alignItems="center" onClick={handleChange} disabled={disabled} {...props}>
          <SptCheckboxStyled
            data-testid="checkbox"
            type="checkbox"
            disabled={disabled}
            name={name}
            value={value}
            checked={checked}
            onChange={handleChange}
          />

          <SptIcon color={iconColor} {...props}>
            {checkboxIcon}
          </SptIcon>

          <SptFlex flexDirection="column">
            {label && (
              <SptText ml={6} color={textColor}>
                {label}
              </SptText>
            )}

            {textHelp && (
              <SptText ml={6} color={error ? 'error' : textHelpColor} fontSize="small">
                {textHelp}
              </SptText>
            )}
          </SptFlex>
        </SptCheckboxContainer>
      </>
    )
  }
)

SptCheckbox.displayName = 'SptCheckbox'

SptCheckbox.propTypes = {
  colored: PropTypes.bool,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  checkboxActive: PropTypes.string,
  checkboxInactive: PropTypes.string
}

SptCheckbox.defaultProps = {
  icon: 'spt-checkbox-off',
  iconChecked: 'spt-checkbox-on'
}
