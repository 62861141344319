import React from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { ifProp, fontSize } from '@sponte/lib-utils/dist/theme/tools'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptIcon } from '../Icon'

export const SptListItemStyled = styled(SptFlex)`
  ${fontSize('default')};

  ${ifProp(
    'onClick',
    css`
      cursor: pointer;
    `
  )};

  /* hack para a lista ficar no tamanho correto quando tiver icones */
  svg {
    margin-top: -3px;
    margin-bottom: -3px;
  }
`

SptListItemStyled.displayName = 'SptListItemStyled'

export const SptListItem = ({
  action,
  primaryText,
  primaryTextColor = 'primary',
  secondaryText,
  secondaryTextColor,
  icon,
  ...props
}) => {
  return (
    <SptListItemStyled
      width={1}
      py={7}
      px={15}
      border="1px solid"
      borderColor="mediumGrey"
      borderRadius="sm"
      alignItems="center"
      justifyContent="space-around"
      {...props}
      data-testid="listItem"
    >
      <SptFlex flex={1}>
        {icon && (
          <SptIcon color="darkGrey" mr={6}>
            {icon}
          </SptIcon>
        )}

        <SptText color={primaryTextColor} fontWeight="bold">
          {primaryText}
        </SptText>
      </SptFlex>

      {secondaryText && (
        <SptFlex flex={1}>
          <SptText color={secondaryTextColor} fontWeight="medium">
            {secondaryText}
          </SptText>
        </SptFlex>
      )}

      {action && <SptFlex>{action}</SptFlex>}
    </SptListItemStyled>
  )
}

SptListItem.displayName = 'SptListItem'

SptListItem.propTypes = {
  action: PropTypes.node,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  icon: PropTypes.string
}

SptListItem.defaultProps = {}
