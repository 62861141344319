import React, { memo } from 'react'

import { SptDrawer } from '@sponte/lib-components/dist/molecules/Drawer'

export const CadastroTesteGratisWrapperMobile = memo(({ children }) => {
  return (
    <SptDrawer opened width={1} anchor="left">
      {children}
    </SptDrawer>
  )
})
