import { useEffect } from 'react'

import { checkDeps, useDeepCompareMemoize } from './useDeepCompareMemoize'

/**
 * https://github.com/kentcdodds/use-deep-compare-effect
 */
export function useDeepCompareEffect(effect, inputs) {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(inputs, 'useDeepCompareEffect')
  }

  useEffect(effect, useDeepCompareMemoize(inputs))
}
