import React, { useMemo, useState, useCallback, useRef } from 'react'

import moment from 'moment'

import styled from 'styled-components'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { theme, fontSize } from '@sponte/lib-utils/dist/theme/tools'

import { SptButton } from '../../atoms/Button'
import { SptPortal } from '../../atoms/Portal'

const Calendar = styled.div`
  .DateInput {
    display: none;
  }

  .DateRangePickerInput_arrow {
    display: none;
  }

  .DateRangePickerInput__withBorder {
    border: none;
  }

  .DateRangePicker {
    display: block !important;
  }

  .DateRangePicker_picker {
    z-index: 99999;
    position: fixed;
    top: ${(props) => props.y || 0}px !important;
    left: ${(props) => props.x || 0}px !important;
  }

  .CalendarMonth_caption {
    font-weight: ${theme('fontWeights.medium')};
    ${fontSize('default')}
    width: 124%;
    margin-left: -23px;
    padding-bottom: ${theme('space.11')}px;
    margin-bottom: ${theme('space.15')}px;
    background: ${theme('colors.lightGrey')};
  }

  .CalendarMonthGrid {
    background: transparent;
  }

  .CalendarDay {
    vertical-align: middle !important;
    border-radius: ${theme('radii.md')}px;
    width: ${theme('space.14')}px !important;
    height: ${theme('space.14')}px !important;
  }

  .CalendarDay:hover {
    border: none;
  }

  .CalendarDay__hovered_span {
    background: ${theme('colors.primary')};
    color: ${theme('colors.white')};
  }

  .CalendarDay__hovered_span:hover {
    background: ${theme('colors.primary')};
    color: ${theme('colors.white')};
  }

  .CalendarDay__selected {
    background: ${theme('colors.primary')};
    font-weight: ${theme('fontWeights.bold')};
  }

  .CalendarDay__selected:hover {
    background: ${theme('colors.primaryDark')};
    font-weight: ${theme('fontWeights.bold')};
  }

  .CalendarDay__selected_span {
    background: ${theme('colors.primary')};
    font-weight: ${theme('fontWeights.bold')};
  }

  .CalendarDay__selected_span:hover {
    background: ${theme('colors.primaryDark')};
    font-weight: ${theme('fontWeights.bold')};
  }

  .CalendarDay__default {
    font-family: ${theme('fonts.default')};
    font-weight: ${theme('fontWeights.medium')};
    ${fontSize('medium')}
    border: none;
    line-height: 0 !important;
  }

  .CalendarDay__blocked_calendar {
    border: none;
    background: ${theme('colors.lightGrey')};
    color: ${theme('colors.mediumGrey')};
    border-radius: 0;
  }

  .CalendarDay__blocked_calendar:hover {
    border: none;
    background: ${theme('colors.lightGrey')};
    color: ${theme('colors.mediumGrey')};
  }
  .DayPicker {
    overflow: hidden;
    width: auto !important;
    border-radius: ${theme('radii.sm')}px;
  }

  .DayPicker_weekHeader {
    background: ${theme('colors.white')};
    font-weight: ${theme('fontWeights.medium')};
    color: ${theme('colors.almostBlack')};
    margin-top: ${theme('space.5')}px;
  }

  .DayPicker_weekHeader_li {
    width: ${theme('space.14')}px !important;
  }

  .DayPickerNavigation {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .DayPickerNavigation_button button {
    margin-top: ${theme('space.6')}px;
    margin-left: ${theme('space.4')}px;
    margin-right: ${theme('space.4')}px;
  }

  .DayPicker_transitionContainer {
    background: transparent;
  }
`

Calendar.displayName = 'Calendar'

export const SptFieldDateRangePicker = ({
  id,
  type,
  onChange,
  startDateName,
  endDateName,
  children,
  value,
  ...props
}) => {
  const { t } = useTranslation()

  const dateMask = useMemo(() => {
    return t('geral:date_mask')
  }, [t])

  const [focusedInput, setFocusedInput] = useState(null)

  const startPositionRef = useRef()
  const endPositionRef = useRef()

  const calendarPositon =
    focusedInput && focusedInput === 'startDate'
      ? startPositionRef.current && startPositionRef.current.getBoundingClientRect()
      : endPositionRef.current && endPositionRef.current.getBoundingClientRect()

  const handleChange = useCallback(
    ({ startDate, endDate }) => {
      if (!onChange || typeof onChange !== 'function') {
        return
      }

      const start = moment(startDate)
      const end = moment(endDate)

      onChange({
        target: {
          id,
          name: startDateName,
          type: 'text',
          value: start.isValid() ? start.format() : ''
        }
      })

      onChange({
        target: {
          id,
          name: endDateName,
          type: 'text',
          value: end.isValid() ? end.format() : ''
        }
      })
    },
    [onChange]
  )

  const startVal = getSafe(value, startDateName)
  const endVal = getSafe(value, endDateName)
  const startDateValue = startVal ? moment(startVal) : null
  const endDateValue = endVal ? moment(endVal) : null

  const Comp = () => {
    return React.cloneElement(children, {
      setFocusedInput,
      startDateValue: getSafe(value, startDateName),
      endDateValue: getSafe(value, endDateName),
      startPositionRef,
      endPositionRef
    })
  }

  return (
    <>
      <Comp />

      <SptPortal>
        <Calendar x={calendarPositon && calendarPositon.x} y={calendarPositon && calendarPositon.bottom}>
          <DateRangePicker
            {...props}
            isOutsideRange={() => false}
            numberOfMonths={1}
            startDate={startDateValue}
            endDate={endDateValue}
            onDatesChange={handleChange}
            focusedInput={focusedInput}
            onFocusChange={(newFocus) => setFocusedInput(newFocus)}
            daySize={28}
            startDateId="start"
            endDateId="end"
            displayFormat={() => dateMask}
            hideKeyboardShortcutsPanel
            navPrev={<SptButton icon="spt-caret-left" palette="neutral" variant="text" />}
            navNext={<SptButton icon="spt-caret-right" palette="neutral" variant="text" />}
          />
        </Calendar>
      </SptPortal>
    </>
  )
}

SptFieldDateRangePicker.displayName = 'SptFieldDateRangePicker'

SptFieldDateRangePicker.propTypes = {}

SptFieldDateRangePicker.defaultProps = {}
