import React, { memo } from 'react'

// eslint-disable-next-line import/no-cycle
import { SptRichTextAreaContent } from './RichTextAreaContent'
import { RichTextAreaProvider } from './RichTextAreaContext'

export const SptRichTextArea = memo((props) => {
  return (
    <RichTextAreaProvider {...props}>
      <SptRichTextAreaContent />
    </RichTextAreaProvider>
  )
})

SptRichTextArea.displayName = 'SptRichTextArea'

SptRichTextArea.propTypes = {}

SptRichTextArea.defaultProps = {}
