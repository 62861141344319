import React from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'

import { SptCheckbox } from '../Checkbox/Checkbox'

export const SptCheckboxIconSizes = {
  sm: 0,
  md: 2,
  lg: 4
}

const SptCheckboxIconStyled = styled(SptBox)`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border: 1px solid ${theme('colors.mediumGrey')};
  border-radius: 50px;
`

SptCheckboxIconStyled.displayName = 'SptCheckboxIconStyled'

export const SptCheckboxIcon = ({ checked, onChange, size, icon, ...props }) => {
  const handleChange = () => {
    if (onChange) {
      onChange(!checked)
    }
  }

  return (
    <SptCheckboxIconStyled p={SptCheckboxIconSizes[size]} onClick={handleChange}>
      <SptCheckbox checked={checked} icon={icon} iconChecked={icon} {...props} />
    </SptCheckboxIconStyled>
  )
}

SptCheckboxIcon.displayName = 'SptCheckboxIcon'

SptCheckboxIcon.propTypes = {
  size: PropTypes.oneOf(Object.keys(SptCheckboxIconSizes)),
  icon: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

SptCheckboxIcon.defaultProps = {
  size: 'md',
  fontSize: 30
}
