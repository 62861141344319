import React, { memo, useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptFieldBoxLetterInputStyled } from './FieldBoxLetterInputStyled'
import { keysNotAllow, numberOnly } from './utils'

export const SptFieldBoxLetterCurrency = memo(
  ({ id, name, value, disabled, onChange, maxLength, setValue, ...props }) => {
    const internalValue = useMemo(() => {
      const arrayValue = value ? String(parseFloat(value, 10).toFixed(2)).replace('.', '') : []
      const arrayPreenchido = []
      if (arrayValue.length < maxLength + 2) {
        while (arrayPreenchido.length + arrayValue.length < maxLength + 2) {
          arrayPreenchido.push('')
        }
      }
      return [...arrayPreenchido, ...arrayValue]
    }, [value, maxLength])

    const setInputFocus = useCallback(() => {
      if (!disabled) {
        document.getElementById(`${name}-${maxLength + 1}`).focus()
      }
    }, [disabled, value, name])

    const handleKeyDown = useCallback(
      (e) => {
        if (keysNotAllow(e.key) || numberOnly(e.key) || e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
          return
        }

        const valueCurrent =
          e.key.length === 1
            ? [...internalValue, e.key].filter((ar, indexa) => indexa > 0 && ar !== '')
            : ['', ...internalValue.filter((ar, indexa) => indexa < maxLength + 1 && ar !== '')]

        const newValue =
          valueCurrent.length > 2
            ? valueCurrent.map((a, index) => (index === valueCurrent.length - 3 ? `${a}.` : a)).join('')
            : ['0.', '0', ...valueCurrent].join('')

        if (typeof onChange === 'function') {
          onChange({
            ...e,
            target: {
              ...e.target,
              id,
              type: 'number',
              name,
              value: parseFloat(newValue, 10).toFixed(2)
            }
          })
        }

        if (typeof setValue === 'function') {
          setValue(parseFloat(newValue, 10).toFixed(2))
        }
      },
      [name, internalValue]
    )

    return (
      <SptFlex
        width={{
          web: `${10 * (maxLength + 2) + 10}px`,
          hd: `${12 * (maxLength + 2) + 12}px`,
          fullhd: `${18 * (maxLength + 2) + 18}px`
        }}
        onClick={setInputFocus}
      >
        {internalValue.map((caracter, i) => (
          <>
            <SptFieldBoxLetterInputStyled
              key={i}
              id={`${name}-${i}`}
              {...props}
              maxLength={maxLength + 2}
              hasBorderRight={i === maxLength - 1 || i === maxLength + 1}
              onKeyDown={(e) => handleKeyDown(e)}
              value={caracter}
            />
            {i === maxLength - 1 && (
              <SptText mx={2} fontWeight="bold">
                ,
              </SptText>
            )}
          </>
        ))}
      </SptFlex>
    )
  }
)

SptFieldBoxLetterCurrency.displayName = 'SptFieldBoxLetterCurrency'

SptFieldBoxLetterCurrency.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  hasValue: PropTypes.bool
}
