import React, { memo, forwardRef } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { switchProp, theme, fontSize } from '@sponte/lib-utils/dist/theme/tools'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

export const SptBadgeSizes = {
  xs: css`
    width: ${theme('space.8')}px;
    height: ${theme('space.8')}px;

    ${SptText} {
      ${fontSize('small')};
    }
  `,

  sm: css`
    width: ${theme('space.12')}px;
    height: ${theme('space.12')}px;

    ${SptText} {
      ${fontSize('small')};
    }
  `,

  md: css`
    width: ${theme('space.14')}px;
    height: ${theme('space.14')}px;

    ${SptText} {
      ${fontSize('medium')};
    }
  `,

  lg: css`
    width: ${theme('space.16')}px;
    height: ${theme('space.16')}px;

    ${SptText} {
      ${fontSize('default')};
    }
  `
}

const SptBadgeStyled = styled(SptFlex)`
  ${switchProp('size', SptBadgeSizes)}
`

SptBadgeStyled.displayName = 'SptBadgeStyled'

export const SptBadge = memo(
  forwardRef(({ children, color, strong, ...props }, ref) => {
    const child = (children && parseInt(children, 10) > 9 ? '9+' : children) || 0

    const textProps = {
      color,
      fontWeight: strong ? 'bold' : 'medium'
    }

    return (
      <SptBadgeStyled ref={ref} alignItems="center" justifyContent="center" borderRadius="lg" {...props}>
        <SptText {...textProps}>{child}</SptText>
      </SptBadgeStyled>
    )
  })
)

SptBadge.displayName = 'SptBadge'

SptBadge.propTypes = {
  size: PropTypes.oneOf(Object.keys(SptBadgeSizes)).isRequired,
  strong: PropTypes.bool
}

SptBadge.defaultProps = {
  size: 'md',
  bg: 'primary',
  color: 'white',
  strong: true
}
