import React from 'react'

import PropTypes from 'prop-types'

import { SptFlex } from '../../elements/Flex'

export const SptCardFooter = (props) => {
  return <SptFlex {...props} />
}

SptCardFooter.displayName = 'SptCardFooter'

SptCardFooter.propTypes = {
  children: PropTypes.node
}

SptCardFooter.defaultProps = {
  py: 10,
  px: 15,
  width: 1,
  flexDirection: 'row-reverse',
  alignItens: 'center',
  justifyContent: 'space-between',
  borderTop: '1px solid',
  borderColor: 'mediumGrey'
}
