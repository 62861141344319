import { useMemo, useEffect } from 'react'

import hotkeys from 'hotkeys-js'

export function useHotkeys(handler, keys = ['*'], inputs = []) {
  if (!handler || typeof handler !== 'function') {
    throw new Error('[useHotkeys]: handler should be a function')
  }

  const keysMap = useMemo(() => (!Array.isArray(keys) ? [keys] : keys), [keys])

  useEffect(() => {
    hotkeys(keysMap.join(','), (event, handle) => handler(handle.key, event, handle))

    return () => {
      keysMap.forEach((key) => hotkeys.unbind(key))
    }
  }, [...keysMap, ...inputs])
}
