import React, { useRef, useMemo } from 'react'

import useInputMask from 'use-input-mask'

import { INPUT_FOUR_NUMBER_BAR_FOUR_NUMBER } from '@sponte/lib-utils/dist/helpers/masks'
import { onlyNumberFromCurrency } from '@sponte/lib-utils/dist/helpers/number'

import { SptField } from '../Field'

export const SptFieldLDBEN = ({ value, onChange, ...props }) => {
  const inputRef = useRef(null)

  const handleOnChange = useInputMask({
    input: inputRef,
    onChange: (e) => {
      if (!onChange || typeof onChange !== 'function') {
        return
      }

      onChange({
        ...e,
        target: {
          ...e.target,
          id: props.id,
          type: 'text',
          name: props.name,
          value: e.target.value ? onlyNumberFromCurrency(e.target.value) : undefined
        }
      })
    },
    mask: INPUT_FOUR_NUMBER_BAR_FOUR_NUMBER,
    guide: false,
    initialValue: value
  })

  const fieldProps = useMemo(
    () => ({
      ...props,
      type: 'text',
      hasValue: !!value,
      onChange: handleOnChange
    }),
    [props, handleOnChange]
  )

  return <SptField inputRef={inputRef} {...fieldProps} />
}

SptFieldLDBEN.propTypes = {}

SptFieldLDBEN.defaultProps = {}
