import React, { forwardRef, useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { ifProp, prop } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'
import { SptHidden } from '../../elements/Hidden'

export const SptSwitchSizes = {
  xxs: {
    width: '28px',
    height: '12px'
  },

  xs: {
    width: '36px',
    height: '16px'
  },

  sm: {
    width: '74px',
    height: '32px'
  },

  md: {
    width: '90px',
    height: '40px'
  },

  lg: {
    width: '106px',
    height: '48px'
  }
}

const SptSwitchContentStyled = styled(SptBox)`
  transition: transform 250ms ease 0s;

  width: ${prop('size')};
  height: ${prop('size')};
`

SptSwitchContentStyled.displayName = 'SptSwitchContentStyled'

const SptSwitchStyled = styled(SptBox)`
  cursor: pointer;
  box-sizing: content-box;
  transition: background 250ms ease 0s;

  width: ${prop('width')};
  height: ${prop('height')};

  ${ifProp(
    'checked',
    css`
      ${SptSwitchContentStyled} {
        transform: translateX(calc(${prop('width')} - ${prop('height')}));
      }
    `
  )};
`

SptSwitchStyled.displayName = 'SptSwitchStyled'

export const SptSwitch = forwardRef(
  (
    { id, name, value, color, defaultChecked, checked, disabled, onChange, onBlur, onFocus, children, size, ...props },
    ref
  ) => {
    const { width, height } = SptSwitchSizes[size]

    const handleChange = useCallback(() => {
      if (!disabled && onChange) {
        onChange(!checked)
      }
    }, [checked, disabled, onChange])

    const switchColor = useMemo(() => {
      if (checked) return color
      if (disabled) return 'lightGrey'

      return 'mediumGrey'
    }, [checked, disabled])

    return (
      <SptBox as="label" display="flex" verticalAlign="middle" {...props}>
        <SptHidden
          as="input"
          type="checkbox"
          data-testid="switch"
          id={id}
          ref={ref}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          defaultChecked={defaultChecked}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleChange}
        />

        <SptSwitchStyled
          borderRadius="full"
          justifyContent="flex-start"
          width={width}
          height={height}
          checked={checked}
          bg={switchColor}
          p={2}
        >
          <SptSwitchContentStyled bg="white" borderRadius="full" size={height} />
        </SptSwitchStyled>
      </SptBox>
    )
  }
)

SptSwitch.displayName = 'SptSwitch'

SptSwitch.propTypes = {
  size: PropTypes.oneOf(Object.keys(SptSwitchSizes)).isRequired
}

SptSwitch.defaultProps = {
  size: 'md',
  color: 'primary'
}
