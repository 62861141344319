import styled from 'styled-components'

import { SptBox } from '../Box'

export const SptCol = styled(SptBox)``

SptCol.displayName = 'SptCol'

SptCol.propTypes = {
  ...SptBox.propTypes
}

SptCol.defaultProps = {
  px: 5,
  my: 5,
  width: 1
}
