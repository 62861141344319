export default {
  geral: {
    avancar: 'Avançar',
    obrigado: 'Obrigado'
  },
  cadastro: {
    oba: 'Oba! Vamos adorar ter você aqui pelos próximos 15 dias!',
    qualSeuNome: 'Qual seu nome?',
    seuMelhorEmail: 'Seu melhor email',
    vamosComecar: 'Vamos começar?',
    crieUmaSenha: 'Crie uma senha',
    naoNecessarioCartao: 'Não é necessário informar cartão de crédito ;)',
    informeSeu: 'Informe aqui o seu',
    nomeSuaClinica: 'nomedasuaclinica',
    qualTelefone: 'Qual seu telefone?',
    concordoCom: 'Concordo com a',
    politicaProvacidade: 'Política de Privacidade',
    naoUtilizeespacoesNemAcentos: 'Não utilize espaços, acentos ou caracteres especiais',
    porqueUsamosCpfCnpj: 'Por que pedimos seu CPF/CNPJ?',
    motivoDoCpfCnpj:
      'O seu CPF/CNPJ é seu identificador em nosso sistema. Ele é necessário para criar a base de dados personalizada para você e garantir a segurança no acesso. Pode ficar tranquilo(a), utilizaremos somente para este fim.',
    acesseSeuEmail:
      'Enquanto isso, preciso que acesse seu email e clique no link que enviei, para validar sua conta e aproveitar todos os recursos do MedPlus de graça!',
    nosVemosEmBreve: 'Nos vemos em breve ;)',
    suaBaseFoiCriada: 'Sua base já está sendo criada.'
  },

  validacoes: {
    dominioRegistrado: 'Domínio já registrado'
  }
}
