import React from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import { ifProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'

import { SptAnimationFade } from '../AnimationFade'

const SptOverlayStyled = styled(SptBox)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
  /* white or almostBlack */
  background-color: ${ifProp('isLight', 'rgba(255, 255, 255, 0.9)', 'rgba(70, 80, 90, 0.5)')};
  /* -webkit-tap-highlight-color: transparent;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px); */
`

SptOverlayStyled.displayName = 'SptOverlayStyled'

export const SptOverlay = ({ opened, ...props }) => {
  return (
    <SptAnimationFade in={opened} appear>
      <SptOverlayStyled {...props} />
    </SptAnimationFade>
  )
}

SptOverlay.displayName = 'SptOverlay'

SptOverlay.propTypes = {
  opened: PropTypes.bool,
  isLight: PropTypes.bool
}

SptOverlay.defaultProps = {
  zIndex: -1,
  position: 'fixed'
}
