import React, { memo } from 'react'

import PropTypes from 'prop-types'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptAvatar } from '../../atoms/Avatar'
import { SptBoxLoader } from '../../atoms/BoxLoader'
import { SptCloseButton } from '../../atoms/Button'
import { SptCardHeader } from '../../atoms/Card'

const SptDrawerFormHeaderAvatar = memo(({ bg = 'mediumGrey', size = 'sm', ...props }) => {
  return <SptAvatar bg={bg} size={size} {...props} />
})

export const SptDrawerFormHeaderCode = memo(({ codePrefix = true, code, mb = 5, ...props }) => {
  return (
    <SptText color="primary" fontWeight="bold" mb={mb} {...props}>
      {codePrefix && '#'}
      {code}
    </SptText>
  )
})

export const SptDrawerFormHeaderTitle = memo(({ title, ...props }) => {
  return (
    <SptFlex maxWidth="100%" overflow="hidden">
      <SptText color="almostBlack" fontWeight="bold" fontSize="xlarge" textAlign="center" truncate {...props}>
        {title}
      </SptText>
    </SptFlex>
  )
})

export const SptDrawerFormHeaderCodeLoader = memo(() => {
  return <SptBoxLoader width={40} height={18} borderRadius="sm" mb={5} />
})

export const SptDrawerFormHeaderTitleLoader = memo(() => {
  return <SptBoxLoader width={{ _: 1 / 2, web: 1 / 3 }} height={28} borderRadius="sm" />
})

export const SptDrawerFormHeaderLoader = memo(() => {
  return (
    <>
      <SptDrawerFormHeaderCodeLoader />
      <SptDrawerFormHeaderTitleLoader />
    </>
  )
})

export const SptDrawerFormHeader = ({
  tabs,
  icon,
  image,
  codePrefix,
  code,
  title,
  onClose,
  avatar,
  children,
  isLoading
}) => {
  const avatarProps =
    !avatar && (icon || image)
      ? {
          icon,
          image
        }
      : avatar

  return (
    <SptCardHeader position="relative" flexDirection="column">
      <SptFlex px={15} pt={7} pb={15} flex={1} flexDirection="column">
        {onClose && (
          <SptFlex width={1} justifyContent="flex-end">
            <SptCloseButton data-testid="closeButton" onClick={() => onClose()} />
          </SptFlex>
        )}

        <SptFlex
          flex={1}
          alignItems={{
            _: 'center',
            web: 'inherit'
          }}
          flexDirection={{
            _: 'column',
            web: 'row'
          }}
        >
          {avatarProps && <SptDrawerFormHeaderAvatar {...avatarProps} />}

          <SptFlex
            width={1}
            flex={1}
            mx={9}
            overflow="hidden"
            flexDirection="column"
            alignItems={{
              _: 'center',
              web: 'flex-start'
            }}
            justifyContent="center"
            mt={{
              _: 15,
              web: 0
            }}
          >
            {children || (
              <>
                {isLoading ? (
                  <SptDrawerFormHeaderLoader />
                ) : (
                  <>
                    <SptDrawerFormHeaderCode code={code} codePrefix={codePrefix} />

                    <SptDrawerFormHeaderTitle title={title} />
                  </>
                )}
              </>
            )}
          </SptFlex>
        </SptFlex>
      </SptFlex>

      {tabs && <SptFlex>{tabs}</SptFlex>}
    </SptCardHeader>
  )
}

SptDrawerFormHeader.displayName = 'SptDrawerFormHeader'

SptDrawerFormHeader.propTypes = {
  tabs: PropTypes.node,
  icon: PropTypes.string,
  image: PropTypes.string,
  codePrefix: PropTypes.bool,
  code: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  avatar: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool
}

SptDrawerFormHeader.defaultProps = {}
