import React from 'react'

import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { EventContainer, EventColor, EventName } from './styles'

export const SptBigCalendarSubtitles = React.memo(({ color, name }) => {
  const theme = useTheme();

  return (
    <EventContainer>
      <EventColor color={theme.colors[color]} />
      <EventName>
        {name}
      </EventName>
    </EventContainer>
  )
})
