import systemPropTypes from '@styled-system/prop-types'

import styled from 'styled-components'
import { layout, border, shadow, position, space, color } from 'styled-system'

export const SptAudio = styled.audio`
  ${layout};
  ${border};
  ${shadow};
  ${position};
  ${space};
  ${color};
`

SptAudio.displayName = 'SptAudio'

SptAudio.propTypes = {
  ...systemPropTypes.layout,
  ...systemPropTypes.border,
  ...systemPropTypes.shadow,
  ...systemPropTypes.position,
  ...systemPropTypes.space,
  ...systemPropTypes.color
}

SptAudio.defaultProps = {}
