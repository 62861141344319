import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { Draggable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'

const portal = document.createElement('div')
portal.classList.add('my-super-cool-portal')
document.body.appendChild(portal)

export const SptDragListItem = ({ children, id, index, inPortal, ...props }) => {
  const style = useMemo(() => {
    return props
  }, [props])

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => {
        if (!snapshot.isDragging || !inPortal) {
          return children(provided, snapshot, style)
        }

        return createPortal(children(provided, snapshot, style), portal)
      }}
    </Draggable>
  )
}

SptDragListItem.displayName = 'SptDragListItem'

SptDragListItem.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  inPortal: PropTypes.bool
}

SptDragListItem.defaultProps = {
  inPortal: false
}
