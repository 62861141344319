import React, { memo, useMemo, useCallback, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import moment from 'moment'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptFieldBoxLetterInputStyled } from './FieldBoxLetterInputStyled'
import { keysNotAllow, numberOnly } from './utils'

export const SptFieldBoxLetterHour = memo(({ id, name, value, disabled, onChange, setValue, ...props }) => {
  const internalValue = useMemo(() => {
    const arrayValue = value ? String(value).replace(':', '').split('') : []

    return Array.from({ length: 4 }).map((_, i) => (i > arrayValue.length - 1 ? '' : arrayValue[i]))
  }, [value])

  const inputsRefs = useRef(null)

  useEffect(() => {
    inputsRefs.current = document.getElementsByName(`${name}-input`)
  }, [])

  const setInputFocus = useCallback(() => {
    if (!value && !disabled) {
      document.getElementById(`${name}-0`).focus()
    }
  }, [disabled, value, name])

  const handleKeyDown = useCallback(
    (e, i) => {
      if (keysNotAllow(e.key) || numberOnly(e.key)) {
        return
      }

      const prevCharacter = inputsRefs.current[i - 1]
      const nextCharacter = inputsRefs.current[i + 1]
      if (e.key === 'ArrowRight') {
        if (nextCharacter) {
          nextCharacter.focus()
        }
        return
      }

      if (e.key === 'ArrowLeft') {
        if (prevCharacter) {
          prevCharacter.focus()
        }
        return
      }

      const valueCurrent =
        e.key.length === 1
          ? internalValue.map((ar, index) => (index === i ? e.key : ar))
          : internalValue.map((ar, index) => (index === i ? '' : ar))

      const valueJoin = valueCurrent.join('')
      const hoursFormated =
        valueCurrent.filter((ar) => ar !== '').length === 4
          ? moment()
              .set({
                hours: parseInt(valueJoin.slice(0, 2), 10),
                minutes: parseInt(valueJoin.slice(2), 10)
              })
              .format('HH:mm')
          : valueJoin

      if (typeof onChange === 'function') {
        onChange({
          ...e,
          target: {
            ...e.target,
            id: props.id,
            type: 'text',
            name,
            value: hoursFormated
          }
        })
      }

      if (typeof setValue === 'function') {
        setValue(hoursFormated)
      }

      if ((e.key === 'Backspace' || e.key === 'Delete') && prevCharacter) {
        prevCharacter.focus()
        return
      }

      if (e.key.length === 1 && nextCharacter) {
        nextCharacter.focus()
      }
    },
    [name, internalValue]
  )

  return (
    <SptFlex
      width={{
        web: '50px',
        hd: '60px',
        fullhd: '90px'
      }}
      onClick={setInputFocus}
    >
      {internalValue.map((caracter, i) => (
        <>
          <SptFieldBoxLetterInputStyled
            key={i}
            id={`${name}-${i}`}
            {...props}
            name={`${name}-input`}
            maxLength={1}
            hasBorderRight={i === 1 || i === 3}
            onKeyDown={(e) => handleKeyDown(e, i)}
            value={caracter}
          />
          {i === 1 && (
            <SptText mx={2} fontWeight="bold">
              :
            </SptText>
          )}
        </>
      ))}
    </SptFlex>
  )
})

SptFieldBoxLetterHour.displayName = 'SptFieldBoxLetterHour'

SptFieldBoxLetterHour.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  hasValue: PropTypes.bool
}
