import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { switchProp } from '@sponte/lib-utils/dist/theme'

import { SptFlex } from '../../elements/Flex'

import { SptAnimationSlide } from '../../atoms/AnimationSlide'
import { SptModal } from '../../atoms/Modal'

export const SptDrawerAnchors = {
  left: css`
    left: 0;
    right: auto;
  `,

  right: css`
    left: auto;
    right: 0;
  `,

  bottom: css`
    top: auto;
    bottom: 0;
  `,

  top: css`
    top: 0;
    bottom: auto;
  `
}

const Drawer = styled(SptFlex)`
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2000;

  ${switchProp('anchor', SptDrawerAnchors)}
`

export const SptDrawer = ({ anchor, opened, initialFocusRef, onClose, disableOnKeyDown, disableFocusLock, ...props }) => {
  const { id } = props

  const direction = useMemo(() => {
    if (anchor === 'left') {
      return 'leftToRight'
    }

    if (anchor === 'bottom') {
      return 'bottomToTop'
    }

    return 'rightToLeft'
  }, [anchor])

  return (
    <SptModal
      opened={opened}
      onClose={() => onClose()}
      initialFocusRef={initialFocusRef}
      disableOnKeyDown={disableOnKeyDown}
      disableFocusLock={disableFocusLock}
    >
      <SptAnimationSlide appear in={opened} direction={direction}>
        <Drawer
          flexDirection="column"
          bg="white"
          tabIndex="-1"
          role="dialog"
          anchor={anchor}
          data-testid={id}
          {...props}
        />
      </SptAnimationSlide>
    </SptModal>
  )
}

SptDrawer.propTypes = {
  opened: PropTypes.bool,
  anchor: PropTypes.oneOf(Object.keys(SptDrawerAnchors)).isRequired
}

SptDrawer.defaultProps = {
  opened: false,
  anchor: 'left'
}
