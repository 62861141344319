import React, { memo, useCallback, forwardRef } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { palette, theme, ifProp, ifNotProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptButton } from '../Button'
import { SptIcon } from '../Icon'

const SptTabStyled = styled(SptButton)`
  height: 48px;
  border-radius: 0;
  border: none !important;
  box-shadow: none !important;

  ${SptText} {
    color: ${ifProp('selected', palette(), theme('colors.almostBlack'))} !important;
  }

  ${ifProp(
    'notShow',
    css`
      display: none;
    `
  )};

  ${ifNotProp(
    'disabled',
    css`
      &:hover {
        background-color: transparent;

        ${SptText} {
          color: ${palette()} !important;
        }
      }
    `
  )};
`

SptTabStyled.displayName = 'SptTabStyled'

const SptTabVariants = {
  info: <SptIcon color="info">spt-info</SptIcon>,

  success: <SptIcon color="success">spt-check-circle</SptIcon>,

  warning: <SptIcon color="warning">spt-warning</SptIcon>,

  error: <SptIcon color="error">spt-alert</SptIcon>
}

export const SptTab = memo(
  forwardRef(({ label, value, onChange, onClick, children, variant, ...props }, ref) => {
    const handleChange = useCallback(
      (event) => {
        if (onChange) {
          onChange(event, value)
        }

        if (onClick) {
          onClick(event)
        }
      },
      [onChange, onClick]
    )

    return (
      <SptTabStyled
        ref={ref}
        variant="text"
        role="tab"
        px={10}
        pt={7}
        pb={8}
        aria-selected={props.selected}
        onClick={handleChange}
        {...props}
      >
        <SptFlex justifyContent="center" alignItems="center">
          {children}

          {variant && <SptFlex ml={6}>{SptTabVariants[variant]}</SptFlex>}
        </SptFlex>
      </SptTabStyled>
    )
  })
)

SptTab.displayName = 'SptTab'

SptTab.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['', ...Object.keys(SptTabVariants)])
}

SptTab.defaultProps = {
  palette: 'primary'
}
