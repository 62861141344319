import React, { useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { SptFlex, SptText } from '../../elements'

import { SptButton } from '../../atoms/Button'

import { SptButtonGroup } from '../ButtonGroup'

const defaultItemToString = (item) => (typeof item === 'string' ? item : getSafe(item, 'nome', '')) || ''

export const SptFieldRadioButtonGroup = ({
  id: idProp,
  type,
  variant,
  value = null,
  name,
  items = [],
  onChange = () => {},
  itemToString = defaultItemToString,
  itemIsSelectedKey = 'id',
  displayTextHelp = true,
  error,
  textHelp,
  textHelpColor = 'darkGrey',
  multiSelect,
  ...props
}) => {
  const itemIsSelected = useCallback(
    (item, selectedItem) =>
      getSafe(item, itemIsSelectedKey, item) === getSafe(selectedItem, itemIsSelectedKey, selectedItem),
    [itemIsSelectedKey]
  )

  const selectedItems = (selectedItem) => {
    if (value.includes(selectedItem)) {
      return value.filter((item) => item !== selectedItem)
    }

    return [...value, selectedItem]
  }

  const id = useMemo(() => {
    return idProp || name
  }, [idProp, name])

  const onClick = useCallback(
    (selectedItem) => {
      if (!itemIsSelected(selectedItem, value)) {
        onChange({
          target: {
            id,
            type: 'text',
            name,
            value: multiSelect ? selectedItems(selectedItem) : selectedItem
          }
        })
      }
    },
    [id, name, value, itemIsSelected]
  )

  const textHelpComp = useMemo(() => {
    if (!displayTextHelp || !textHelp) {
      return null
    }

    return (
      <SptText fontSize="small" color={error ? 'error' : textHelpColor} ml={10} mt={5}>
        {textHelp}
      </SptText>
    )
  }, [textHelp, displayTextHelp, error])

  return (
    <SptFlex flexDirection="column" flex={1}>
      <SptButtonGroup {...props}>
        {items.map((item, index) => (
          <SptButton
            data-testid={`${name}-${getSafe(item, 'chave', getSafe(item, 'id', item[itemIsSelectedKey]))
              .toString()
              .toLowerCase()}`}
            key={index}
            onClick={() => onClick(item)}
            variant={itemIsSelected(item, value) || (multiSelect && value.includes(item)) ? 'contained' : 'outlined'}
            icon={item.icon}
            iconLeft={item.iconLeft}
          >
            {itemToString(item)}
          </SptButton>
        ))}
      </SptButtonGroup>
      {textHelpComp}
    </SptFlex>
  )
}

SptFieldRadioButtonGroup.displayName = 'SptFieldRadioButtonGroup'

SptFieldRadioButtonGroup.propTypes = {
  items: PropTypes.array.isRequired,
  itemToString: PropTypes.func,
  itemIsSelectedKey: PropTypes.string,
  multiSelect: PropTypes.bool
}

SptFieldRadioButtonGroup.defaultProps = {
  items: []
}
