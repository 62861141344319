import styled from 'styled-components'
import { border, space } from 'styled-system'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

export const SptHr = styled.hr`
  ${space};
  ${border};

  width: 100%;
  margin-block-start: ${(props) => theme(`space.${props.mt}`)}px;
  margin-block-end: ${(props) => theme(`space.${props.mb}`)}px;
`

SptHr.displayName = 'SptHr'

SptHr.propTypes = {}

SptHr.defaultProps = {
  mt: 8,
  mb: 4,
  borderStyle: 'solid',
  borderColor: 'lightGrey'
}
