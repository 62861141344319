import React, { useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import { SptCol } from '../../elements/Grid'

import { SptRadio } from '../../atoms/Radio'

function defaultItemToString(item) {
  return item && typeof item === 'object' ? item.nome : item || ''
}

function defaultItemIsSelected(item, selectedItem) {
  return item === selectedItem
}

export const SptFieldRadioGroup = ({
  type,
  variant,
  name,
  value,
  items,
  onChange,
  itemToString = defaultItemToString,
  itemIsSelected = defaultItemIsSelected,
  ...props
}) => {
  const id = useMemo(() => {
    return props.id || name
  }, [props.id, name])

  const onClick = useCallback(
    (item) => {
      if (onChange) {
        onChange({
          target: {
            id,
            name,
            type: 'text',
            value: item
          }
        })
      }
    },
    [id, name, itemIsSelected]
  )

  return items.map((item, index) => (
    <SptCol key={index} width="auto" {...props}>
      <SptRadio
        label={itemToString(item)}
        size={props.size}
        value={item}
        icon={item.icon}
        iconChecked={item.iconChecked}
        disabled={props.disabled}
        textHelp={item.textHelp}
        onClick={() => onClick(item, value)}
        checked={itemIsSelected(item, value)}
      />
    </SptCol>
  ))
}

SptFieldRadioGroup.displayName = 'SptFieldRadioGroup'

SptFieldRadioGroup.propTypes = {
  items: PropTypes.array.isRequired,
  itemToString: PropTypes.func,
  itemIsSelected: PropTypes.func
}

SptFieldRadioGroup.defaultProps = {
  items: []
}
