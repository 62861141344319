import React from 'react'

import styled from 'styled-components'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptFlex } from '../../elements/Flex'

import { SptCard } from '../Card'
import { SptCheckbox } from '../Checkbox'

const SptCheckboxCardStyled = styled(SptCard)`
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;

  svg {
    cursor: pointer;
  }
`

SptCheckboxCardStyled.displayName = 'SptCheckboxCardStyled'

const CheckboxCardContent = styled(SptFlex)`
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
`

CheckboxCardContent.displayName = 'CheckboxCardContent'

const CheckboxCardFooter = styled(SptFlex)`
  border: 1px solid ${theme('colors.mediumGrey')};
  border-top: none;
  border-bottom-left-radius: ${theme('radii.sm')}px;
  border-bottom-right-radius: ${theme('radii.sm')}px;
`

CheckboxCardFooter.displayName = 'CheckboxCardFooter'

export const SptCheckboxCard = ({ children, onChange, checked, ...props }) => {
  const handleChange = () => {
    if (onChange) {
      onChange(!checked)
    }
  }

  const bg = checked ? 'primaryLight' : 'mediumGrey'
  const boxShadow = checked ? 1 : 0

  return (
    <SptCheckboxCardStyled flexDirection="column" overflow="hidden" boxShadow={boxShadow} onClick={handleChange}>
      <CheckboxCardContent bg={bg} alignItems="center" justifyContent="center" p={12}>
        {children}
      </CheckboxCardContent>

      <CheckboxCardFooter p={12}>
        <SptCheckbox checked={checked} onChange={handleChange} {...props} />
      </CheckboxCardFooter>
    </SptCheckboxCardStyled>
  )
}

SptCheckboxCard.displayName = 'SptCheckboxCard'

SptCheckboxCard.propTypes = {}

SptCheckboxCard.defaultProps = {}
