import React, { memo, useRef, useMemo, useState, useCallback, useEffect } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { callAllEventHandlers } from '@sponte/lib-utils/dist/helpers/callAll'
import { generateId } from '@sponte/lib-utils/dist/helpers/generateId'

import { useForkRef } from '@sponte/lib-utils/dist/hooks/useForkRef'

import { ifProp, switchProp, theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'
import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptLoading } from '../../atoms'
import { SptInput } from '../../atoms/Input'
import { SptTextarea } from '../../atoms/Textarea'

import { SptFieldShapes, SptFieldSizes } from '../Field/Field'

export const SptFieldTableSimpleVariants = {
  tableLine: css`
    border-color: ${ifProp('focused', theme('colors.primary'))};
  `
}

const SptFieldTableSimpleStyled = styled(SptFlex)`
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  height: auto;
  background-color: transparent;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  svg {
    z-index: 1;
  }

  label {
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(8px) scale(1.25);
    transform-origin: left;

    ${ifProp(
      (props) => props.hasValue || props.focused,
      css`
        transform: translateY(0) scale(1);
      `
    )};
  }

  ${switchProp('size', SptFieldSizes)};

  ${switchProp('shape', SptFieldShapes)};

  ${ifProp(
    (props) => props.textarea,
    css`
      height: auto;
    `
  )};

  ${switchProp('variant', SptFieldTableSimpleVariants)};
`

SptFieldTableSimpleStyled.displayName = 'SptFieldTableSimpleStyled'

const SptFieldAddonStyled = styled(SptFlex).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  height: 100%;

  > span {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 ${theme('space.10')}px;
  }
`

SptFieldAddonStyled.displayName = 'SptFieldAddonStyled'

const SptFieldSuffixStyled = styled(SptFieldAddonStyled).attrs({
  ml: 6
})`
  > svg:last-of-type {
    margin-right: ${theme('space.6')}px;
  }

  > button:last-of-type {
    margin-right: ${theme('space.4')}px;
  }

  > span {
    border-left: 1px solid ${theme('colors.mediumGrey')};
  }
`

SptFieldSuffixStyled.displayName = 'SptFieldSuffixStyled'

const SptFieldTableSimpleLabelStyled = memo(({ id, htmlFor, required, children }) => (
  <SptText as="label" variant="label" id={id} htmlFor={htmlFor}>
    {children}
    {required && (
      <SptText fontSize="small" color="error" pl={1}>
        *
      </SptText>
    )}
  </SptText>
))

SptFieldTableSimpleLabelStyled.displayName = 'SptFieldTableSimpleLabelStyled'

export const SptFieldTableSimple = memo(
  ({
    id,
    name,
    label,
    textHelp,
    required,
    size = 'lg',
    shape = 'rounded',
    palette: colorPalette,
    disabled,
    loading,
    value,
    onClick,
    displayTextHelp,
    onBlur,
    onFocus,
    textarea,
    children,
    suffix,
    variant = 'tableLine',
    inputRef: inputRefProp,
    hasValue: hasValueProp,
    minWidth,
    ...props
  }) => {
    const inputRef = useRef()

    const handleInputRef = useForkRef(inputRef, inputRefProp)

    const [focused, setFocus] = useState(false)

    const setInputFocus = useCallback(() => {
      if (inputRef && inputRef.current && !disabled) {
        inputRef.current.focus()
      }
    }, [inputRef, disabled])

    const [hasValue, setHasValue] = useState(hasValueProp || (inputRef.current && !!inputRef.current.value))

    useEffect(() => {
      setHasValue(hasValueProp || !!hasValue || (inputRef.current && !!inputRef.current.value))
    }, [value, hasValueProp, focused, inputRef.current])

    const { inputId, labelId, describeId } = useMemo(() => {
      const baseInputId = id || name || generateId()

      return {
        inputId: baseInputId,
        labelId: `${baseInputId}-label`,
        describeId: `${baseInputId}-describe`
      }
    }, [id, name])

    const suffixComp = useMemo(() => {
      if (loading) {
        return <SptLoading variant="spinner" palette="primary" />
      }

      if (suffix) {
        return suffix
      }

      return null
    }, [suffix, loading])

    const fieldProps = useMemo(
      () => ({
        onClick: onClick || setInputFocus,
        hasValue,
        focused,
        size,
        shape,
        pr: suffixComp ? 0 : 10,
        variant,
        colorPalette,
        disabled,
        textarea,
        htmlFor: inputId
      }),
      [inputId, hasValue, focused, suffixComp, shape, size, colorPalette, disabled, onClick]
    )

    const inputProps = useMemo(
      () => ({
        id: inputId,
        name,
        value,
        disabled,
        ref: handleInputRef,
        onBlur: callAllEventHandlers(onBlur, () => setFocus(false)),
        onFocus: callAllEventHandlers(onFocus, () => setFocus(true)),
        'aria-labelledby': labelId,
        'aria-describedby': describeId,
        ...props
      }),
      [inputId, name, value, disabled, handleInputRef, onBlur, onFocus, labelId, describeId, props]
    )

    const suffixComponent = useMemo(() => {
      if (suffixComp) {
        return <SptFieldSuffixStyled>{suffixComp}</SptFieldSuffixStyled>
      }

      return suffixComp
    }, [suffixComp])

    const labelComponent = useMemo(() => {
      if (label) {
        return (
          <SptFieldTableSimpleLabelStyled id={labelId} htmlFor={inputId} display="none" required={required}>
            {label}
          </SptFieldTableSimpleLabelStyled>
        )
      }

      return label
    }, [inputId, labelId, label, required])

    const inputComponent = !textarea ? <SptInput {...inputProps} /> : <SptTextarea {...inputProps} />

    return (
      <SptBox minWidth={minWidth} width={1}>
        <SptFieldTableSimpleStyled alignItems="center" overflow="hidden" {...fieldProps} onClick={setInputFocus}>
          <SptFlex py={7} px={6} width={1} flexDirection="column">
            {labelComponent}
            {inputComponent}
          </SptFlex>
          {suffixComponent}
        </SptFieldTableSimpleStyled>
      </SptBox>
    )
  }
)

SptFieldTableSimple.displayName = 'SptFieldTableSimple'

SptFieldTableSimple.propTypes = {
  size: PropTypes.oneOf(Object.keys(SptFieldSizes)),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  textHelp: PropTypes.string,
  loading: PropTypes.bool,
  shape: PropTypes.oneOf([...Object.keys(SptFieldShapes)]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  palette: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'neutral']).isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  displayTextHelp: PropTypes.bool
}

SptFieldTableSimple.defaultProps = {
  size: 'lg',
  palette: 'primary',
  shape: 'rounded',
  loading: false,
  disabled: false,
  required: false,
  displayTextHelp: true
}
