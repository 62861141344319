import React from 'react'

import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import { useDisclosure } from '@sponte/lib-utils/src/hooks/useDisclosure'

import { SptMenuItem, SptMenuItemDivider } from '@sponte/lib-components/dist/atoms/MenuItem'
import { SptMenuList } from '@sponte/lib-components/dist/atoms/MenuList'

import InputCell from './InputCell'

const Th = styled.th`
  border: 1px solid #000;
  position: relative;
  vertical-align: middle;
`

export default ({ value, onChange, onToggleReadOnly, onAddColumnLeft, onAddColumnRight, onRemoveColumn }) => {
  const { t } = useTranslation()

  const drawerTagsProps = useDisclosure()

  return (
    <Th>
      <InputCell
        onChange={onChange}
        value={value}
        onToggleReadOnly={onToggleReadOnly}
        render={({ showEditOptions }) => {
          return (
            showEditOptions && (
              <SptMenuList position="absolute" zIndex={9} minWidth="max-content">
                <SptMenuItem icon="spt-arrow-left" onClick={onAddColumnLeft}>
                  {t('geral:adicionarColunaAEsquerda')}
                </SptMenuItem>

                <SptMenuItem icon="spt-delete" onClick={onRemoveColumn}>
                  {t('geral:removerColuna')}
                </SptMenuItem>

                <SptMenuItem icon="spt-arrow-right" onClick={onAddColumnRight}>
                  {t('geral:adicionarColunaADireita')}
                </SptMenuItem>

                <SptMenuItemDivider />

                <SptMenuItem
                  icon="spt-add-tag"
                  onClick={() => {
                    drawerTagsProps.onOpen()
                  }}
                >
                  {t('geral:adicionarTag')}
                </SptMenuItem>
              </SptMenuList>
            )
          )
        }}
      />
    </Th>
  )
}
