import React from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import { theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'

export const SptProgressBarSizes = {
  sm: 1,
  md: 2,
  lg: 4
}

const SptProgressBarStyled = styled(SptBox)`
  height: 100%;
  min-width: 0px;
  max-width: 100%;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: ${theme('radii.sm')}px;
`

SptProgressBarStyled.displayName = 'SptProgressBarStyled'

export const SptProgressBar = ({ size, percent, ...props }) => {
  return (
    <SptBox width={1} bg="mediumGrey" borderRadius="sm">
      <SptProgressBarStyled py={SptProgressBarSizes[size]} bg="primary" width={`${percent}%`} {...props} />
    </SptBox>
  )
}

SptProgressBar.displayName = 'SptProgressBar'

SptProgressBar.propTypes = {
  size: PropTypes.oneOf(Object.keys(SptProgressBarSizes)).isRequired,
  percent: PropTypes.number.isRequired
}

SptProgressBar.defaultProps = {
  size: 'md',
  percent: 0
}
