import React, { useRef, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import useInputMask from 'use-input-mask'

import { INPUT_TEXT_DATE_MASK } from '@sponte/lib-utils/src/helpers/masks'

import { SptField } from '../Field'

export const SptFieldDateMaskText = ({ value, onChange, integerMaskLimit, ...props }) => {
  const { t } = useTranslation()

  const inputRef = useRef(null)

  const internalValue = value ? `${value} ${t('geral:dia', { count: value })}` : undefined

  const handleChange = useInputMask({
    input: inputRef,
    onChange: (e) => {
      if (!onChange || typeof onChange !== 'function') {
        return
      }
      onChange({
        ...e,
        target: {
          ...e.target,
          id: props.id,
          type: 'text',
          name: props.name,
          value: e.target.value ? parseInt(e.target.value, 10) : undefined
        }
      })
    },
    mask: (val) =>
      INPUT_TEXT_DATE_MASK(val, t('geral:dia', { count: parseInt(val, 10) }).toLowerCase(), integerMaskLimit),
    guide: false,
    initialValue: internalValue
  })

  const fieldProps = useMemo(
    () => ({
      ...props,
      type: 'text',
      hasValue: !!value,
      onChange: handleChange
    }),
    [props, value, handleChange]
  )

  return <SptField inputRef={inputRef} {...fieldProps} />
}

SptFieldDateMaskText.propTypes = {}

SptFieldDateMaskText.defaultProps = {}
