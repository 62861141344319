import { memo, useEffect } from 'react'

import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

import { useThrottle } from '@sponte/lib-utils/dist/hooks/useThrottle'

export const SptFormAutoSave = memo(({ time, onSave }) => {
  const { dirty, values, ...props } = useFormikContext()

  const handleSave = useThrottle(onSave, time)

  useEffect(() => {
    if (dirty) {
      handleSave(values, props)
    }
  }, [values, dirty])

  return null
})

SptFormAutoSave.displayName = 'SptFormAutoSave'

SptFormAutoSave.propTypes = {
  time: PropTypes.number,
  onSave: PropTypes.func.isRequired
}

SptFormAutoSave.defaultProps = {
  time: 1000
}
