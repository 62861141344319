import React, { memo } from 'react'

import { RichUtils } from 'draft-js'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { useDisclosure } from '@sponte/lib-utils/dist/hooks/useDisclosure'
import { useOnClickOutside } from '@sponte/lib-utils/dist/hooks/useOnClickOutside'
import { usePopper } from '@sponte/lib-utils/dist/hooks/usePopper'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptIcon } from '../../atoms/Icon'
import { SptMenuItem } from '../../atoms/MenuItem'
import { SptMenuList } from '../../atoms/MenuList'
import { SptPortal } from '../../atoms/Portal'

import { useRichTextContext } from './RichTextAreaContext'

const BLOCK_TYPES = [
  { label: 'Normal', style: 'unstyled' },
  { label: 'Título', style: 'header-one' },
  { label: 'Subtítulo', style: 'header-two' },
  { label: 'Citação', style: 'blockquote' }
]

export const SptRichTextAreaContentControlsBlockType = memo(() => {
  const { editorState, handleEditorState, focusEditor } = useRichTextContext()

  const { styles, popper, referrence, popperNode, referrenceNode } = usePopper()
  const { isOpen, onToggle, onClose } = useDisclosure({
    reducer: (_, { type }, changes) => {
      if (type === useDisclosure.types.close) {
        setTimeout(() => focusEditor(), 300)
      }

      return changes
    }
  })

  useOnClickOutside(
    [referrenceNode, popperNode],
    () => {
      if (isOpen) {
        onClose()
      }
    },
    [referrenceNode, popperNode]
  )

  const selection = editorState.getSelection()
  const currentBlockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()

  const handleToggleBlockType = (blockType) => (e) => {
    e.preventDefault()

    handleEditorState(RichUtils.toggleBlockType(editorState, blockType))

    onClose()
  }

  return (
    <>
      <SptFlex
        width={100}
        style={{ cursor: 'pointer' }}
        alignItems="center"
        ref={referrence}
        onClick={() => onToggle()}
        paddingLeft={5}
        paddingRight={5}
      >
        <SptText>
          {getSafe(
            BLOCK_TYPES.find((blockType) => blockType.style === currentBlockType),
            'label',
            'Normal'
          )}
        </SptText>

        <SptIcon>spt-chevron-down</SptIcon>
      </SptFlex>

      {isOpen && (
        <SptPortal>
          <SptMenuList ref={popper} style={styles} width={100} zIndex={10}>
            {BLOCK_TYPES.map((blockType) => (
              <SptMenuItem
                key={blockType.style}
                onMouseDown={handleToggleBlockType(blockType.style)}
                active={blockType.style === currentBlockType}
              >
                {blockType.label}
              </SptMenuItem>
            ))}
          </SptMenuList>
        </SptPortal>
      )}
    </>
  )
})

SptRichTextAreaContentControlsBlockType.displayName = 'SptRichTextAreaContentControlsBlockType'

SptRichTextAreaContentControlsBlockType.propTypes = {}

SptRichTextAreaContentControlsBlockType.defaultProps = {}
